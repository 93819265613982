import { createRequestTypes } from '../appUtils';
export const GENERIC_ACTION = 'GENERIC_ACTION';

export const DENY_PERMISSION = 'DENY_PERMISSION';
export const PASSWORD_FORGOT_FORM_RESET = 'PASSWORD_FORGOT_FORM_RESET';

export const SET_TOKEN = 'SET_TOKEN';

export const TEAM_UPDATE = createRequestTypes('TEAM_UPDATE');
export const INVITE_VALIDATION = createRequestTypes('INVITE_VALIDATION');
export const SET_RECURLY_BILLING_INFO = createRequestTypes('SET_RECURLY_BILLING_INFO');
export const FETCH_RECURLY_BILLING_INFO = createRequestTypes('FETCH_RECURLY_BILLING_INFO');
export const SET_RECURLY_BILLING_TOKEN = createRequestTypes('FETCH_RECURLY_BILLING_TOKEN');
export const FETCH_TEAMS = createRequestTypes('FETCH_TEAMS');
export const FETCH_USER = createRequestTypes('FETCH_USER');

export const LOG_USER_ACTION = createRequestTypes('LOG_USER_ACTION');

export * from './userActions';
