import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();

schema.is().min(14).has().digits(1).uppercase().has().lowercase();

interface ValidationResult {
  isValid: boolean;
  isPasswordLengthValid: boolean;
  hasDigits: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
  hasSpecialCharacters: boolean;
}

const getPasswordValidationResult = (password: string): ValidationResult => {
  // WARNING: Modifying the params to the schema.validate function should cause the dev to re-assess whether `string[]` is the correct return type of the schema.validate function
  const errors = schema.validate(password, { list: true }) as string[];
  const isPasswordLengthValid = !errors.includes('min');
  const hasDigits = !errors.includes('digits');
  const hasLowercase = !errors.includes('lowercase');
  const hasUppercase = !errors.includes('uppercase');
  // special character list comes from Cognito: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  // we need to make our regex for this since we don't want to use the default regex from the password-validator package
  const specialCharacterList = '^$*.[]{}()?-"!@#%&/\\,><\':;|_~`+='.split('');

  const hasSpecialCharacters = password.split('').some((chr) => specialCharacterList.includes(chr));

  const isValidationResultClean = errors.length === 0;

  const isValid = isValidationResultClean && hasSpecialCharacters;

  return {
    isValid,
    hasDigits,
    isPasswordLengthValid,
    hasLowercase,
    hasUppercase,
    hasSpecialCharacters,
  };
};

const getArePasswordsMatching = ({
  password,
  confirmPassword,
}: {
  password: string;
  confirmPassword: string;
}): boolean => password === confirmPassword;

export const passwordUtils = { getPasswordValidationResult, getArePasswordsMatching };
