import React, { useCallback, useEffect } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';

import { BackIcon, BlueButton, FormWrapper, HeaderWrapper, ModalWrapper } from './common/generic';
import WidgetContainer from './common/WidgetContainer';

import CompletedIcon from '../../assets/imgs/Completed.svg';
import { LeftDiv, PlanCostToday, PlanCostYearly, RightDiv } from './common/CostFormat';
import { useSelector, useDispatch } from 'react-redux';
import {
  getIsSkipCCForTeam,
  getSelectedTeamBillingSubscriptionIsValid,
  getSelectedTeamId,
} from '../../selectors';
import { plansHash, renderYearlyPrice } from './common/utils';
import { fetchTeam } from '../../actionCreators';
import { getAuth } from '../../auth/redux/selectors';

const PlanPerkWrapper = styled.div`
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const PlanPerkIcon = styled.img`
  display: inline-block;
  vertical-align: middle;

  margin-right: 10px;
`;

const PlanPerkText = styled.span`
  display: inline-block;
  vertical-align: middle;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
`;

const PlanFormWrapper = styled.div`
  margin-top: 15px;
`;

const PlanHeader = styled.div`
  position: relative;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  margin-left: 32px;
`;

const PlanRadioInput = styled(Input)`
  margin-right: 19px;
  margin-left: 0px;
`;

const PlanPrice = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 20px;
`;

const PlanMostPopularTag = styled.div`
  position: absolute;
  background: #2952ec;
  border-radius: 6px;

  font-size: 12px;
  font-weight: 500;
  line-height: 22px;

  width: 90px;
  height: 22px;

  right: 75px;
  top: 6px;

  text-align: center;

  color: white;
`;

const PlanCostWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 315px;
`;

const PlanFormGroup = styled(FormGroup)`
  padding: 5px;
  margin-bottom: 0;
  padding-bottom: 10px;
  margin-left: -5px;
  margin-right: -5px;
  :hover {
    background-color: #f9f9f9;
  }
`;

interface PlanSignupProps {
  plan: string;
  setPlan: (value: string) => void;
  onDone: () => void;
  onSkipBilling: () => void;
  onBackStep: () => void;
}

const PlanSignup: React.FC<PlanSignupProps> = ({
  children,
  plan,
  setPlan,
  onDone,
  onSkipBilling,
  onBackStep,
}) => {
  const today = new Date();
  const thirtyDays = new Date(today.valueOf());
  thirtyDays.setDate(thirtyDays.getDate() + 30);

  const dispatch = useDispatch();
  const dueDateString = thirtyDays.toLocaleDateString('en-GB', {
    year: 'numeric',
    day: '2-digit',
    month: 'long',
  });

  const auth = useSelector(getAuth);
  const skipCCForTeam = useSelector(getIsSkipCCForTeam);
  const isTeamBillingSubscriptionValid = useSelector(getSelectedTeamBillingSubscriptionIsValid);
  const teamId = useSelector(getSelectedTeamId);

  useEffect(() => {
    if (!teamId) {
      dispatch(fetchTeam());
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (skipCCForTeam || isTeamBillingSubscriptionValid) {
      onSkipBilling();
    }
  }, [isTeamBillingSubscriptionValid, onDone, onSkipBilling, skipCCForTeam]);

  const handleSubmit = useCallback(() => {
    if (plan && auth.account?.id) {
      if (plan === 'enterprise') {
        window.open('https://get.mosaicapp.com/get-demo');
      }
      onDone();
    }
  }, [auth.account?.id, onDone, plan]);

  const renderPlanRadio = (plan: string, title: string, price: number, mostPopular: boolean) => {
    return (
      <PlanFormGroup>
        <span>
          <PlanHeader>
            <span>{title} Plan</span>
            {mostPopular ? <PlanMostPopularTag>Most Popular</PlanMostPopularTag> : null}
          </PlanHeader>
          <PlanRadioInput
            addon
            id={'radio-' + plan}
            value={plan}
            type="radio"
            name="plan-type"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlan(e.target.value)}
          />
          <span>
            <PlanPrice>${price.toFixed(2)}</PlanPrice> user/month billed anually
          </span>
        </span>
      </PlanFormGroup>
    );
  };

  return (
    <ModalWrapper>
      <FormWrapper>
        <Form>
          <HeaderWrapper onClick={onBackStep}>
            <BackIcon />
            Try Mosaic for free
          </HeaderWrapper>
          <PlanPerkWrapper>
            <PlanPerkIcon src={CompletedIcon} alt="check-mark" />
            <PlanPerkText>Free 30 day trial. Cancel any time</PlanPerkText>
          </PlanPerkWrapper>
          <PlanPerkWrapper>
            <PlanPerkIcon src={CompletedIcon} alt="check-mark" />
            <PlanPerkText>We&apos;ll remind you before your trial ends</PlanPerkText>
          </PlanPerkWrapper>
          <PlanFormWrapper>
            {renderPlanRadio(
              'professional',
              plansHash.professional.name,
              plansHash.professional.price,
              false,
            )}
            {renderPlanRadio('business', plansHash.business.name, plansHash.business.price, true)}
            <PlanFormGroup>
              <label>
                <PlanHeader>{plansHash.enterprise.name}</PlanHeader>
                <PlanRadioInput
                  addon
                  value="enterprise"
                  type="radio"
                  name="plan-type"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlan(e.target.value)}
                />
                <span>
                  <PlanPrice>Contact Us</PlanPrice>
                </span>
              </label>
            </PlanFormGroup>
          </PlanFormWrapper>
          <PlanCostWrapper>
            <div style={{ display: 'flex' }}>
              <LeftDiv>
                <PlanCostYearly>Due {dueDateString}</PlanCostYearly>
              </LeftDiv>
              <RightDiv>
                <PlanCostYearly>{renderYearlyPrice(plansHash[plan]?.price)}</PlanCostYearly>
              </RightDiv>
            </div>
            <div style={{ display: 'flex' }}>
              <LeftDiv>
                <PlanCostToday>
                  Due today <span style={{ color: 'blue' }}>(30 days free)</span>
                </PlanCostToday>
              </LeftDiv>
              <RightDiv>
                <PlanCostToday>$0.00</PlanCostToday>
              </RightDiv>
            </div>
          </PlanCostWrapper>
          <BlueButton disabled={!plan} onClick={handleSubmit}>
            Next
          </BlueButton>
        </Form>
      </FormWrapper>
      <WidgetContainer>{children}</WidgetContainer>
    </ModalWrapper>
  );
};

export default PlanSignup;
