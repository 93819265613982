import { DefaultTheme } from 'styled-components';

export const appTheme: DefaultTheme = {
  colors: {
    colorPureWhite: '#fff',
    colorFadedWhite: '#fdfdfd',
    colorBluishGray: '#c6ccd1',
    colorTranslucentGray1: '#fafafa',
    colorTranslucentGray2: '#f2f2f2',
    colorTranslucentGray3: '#f5f5f5',
    colorTranslucentGray4: '#f3f3f3',
    colorTranslucentGray5: '#f1f1f1',
    colorTranslucentGray6: '#f8f8f8',
    colorTranslucentGray7: '#f6f6f6',
    colorPaleGray1: '#ebebeb',
    colorPaleGray2: '#e9e9e9',
    colorPaleGray3: '#e6e6e6',
    colorPaleGray4: '#e5e5e5',
    colorPaleGray5: '#e4e4e4',
    colorPaleGray6: '#e2e2e2',
    colorPaleGray7: '#ececec',
    colorPaleGray8: '#eeeeee',
    colorPaleGray9: '#e3e3e3',
    colorPaleGray10: '#eaeaea',
    colorPaleGray11: '#ece8e8',
    colorPaleGray12: '#e8e8e8',
    colorLightGray1: '#dddddd',
    colorLightGray2: '#d5d5d5',
    colorLightGray3: '#cbcbcb',
    colorLightGray4: '#cccccc',
    colorLightGray5: '#d2d2d2',
    colorLightGray6: '#cdcdcd',
    colorLightGray7: '#f0f0f0',
    colorLightGray8: '#dfdfdf',
    colorLightGray9: '#d9d9d9',
    colorLightGray10: '#999999',
    colorLightGray11: '#979797',
    colorLightGray12: '#dbdbdb',
    colorLightGray13: '#9e9e9e',
    colorLightGray14: '#999999',
    colorLightGray15: '#a4a4a4',
    colorLightGray16: '#c0c0c0',
    colorLightGray17: '#efefef',
    colorLightGray18: '#9e9a9a',
    colorLightGray19: '#f7f7f7',
    colorLightGray20: '#BBBDC0',
    colorLightGray21: '#D3D3D3',
    colorLightGray22: '#F9F9F9',
    colorLightGray23: '#f1efef',
    colorLightGray24: '#bababa',
    colorMediumGray1: '#b3b3b3',
    colorMediumGray2: '#adadad',
    colorMediumGray3: '#a5a5a5',
    colorMediumGray4: '#9b9b9b',
    colorMediumGray5: '#808080',
    colorMediumGray6: '#898989',
    colorMediumGray6AndAHalf: '#8D8D8D',
    colorMediumGray7: '#333333',
    colorMediumGray8: '#9f9f9f',
    colorMediumGray9: '#4f4f4f',
    colorMediumGray10: '#a4a4a4',
    colorMediumGray11: '#e7e7e7',
    colorMediumGray12: '#d8d8d8',
    colorMediumGray13: '#959595',
    colorMediumGray14: '#c4c4c4',
    colorMediumGray15: '#E7E3E3',
    colorSemiDarkGray1: '#4a4a4a',
    colorSemiDarkGray2: '#4c4c4c',
    colorSemiDarkGray3: '#4d4d4d',
    colorSemiDarkGray4: '#6a6a6a',
    colorSemiDarkGray5: '#6f6f6f',
    colorSemiDarkGray6: '#4f4f4f',
    colorSemiDarkGray7: '#686565',
    colorSemiDarkGrey8: '#C0BBBB',
    colorSemiDarkGrey9: '#E0DDDD',
    colorSemiDarkGrey10: '#888585',
    colorDarkGray1: '#404040',
    colorDarkGray2: '#383737',
    colorDarkGray3: '#373a3c',
    colorDarkGray4: '#2c2c2c',
    colorDarkGray5: '#3b3b3b',
    colorPureBlack: '#000',
    colorSeaGreen: '#65ad15',
    colorQbTooltipGreen: '#27AE60',
    colorEditGreen: '#15d9d7',
    colorConnectedGreen: '#219653',
    colorStartGreen: '#b2e555',
    colorBudgetGreen: '#6fdb19',
    colorBudgetDotGreen: '#27AE60',
    colorPaleGreen1: '#CDE4C9',
    colorPaleGreen2: '#D4FFE3',
    colorPaleGreen3: '#5aa508',
    colorLightGreen1: '#45BD6F',
    colorLightGreen2: '#90E0B1',
    colorLightGreen3: '#6FCF97',
    colorMediumGreen1: '#1A7D45',
    colorMediumGreen2: '#1D7D46',
    colorMediumGreen3: '#55844C',
    colorMediumGreen4: '#1F864B',
    colorMediumGreen5: '#1A8045',
    colorMediumGreen6: '#4AB212',
    colorMediumGreen7: '#61b802',
    colorMediumGreen8: '#39c273',
    colorMediumGreen9: '#57BA48',
    colorMediumGreen10: '#27ae5f',
    colorDarkGreen1: '#0B7136',
    colorDarkGreen2: '#7ED321',
    colorPaleCyan1: '#DFF2F1',
    colorLightCyan1: '#78D4D0',
    colorLightCyan2: '#BAFAF6',
    colorLightCyan3: '#56CCF2',
    colorMediumCyan2: '#77BAB6',
    colorBudgetGrey: '#A4A4A4',
    colorBudgetBlue: '#0074D9',
    colorIceBlue: '#eef7ff',
    colorRoyalBlue: '#0074d9',
    colorPaleBlue: '#90bff6',
    colorPaleBlue2: '#f5fbfe',
    colorPaleBlue3: '#D5E6FB',
    colorPaleBlue4: '#89B0E7',
    colorPaleBlue5: 'rgba(99, 161, 215, 0.1)',
    colorPaleBlue6: '#56ccf2',
    colorPaleBlue7: '#12A0EB',
    colorPaleBlue8: '#8CC6F0',
    colorDeeperRoyalBlue: '#0056ff',
    colorHelpBlue: '#2B55F1',
    colorLoaderBlue: '#0961f2',
    colorPowderBlue: '#cee4f8',
    colorLightButtonBlue: '#82B3F4',
    colorDarkBlue: '#36465d',
    colorDarkBlue2: '#223148',
    colorDarkBlue3: '#13225c',
    colorMediumBlue1: '#315ae8',
    colorMediumBlue2: '#2666BE',
    colorMediumBlue3: '#5077A8',
    colorMobileRedirect: '#00dbf5',
    colorTealBlue: '#b5eef5',
    colorBrightYellow: '#ffee48',
    colorBrightYellow2: '#fff24e',
    colorBrightYellow3: '#fbf421',
    colorGoldenYellow: '#ead146',
    colorGoldenYellow2: '#CDA53D',
    colorHighlightYellow: '#fff18c',
    colorLightYellow: '#fff18a',
    colorPaleYellow: '#fcf5a3',
    colorPaleYellow2: '#FFF3D4',
    colorPaleYellow3: '#F3FBD5',
    colorPaleYellow4: '#f3f574',
    colorMustardYellow: '#f1b328',
    colorMustardYellow2: '#DDE055',
    colorDarkDijonYellow: '#f8e71c',
    colorBudgetYellow: '#F2F555',
    colorBudgetDotYellow: '#E7EB16',
    colorMediumYellow1: '#D7B300',
    colorMediumYellow2: '#A8C245',
    colorMediumYellow3: '#e5c100',
    colorMediumYellow4: '#d0b10a',
    colorMediumYellow5: '#FFCB55',
    colorBudgetLightGreen: '#d4f5ba',
    colorCrimsonRed: '#d0021b',
    colorDeleteRed: '#ee263f',
    colorPaleRed: '#fe7384',
    colorPaleRed2: '#FBD5D5',
    colorPaleRed3: '#F85251',
    colorMediumRed1: '#d72e2e',
    colorMediumRed2: '#C66363',
    colorMediumRed3: '#ee263f',
    colorMediumRed4: '#d31930',
    colorMediumRed5: '#FF0000',
    colorMediumRed6: '#F15A4F',
    colorCalendarRed: '#eb5757',
    colorCalendarGray: '#828282',
    colorCalendarOrange: '#FF8520',
    colorCalendarBlue: '#2f80ed',
    colorPaleOrange1: '#FAD8BA',
    colorPaleOrange2: '#e8c3a2',
    colorLightOrange: '#f9ca7b',
    colorLightOrange2: '#F2994A',
    colorLightOrange3: '#FFA95E',
    colorLightOrange4: '#ff8a2a',
    colorMediumOrange: '#f1961c',
    colorMediumOrange2: '#E17B26',
    colorMediumOrange3: '#D38642',
    colorMediumOrange4: '#e17217',
    colorMediumOrange5: '#FD9E47',
    colorNavigatePurple: '#9013fe',
    colorAddPink: '#f512ab',
    colorRemainingTeal: '#25E1F0',
    plannerColumnWidth: '280px',
    colorDarkBlueTooltip: '#60667a',
    colorPalePurple1: '#F4ECFC',
    colorPalePurple2: '#FEE8F8',
    colorPalePurple3: '#E7E2F5',
    colorPalePurple4: '#F9D4FF',
    colorLightPurple1: '#B07CE3',
    colorLightPurple2: '#BB72A7',
    colorLightPurple3: '#8F96DE',
    colorLightPurple4: '#EAD4FF',
    colorLightPurple5: '#E7CEFF',
    colorLightPurple6: '#DBBDF7',
    colorMediumPurple1: '#AB5AB8',
    colorMediumPurple2: '#9B51E0',
    colorMediumPurple3: '#CC96FF',
    colorDarkPurple1: '#7A60C1',
    colorDarkPurple2: '#B666FF',
    colorDarkPurple3: '#B368F9',
    colorPaleBrown1: '#E0D0D0',
    colorLightBrown1: '#A77A7A',
  },
};
