import { initialState } from '../reducers/teams';
import { createSelector } from 'reselect';

export const getTeamsState = (state: any) => state.teams || initialState;
export const getSelectedTeam = createSelector(getTeamsState, (teams: any) => teams.selectedTeam);

export const getSelectedTeamId = createSelector(getSelectedTeam, (team: any) => team && team.id);

export const getIsSkipCCForTeam = createSelector(
  getSelectedTeam,
  (team: any) => team && team.skip_credit_card_info,
);

export const getSelectedTeamBillingSubscriptionIsValid = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam?.subscription_status === 'active',
);
