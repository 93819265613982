import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../Routes/Routes';

import logoOrange from '../../assets/logos/logo-orange.svg';
import logoParty from '../../assets/logos/logo-party.png';
import mainLogo from '../../assets/logos/mosaic-logo-2021.svg';

import styled, { ThemeProvider } from 'styled-components';
import { appTheme } from '../../shared/styles';
import { IconStyles } from '../../shared/styles/icons';
import { TooltipsStyles } from '../../shared/styles/tooltips';
import { AppTooltip } from '../shared/AppTooltip';
import { Portal } from '../shared/Portal';
import ChromeDownloadBanner from '../shared/ChromeDownloadBanner';
import { useFeatureFlags } from '../../shared/hooks/useFeatureFlags';

function App() {
  const { isLoaded } = useFeatureFlags();
  useEffect(() => {
    const favicon: HTMLLinkElement = document.getElementById('favicon') as HTMLLinkElement;
    const env = process.env.REACT_APP_UI_ENV;

    switch (env) {
      case 'staging':
        favicon.href = logoOrange;
        break;
      case 'party':
        favicon.href = logoParty;
        break;
      default:
        favicon.href = mainLogo;
    }
  }, []);

  // wait for split feature flags to load before trying to load the UI
  if (!isLoaded) return <></>;

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  return (
    <ThemeProvider theme={appTheme}>
      <AppWrapper>
        <PortalRoot id="portal-root">
          <Portal selector="#portal-root">
            <AppTooltip />
          </Portal>
          <header className="App-header">
            <ChromeDownloadBanner />
          </header>
          <Routes />
        </PortalRoot>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;

const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  ${IconStyles};
  ${TooltipsStyles};
`;

const PortalRoot = styled.div`
  height: 100%;
`;
