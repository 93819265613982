import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchTeam, logUserAction, setRecurlyBillingToken } from '../../actionCreators';

import {
  getSelectedTeamId,
  getIsSkipCCForTeam,
  getSelectedTeamBillingSubscriptionIsValid,
} from '../../selectors';

import { Form, FormGroup, FormText, Label } from 'reactstrap';

import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  Elements,
  RecurlyProvider,
  useRecurly,
} from '@recurly/react-recurly';
import {
  BackIcon,
  BlueButton,
  FormWrapper,
  HeaderWrapper,
  ModalWrapper,
  InvalidText,
} from './common/generic';
import WidgetContainer from './common/WidgetContainer';
import styled from 'styled-components';
import { LeftDiv, PlanCostToday, PlanCostYearly, RightDiv } from './common/CostFormat';

import { plansHash, renderYearlyPrice } from './common/utils';

import * as constants from '../../constants';
import { useRequestStatus } from '../../shared/hooks/useRequestStatus';
import { LoadingWheel } from '../../images/LoadingWheel';
import { AuthState } from '../../auth/redux/reducer';
import { getAuth } from '../../auth/redux/selectors';
import { updateAccountInfo } from '../../auth/redux/actionCreators';
import { getMe } from '../../users/redux/selectors';

const CreditCardLabel = styled(FormText)`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  margin-top: 10px;
  margin-bottom: 10px;
`;

const BillingLabel = styled(Label)`
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin-top: 30px;
  margin-bottom: 16px;
`;

const CardNumberWrapper = styled.div`
  width: 297px;
  height: 48px;

  padding-left: 16px;
  margin-bottom: 10px;

  border: 1px solid rgba(17, 17, 18, 0.2);
  border-radius: 4px;

  * {
    all: initial;
  }
`;

const CardDateLeftWrapper = styled.div`
  width: 75px;
  height: 48px;

  padding-left: 16px;

  border: 1px solid rgba(17, 17, 18, 0.2);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;

  * {
    all: initial;
  }
`;

const CardDateRightWrapper = styled.div`
  width: 75px;
  height: 48px;
  padding-left: 16px;

  border: 1px solid rgba(17, 17, 18, 0.2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;

  margin-right: 7px;

  * {
    all: initial;
  }
`;

const CardCVVWrapper = styled.div`
  width: 138px;
  height: 48px;

  padding-left: 16px;

  border: 1px solid rgba(17, 17, 18, 0.2);
  border-radius: 4px;

  * {
    all: initial;
  }
`;

const BillingCostWrapper = styled.div`
  width: 315px;
  margin-bottom: 95px;
`;

interface BillingSignupProps {
  name: string;
  plan: string;
  recurlyRef: React.RefObject<HTMLFormElement>;
  onDone: () => void;
  onSkipBilling: () => void;
  onBackStep: () => void;
}

const BillingSignup: React.FC<BillingSignupProps> = ({
  children,
  name,
  plan,
  recurlyRef,
  onDone,
  onSkipBilling,
  onBackStep,
}) => {
  const dispatch = useDispatch();
  const skipCCForTeam = useSelector(getIsSkipCCForTeam);
  const isTeamBillingSubscriptionValid = useSelector(getSelectedTeamBillingSubscriptionIsValid);
  const teamId = useSelector(getSelectedTeamId);

  useEffect(() => {
    if (!teamId) {
      dispatch(fetchTeam());
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (skipCCForTeam || isTeamBillingSubscriptionValid) {
      onSkipBilling();
    }
  }, [isTeamBillingSubscriptionValid, onDone, onSkipBilling, skipCCForTeam]);
  return (
    <ModalWrapper>
      <FormWrapper>
        <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}>
          <Elements>
            <RecurlyContainer
              name={name}
              plan={plan}
              recurlyRef={recurlyRef}
              onDone={onDone}
              onBackStep={onBackStep}
            />
          </Elements>
        </RecurlyProvider>
      </FormWrapper>
      <WidgetContainer>{children}</WidgetContainer>
    </ModalWrapper>
  );
};

const logUserActionSignupBillingInfoRequestStatusId =
  'BillingSignup__logUserAction__signupBillingInfo';

const logUserActionSignupPlanRequestStatusId = 'BillingSignup__logUserAction__signupPlan';

const RecurlyContainer: React.FC<Omit<BillingSignupProps, 'onSkipBilling'>> = ({
  name,
  plan,
  recurlyRef,
  onDone,
  onBackStep,
}) => {
  const today = new Date();
  const thirtyDays = new Date(today.valueOf());
  thirtyDays.setDate(thirtyDays.getDate() + 30);

  const dueDateString = thirtyDays.toLocaleDateString('en-GB', {
    year: 'numeric',
    day: '2-digit',
    month: 'long',
  });
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const me = useSelector(getMe);

  const recurly = useRecurly();

  const [validCCNumber, setValidCCNumber] = useState(false);
  const [validMonth, setValidMonth] = useState(false);
  const [validYear, setValidYear] = useState(false);
  const [validCCV, setValidCCV] = useState(false);

  const [recurlyValidationMessage, setRecurlyValidationMessage] = useState<string | undefined>(
    undefined,
  );

  const {
    status: setRecurlyBillingInfoRequestStatus,
    removeStatus: setRecurlyBillingInfoRemoveStatus,
  } = useRequestStatus<string>({ requestStatusId: setRecurlyBillingToken.type });

  const {
    status: logUserActionSignupBillingInfoRequestStatus,
    removeStatus: logUserActionSignupBillingRemoveStatus,
  } = useRequestStatus<string>({ requestStatusId: logUserActionSignupBillingInfoRequestStatusId });

  const { status: updateAccountInfoRequestStatus, removeStatus: updateAccountInfoRemoveStatus } =
    useRequestStatus<string>({ requestStatusId: updateAccountInfo.type });

  const {
    status: logUserActionSignupPlanRequestStatus,
    removeStatus: logUserActionSignupPlanRemoveStatus,
  } = useRequestStatus<string>({ requestStatusId: logUserActionSignupBillingInfoRequestStatusId });

  const clearValidationState = useCallback(() => {
    setRecurlyValidationMessage(undefined);
    setRecurlyBillingInfoRemoveStatus();
    logUserActionSignupBillingRemoveStatus();
    updateAccountInfoRemoveStatus();
    logUserActionSignupPlanRemoveStatus();
  }, [
    logUserActionSignupBillingRemoveStatus,
    logUserActionSignupPlanRemoveStatus,
    setRecurlyBillingInfoRemoveStatus,
    updateAccountInfoRemoveStatus,
  ]);

  const apiCallErrorMessage =
    setRecurlyBillingInfoRequestStatus?.error ||
    logUserActionSignupBillingInfoRequestStatus?.error ||
    updateAccountInfoRequestStatus?.error ||
    logUserActionSignupPlanRequestStatus?.error;

  const isExecuting =
    setRecurlyBillingInfoRequestStatus?.isExecuting ||
    logUserActionSignupBillingInfoRequestStatus?.isExecuting ||
    updateAccountInfoRequestStatus?.isExecuting ||
    logUserActionSignupPlanRequestStatus?.isExecuting;

  const isPageDone = useMemo(() => {
    return validCCNumber && validMonth && validYear && validCCV;
  }, [validCCNumber, validCCV, validMonth, validYear]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (event.preventDefault) event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recurly.token(recurlyRef.current!, (err, token) => {
        clearValidationState();
        if (err) {
          setRecurlyValidationMessage(err.message);
        } else {
          const account = auth.account;

          if (!getIsAccountNull(account)) {
            dispatch(
              setRecurlyBillingToken({
                billingTokenId: token.id,
                meta: {
                  requestStatusId: setRecurlyBillingToken.type,
                  onSuccess: () => {
                    dispatch(
                      logUserAction({
                        actionable_id: account.id,
                        actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
                        action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
                        entity_information: {
                          user_action_type: 'signup_billing_info',
                          user_action_id: 5,
                          success: true,
                          error: null,
                        },
                        meta: {
                          requestStatusId: logUserActionSignupBillingInfoRequestStatusId,
                          onSuccess: () => {
                            dispatch(
                              updateAccountInfo({
                                account: {
                                  account_id: account.id,
                                  subscription_plan: plan,
                                },
                                meta: {
                                  requestStatusId: updateAccountInfo.type,
                                  onSuccess: () => {
                                    dispatch(
                                      logUserAction({
                                        actionable_id: account.id,
                                        actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
                                        action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
                                        entity_information: {
                                          user_action_type: 'signup_plan',
                                          user_action_id: 4,
                                          success: true,
                                          error: null,
                                        },
                                        meta: {
                                          requestStatusId: logUserActionSignupPlanRequestStatusId,
                                          onSuccess: () => {
                                            // fetch team again so that our subscription status is updated to be active so that we are able to press `Done` on the final step of onboarding
                                            dispatch(fetchTeam());
                                            onDone();
                                          },
                                        },
                                      }),
                                    );
                                  },
                                },
                              }),
                            );
                          },
                        },
                      }),
                    );
                  },
                },
              }),
            );
          }
        }
      });
    },
    [auth.account, clearValidationState, dispatch, onDone, plan, recurly, recurlyRef],
  );

  const renderHiddenFields = () => {
    return (
      <div style={{ display: 'none' }}>
        <input
          data-recurly="first_name"
          placeholder="First Name"
          defaultValue={name.split(' ')[0] || me?.account?.first_name || ''}
        />
        <input
          data-recurly="last_name"
          placeholder="Last Name"
          defaultValue={name.split(' ')[1] || me?.account?.last_name || ''}
        />
        <input data-recurly="postal_code" placeholder="Postal Code" defaultValue="94117" />
      </div>
    );
  };

  const fontSize = '16';

  return (
    <Form onSubmit={handleSubmit} innerRef={recurlyRef}>
      {renderHiddenFields()}
      <HeaderWrapper onClick={onBackStep}>
        <BackIcon />
        Try Mosaic for free
      </HeaderWrapper>
      <FormGroup>
        <BillingLabel>Payment Method</BillingLabel>
        <div>
          <CreditCardLabel>Credit or Debit Card information</CreditCardLabel>
        </div>
        <CardNumberWrapper>
          <CardNumberElement
            style={{
              fontSize,
              placeholder: { content: 'Card number' },
            }}
            onFocus={clearValidationState}
            onChange={(event) => setValidCCNumber(event.valid)}
          />
        </CardNumberWrapper>
      </FormGroup>
      <FormGroup style={{ marginBottom: 0 }}>
        <div style={{ paddingBottom: '10px', display: 'flex' }}>
          <CardDateLeftWrapper>
            <CardMonthElement
              onChange={(event) => setValidMonth(event.valid)}
              onFocus={clearValidationState}
              style={{ fontSize, placeholder: { content: 'MM' } }}
            />
          </CardDateLeftWrapper>
          <CardDateRightWrapper>
            <CardYearElement
              onChange={(event) => setValidYear(event.valid)}
              onFocus={clearValidationState}
              style={{ fontSize, placeholder: { content: 'YY' } }}
            />
          </CardDateRightWrapper>
          <CardCVVWrapper>
            <CardCvvElement
              onChange={(event) => setValidCCV(event.valid)}
              onFocus={clearValidationState}
              style={{ fontSize, placeholder: { content: 'CVC' } }}
            />
          </CardCVVWrapper>
        </div>
      </FormGroup>
      {recurlyValidationMessage || apiCallErrorMessage ? (
        <InvalidText>{recurlyValidationMessage || apiCallErrorMessage}</InvalidText>
      ) : null}
      <BillingCostWrapper>
        <div style={{ display: 'flex' }}>
          <LeftDiv>
            <PlanCostToday>Due today</PlanCostToday>
          </LeftDiv>
          <RightDiv>
            <PlanCostToday>$0.00</PlanCostToday>
          </RightDiv>
        </div>

        <div style={{ display: 'flex' }}>
          <LeftDiv>
            <PlanCostYearly>Due {dueDateString}</PlanCostYearly>
          </LeftDiv>
          <RightDiv>
            <PlanCostYearly>{renderYearlyPrice(plansHash[plan].price)}</PlanCostYearly>
          </RightDiv>
        </div>
      </BillingCostWrapper>
      <BlueButton disabled={!isPageDone || isExecuting} onClick={handleSubmit}>
        {isExecuting ? <LoadingWheel /> : 'Next'}
      </BlueButton>
    </Form>
  );
};

export default BillingSignup;

const getIsAccountNull = (account: AuthState['account']): account is null => {
  return account === null;
};
