import cn from 'classnames';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { LoadingWheel } from '../../../images/LoadingWheel';
import { appTheme } from '../../../shared/styles';
import { BaseButton } from '../Button/styles';

interface OutlinedButtonProps {
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  height?: number | string;
  width?: number | string;
  onClick?: (event: SyntheticEvent) => void;
}

export const OutlinedButton = ({
  label,
  isDisabled = false,
  isLoading = false,
  height,
  width,
  onClick,
}: OutlinedButtonProps) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      className={cn({ loading: isLoading })}
      style={{ height, width }}
    >
      {isLoading ? <LoadingWheel /> : <ButtonText>{label}</ButtonText>}
    </Button>
  );
};

const Button = styled(BaseButton)`
  border: solid 1px ${appTheme.colors.colorRoyalBlue};

  &:hover,
  &:disabled {
    color: white;
    background-color: ${appTheme.colors.colorRoyalBlue};
  }

  &.loading {
    background-color: ${appTheme.colors.colorRoyalBlue};
  }
`;

const ButtonText = styled.span`
  font-weight: 600;
`;
