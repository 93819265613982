import { SvgIcon, SvgIconProps } from '../../images/SvgIcon';
import { appTheme } from '../../shared/styles';

interface ArrowIconProps extends Pick<SvgIconProps, 'height' | 'width'> {
  fillColor?: string;
}

export const ArrowIcon = ({
  height = 6,
  width = 10,
  fillColor = appTheme.colors.colorSemiDarkGray1,
}: ArrowIconProps) => {
  return (
    <SvgIcon height={height} width={width} viewBox="0 0 10 6" fill="none">
      <path
        d="M5.00032 5.00899L0.450117 0.000699997L0 0.496204L5.00032 6L10 0.495502L9.54988 0L5.00032 5.00899Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
