// reference: https://www.antpace.com/blog/remove-subdirectories-from-a-url-string/
const removeSubdirectoryFromUrlString = ({ url }: { url: string }): string => {
  let isSSL = false;

  if (url.indexOf('https://') !== -1) {
    isSSL = true;
  }

  let parsedUrl = url.replace('https://', '');
  parsedUrl = parsedUrl.replace('http://', '');
  const pathArray = parsedUrl.split('/');
  parsedUrl = `${isSSL ? 'https://' : 'http://'}${pathArray[0]}`;
  return parsedUrl;
};

export const urlUtils = { removeSubdirectoryFromUrlString };
