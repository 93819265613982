import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { GreenButton, FormWrapper, ModalWrapper } from './common/generic';
import WidgetContainer from './common/WidgetContainer';

import CompletedBigIcon from '../../assets/imgs/Completed_Big.svg';
import { getIsSkipCCForTeam, getSelectedTeamBillingSubscriptionIsValid } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { logUserAction } from '../../actionCreators';

import * as constants from '../../constants';
import { getAuth } from '../../auth/redux/selectors';
import { getMe } from '../../users/redux/selectors';

const FinishedWrapper = styled.div`
  display: inline-block;
  text-align: center;
`;

const FinishedHeader = styled.div`
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 11px;
`;

const FinishedBlurb = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;

  margin-bottom: 131px;
`;

const FinishedIcon = styled.img`
  margin-top: 162px;
  margin-bottom: 30px;
`;

interface FinishSignupProps {
  onDone: () => void;
}

const FinishSignup: React.FC<FinishSignupProps> = ({ children, onDone }) => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);
  const me = useSelector(getMe);
  const isTeamBillingSubscriptionValid = useSelector(getSelectedTeamBillingSubscriptionIsValid);
  const skipCCForTeam = useSelector(getIsSkipCCForTeam);

  const doneSignup = useMemo(() => {
    return (
      me && me.hasPassword && me.hasCompany && (isTeamBillingSubscriptionValid || skipCCForTeam)
    );
  }, [isTeamBillingSubscriptionValid, me, skipCCForTeam]);

  const doneAction = useCallback(() => {
    if (auth.account?.id) {
      dispatch(
        logUserAction({
          actionable_id: auth.account.id,
          actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
          action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
          entity_information: {
            user_action_type: 'signup_done',
            user_action_id: 7,
            success: true,
            error: null,
          },
        }),
      );
      onDone();
    }
  }, [dispatch, auth.account?.id, onDone]);

  return (
    <ModalWrapper>
      <FormWrapper>
        <FinishedWrapper>
          <FinishedIcon src={CompletedBigIcon} alt="finished" />
          <FinishedHeader>Congratulations!</FinishedHeader>
          <FinishedBlurb>We&apos;re excited to get you started on Mosaic!</FinishedBlurb>
          <GreenButton disabled={!doneSignup} onClick={doneAction}>
            Done
          </GreenButton>
        </FinishedWrapper>
      </FormWrapper>
      <WidgetContainer>{children}</WidgetContainer>
    </ModalWrapper>
  );
};

export default FinishSignup;
