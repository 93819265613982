import { END } from 'redux-saga';
import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { getRootModule, history as rootHistory } from '../../rootModule';
import { persistentState } from '../../service';

import throttle from 'lodash/throttle';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';
import { splitFlags } from '../../constants/splitFlags';
import thunk from 'redux-thunk';

export const history = rootHistory;

// Split IO config
const sdkBrowserConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLITIO_APIKEY ?? '',
    key: 'flag',
  },
};

export default function configureStore() {
  const persistedState = persistentState.loadState();
  const middlewares = [thunk];
  // Make sure we don't mutate our state
  if (process.env.NODE_ENV !== 'production' && process.env.TEST !== 'true') {
    // eslint-disable-next-line global-require,import/no-extraneous-dependencies
    middlewares.unshift(
      require('redux-immutable-state-invariant').default({
        ignore: ['notes.notesArray', 'notes.activityFeedNotes'],
      }),
    );
  }

  const moduleStore = createStore(
    {
      initialState: { ...persistedState },
      enhancers: [],
      // [sentryReduxEnhancer],
      // see https://github.com/microsoft/redux-dynamic-modules/issues/111, for why middleware need to be loaded here instead of composed with responsiveStoreEnhancer
      extensions: [{ middleware: middlewares }, getSagaExtension()],
    },
    getRootModule(),
    // any additional modules
  );

  moduleStore.subscribe(
    throttle(() => {
      persistentState.saveState({
        auth: persistentState.overridePersistantAuthStorage(moduleStore.getState().auth),
      });
    }, 1000),
  );

  // Split IO
  function onReadyCallback() {
    moduleStore.dispatch(getTreatments({ splitNames: splitFlags }));
  }
  function onUpdateCallback() {
    moduleStore.dispatch(
      getTreatments({
        splitNames: splitFlags,
        evalOnUpdate: true,
      }),
    );
  }
  moduleStore.dispatch(
    initSplitSdk({
      config: sdkBrowserConfig,
      onReady: onReadyCallback,
      onUpdate: onUpdateCallback,
    }),
  );

  moduleStore.close = () => moduleStore.dispatch(END);
  return moduleStore;
}
