import { Switch, Route, useHistory } from 'react-router-dom';
import ForgotPasswordContainer from '../Login/ForgotPasswordContainer';
import InvitationContainer from '../Invitation/InvitationContainer';
import LoginPage from '../Login/Login';
import { SsoCallbackContainer } from '../Login/SsoCallbackContainer';
import ResetRouter from '../Login/ResetRouter';

export default function Routes() {
  const history = useHistory();
  return (
    <>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/sso-callback" component={SsoCallbackContainer} />
        <Route path="/forgot">
          <ForgotPasswordContainer />
        </Route>
        {/* Cognito reset route - query param based so can be changed easily. */}
        <Route path="/setup/invite" component={ResetRouter} />
        <Route path="/reset/:inviteToken" component={ResetRouter} />
        <Route path="/free-trial/">
          <InvitationContainer />
        </Route>

        {/* Embedded paths. */}
        <Route path="/embedded/free-trial/">
          <InvitationContainer />
        </Route>

        {/* Update into own component eventually. */}
        <Route
          path="/"
          component={() => {
            history.push('/login');
            return null;
          }}
        />
      </Switch>
    </>
  );
}
