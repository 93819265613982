import * as entityActions from '../actions';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import { changeEntity } from './generics';
import { logUserAction } from '../actionCreators';
import { getAuthToken } from '../auth/redux/selectors';

const { userActionLog } = entityActions;

export function* logUserActionWorker(action: ReturnType<typeof logUserAction>) {
  const { payload, meta } = action;
  const token = yield select(getAuthToken);

  const { error } = yield changeEntity(userActionLog, api.logUserAction, [token, payload], action);

  if (!error) {
    if (meta?.onSuccess) {
      meta.onSuccess();
    }
  } else {
    if (meta?.onFailure) {
      meta.onFailure();
    }
  }
}
