import styled from 'styled-components';
import { appTheme } from '../../../shared/styles';

export const ResetFormContainer = styled.div`
  max-width: 535px;
  max-height: 405px;
  margin: auto;
  margin-top: 15vh;
  background-color: #ffffff;
  border: none;
  text-align: center;

  h3 {
    line-height: 1.2;
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 1rem;
  }

  h6 {
    margin-top: 0px;
    font-weight: 500;
  }

  .user-input {
    height: 37px;
    padding-top: 0.3rem;
    padding-left: 0.25rem;
    border-bottom: 1px solid #e4e4e4 !important;
    outline: none;
    border: none;
    font-size: 16px;
    color: #525252 !important;

    &.form-control-danger {
      border-bottom: 1px solid ${appTheme.colors.colorCalendarRed} !important;
    }

    &.form-control-success {
      border-bottom: 1px solid ${appTheme.colors.colorMediumGreen1} !important;
    }
  }

  .auth-modal-error {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .auth-modal-error-message {
      font-size: 0.9rem;
      color: #b51732;
      width: 66%;
      text-align: justify;
    }
  }
`;

export const LandingFormContainer = styled.div`
  a {
    text-decoration: none;
  }

  * {
    font-family: 'Open Sans', sans-serif;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    * {
      vertical-align: middle;
    }
  }

  .input-form {
    max-width: 535px;
    max-height: 320px;
    margin: auto;
    margin-top: 15vh;
    background-color: #ffffff;
    border: none;
    text-align: center;
    h3 {
      line-height: 1.2;
      font-size: 2.5rem;
      font-weight: 100;
      margin-bottom: 1rem;
    }
    .user-input {
      height: 37px;
      padding-top: 0.3rem;
      padding-left: 0.25rem;
      border-bottom: 1px solid #e4e4e4 !important;
      outline: none;
      border: none;
      font-size: 16px;
      color: #525252 !important;
    }
    .user-input:focus {
      border-bottom: 1px solid #0074d9 !important;
    }
  }

  .user-input {
    height: 37px;
    padding-top: 0.3rem;
    padding-left: 0.25rem;
    border-bottom: 1px solid #e4e4e4 !important;
    outline: none;
    border: none;
    font-size: 16px;
    color: #525252 !important;
  }

  .login-options {
    margin-top: 16px;
  }

  .forgot-password {
    display: inline-block;
    a {
      vertical-align: middle;
    }
  }
`;

export const LandingContainerStyledWrapper = styled.div`
  height: 100%;
  position: relative;

  * {
    font-family: 'Open Sans', sans-serif;
  }

  body > .auth-page {
    background-color: #fff;
  }
  .modal-backdrop {
    z-index: 2;
  }
  .auth-page {
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: #fff;
    align-items: center;
    position: absolute;

    h2 {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 100;
      font-size: 40px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 1.1rem;
    }
    h6 {
      font-size: 18px;
      margin-bottom: 1.5rem;
      display: inline-block;
    }

    p.action-link {
      text-decoration: none;
      color: #000000;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      text-decoration: none;
      margin-top: 0px;
      margin-bottom: 16px;
    }
    p.action-link {
      cursor: pointer;
    }
    div.input-form {
      max-width: 535px;
      max-height: 405px;
      margin: auto;
      margin-top: 15vh;
      background-color: #ffffff;
      border: none;
      text-align: center;
      h3 {
        line-height: 1.2;
        font-size: 2.5rem;
        font-weight: 100;
        margin-bottom: 1rem;
      }
      .user-input {
        height: 37px;
        padding-top: 0.3rem;
        padding-left: 0.25rem;
        border-bottom: 1px solid #e4e4e4 !important;
        outline: none;
        border: none;
        font-size: 16px;
        color: #525252 !important;
      }
      .user-input:focus {
        border-bottom: 1px solid #0074d9 !important;
      }
    }
    div.input-login {
      margin-top: 20.5vh;
      label {
        //@extend .form-check-label;
        line-height: 1;
        margin-right: 4.1rem;
        margin-top: 0.4rem;
        font-size: 0.9rem;
        color: #0074d9;
      }
      button {
        min-width: 8.5rem;
        height: 3.5rem;
      }
      a.signup-link,
      .help-link-text {
        color: #0074d9;
      }
      .login-options {
        display: flex;
        width: 85%;
        justify-content: flex-end;

        .forgot-password a {
          color: #b5b3b2;
        }
      }
    }
    div.input-forgot {
      margin-top: 11.5vh;
      letter-spacing: -0.001rem;
      p {
        text-align: center;
        font-size: 18px;
        margin-bottom: 0.85rem;
      }
      #login-link:hover,
      #forgot-password-button:hover {
        color: $color-royal-blue;
        cursor: pointer;
      }
      button {
        width: 13.5rem;
        height: 3.35rem;
      }
      .user-input {
        font-size: 18px;
        font-weight: 400;
        color: #525252 !important;
      }
      .password-reset-reminder:not(:empty) {
        font-size: 15px;
        margin-top: 0.5rem;
        color: #9d9d9d;
      }
      .forgot-submit {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          font-size: 18px;

          color: #525252;
        }
      }
      .forgot-resend {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          font-size: 18px;
          margin-top: 1rem;
          color: #adadad;
        }
      }
    }
    div.input-reset {
      @extend .input-form;
      margin-top: 22vh;

      button {
        height: 3.4rem;
        margin-top: 1.2rem;
      }
      .user-input {
        height: 35px;
        padding-top: 0;
        font-size: 18px;
        color: #cdcdcd;
      }
    }
    div.input-signup {
      @extend .input-form;
      margin-top: 21.5vh;
      h3 {
        line-height: 1.2;
        margin-top: -0.25rem;
        margin-bottom: 1.25rem;
        font-size: 2.25rem;
        color: $color-royal-blue;
        font-weight: 500;
      }
      button {
        width: 10.5rem;
        height: 3.15rem;
      }
      p.action-link a {
        color: #0074d9;
      }
      .form-group {
        padding-left: 6rem;
        padding-top: 0.5rem;
      }
    }
    div.input-signup-confirm {
      @extend .input-form;
      margin-top: 25vh;
      h2 {
        margin-bottom: 1.3rem;
      }
      h6 {
        margin-bottom: 0.175rem;
      }
      p {
        color: #8e8e8e;
        font-size: 0.75rem;
      }
      button {
        width: 6.8rem;
        height: 3.1rem;
        color: #aeaeae;
        font-size: 18px;
        border-radius: 100px;
        background-color: #ffffff;
        border: solid 1px #aeaeae;
      }
      button:hover {
        color: #ffffff;
        background-color: #aeaeae;
      }
    }
    div.input-invite {
      @extend .input-form;
      margin-top: 16.25vh;
      h2 {
        margin-bottom: 1.25rem;
      }
      h6 {
        margin-bottom: 1rem;
      }
      button {
        width: 10.25rem;
        height: 3.25rem;
      }

      .user-input {
        height: 52px;
        autocomplete: off;
      }
      .divider {
        display: inline-block;
        border-left: 1px solid #e4e4e4;
        position: absolute;
        margin-top: 9px;
        margin-left: -1px;
        height: 32px;
      }
      .no-error {
        visibility: hidden;
        /* Position the tooltip */
        position: absolute;
        z-index: 10;
      }
      .error {
        visibility: visible;
        width: 190px;
        margin-left: 10px;
        display: inline-flex;
        margin-top: 7px;
        /* Position the tooltip */
        position: absolute;
        z-index: 10;
      }
      .error-image {
        transform: rotate(45deg);
      }
      .tooltip {
        width: 152px;
        background-color: #323232;
        display: flex;
        font-size: 11px;
        color: #fff;
        margin-left: 5px;
        text-align: left;
        border-radius: 5px;
        padding: 2px;
      }
      #password-input {
        padding-left: 0.6rem;
      }
      div.set-company {
        .user-input {
          margin: -0.4rem 0 0.9rem 0.5rem;
          padding-top: 0.75rem;
        }
        button {
          height: 3.15rem;
        }
      }
      div.invite-members {
        height: 100%;
        *::-webkit-input-placeholder {
          color: #7d7d7d;
        }
        div {
          display: inline-grid;
          text-align: center;
        }
        textarea {
          width: 94%;
          height: 6.5rem;
          padding: 12px 14px;
          border: 1px solid #ededed;
          resize: none;
        }
        button {
          width: 10.35rem;
          height: 3.1rem;
          margin-top: 1.5rem;
          color: #aeaeae;
          font-size: 18px;
          border-radius: 100px;
          background-color: #ffffff;
          border: solid 1px #aeaeae;
        }
        button:hover {
          color: #ffffff;
          background-color: #aeaeae;
        }
        .skip {
          margin-top: 2rem;
          font-size: 13px;
          color: #aeaeae;
        }
      }
    }
    div.form-group {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      color: #525252 !important;
    }

    .header-container {
      display: flex;
      justify-content: space-between;

      .login-logo {
        margin-top: 3vh;
        margin-left: 4vh;
        width: 176px;
        height: 34px;
      }
      .close-button {
        margin-top: 2vh;
        margin-right: 8vh;
        transform: rotate(45deg);
      }
    }

    .incorrect-input {
      text-align: center;
      font-size: 14px;
      color: #d0021b;
      margin-top: 25px;
    }
  }
`;

export const StyledHelpIconContainer = styled.div`
  display: inline-block;
  position: relative;
  top: -8px;
  left: 5px;
`;
