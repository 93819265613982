import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { fetchUser } from '../../actionCreators';
import { urlUtils } from '../../appUtils/urlUtils';
import { persistentState } from '../../service';
import { useAppDispatch, useAppSelector } from '../../shared/redux/hooks';
import { getMe } from '../../users/redux/selectors';
import { AuthenticateSuccessResponse } from '../api/types';
import { updateAccountInfo } from '../redux/actionCreators';
import { getAuth, getAuthToken, getIsAcceptedTerms } from '../redux/selectors';
import { useNavigation } from './useNavigation';

const { removeSubdirectoryFromUrlString } = urlUtils;

export const useRedirectIfLoggedIn = () => {
  const auth = useAppSelector(getAuth);

  const dispatch = useAppDispatch();
  const { searchParams, sourceUrl } = useNavigation();
  const authToken = useAppSelector(getAuthToken);
  const isAcceptedTermsInitialValue = useAppSelector(getIsAcceptedTerms);
  const me = useAppSelector(getMe);

  const getRedirectDomain = useCallback(() => {
    if (!sourceUrl) return;

    // we remove the subdirectory from the url string in case the source url looks like `https://staging.mosaicapp.com/login?redirect=/settings` in which case we just want to keep `https://staging.mosaicapp.com`
    return removeSubdirectoryFromUrlString({ url: sourceUrl });
  }, [sourceUrl]);

  const redirectIfLoggedIn = useCallback(() => {
    const isNotRedirectIfAuthenticated = searchParams.get('notRedirectIfAuthenticated') === 'true';
    if (isNotRedirectIfAuthenticated) return;

    const isTempTokenValid =
      auth.tempToken && auth.tempTokenExpiration && auth.tempTokenExpiration > new Date().valueOf();

    if (isTempTokenValid && me?.account?.is_tos_compliant) {
      persistentState.saveState({
        auth: persistentState.overridePersistantAuthStorage(auth),
      });
      // @ts-expect-error application does not know about window.ReactNativeWebView
      const reactNativeWebView = window.ReactNativeWebView;

      if (!reactNativeWebView) {
        const redirectDomain = getRedirectDomain();

        const queryParams = qs.stringify({
          access_token: auth.tempToken,
          realm_id: auth.realmId,
        });

        if (redirectDomain) {
          window.location.href = `${redirectDomain}/?${queryParams}`;
        }
        return;
      }

      if (auth.account && auth.realmId && authToken && auth.tempToken) {
        const authenticateSuccessResponse: AuthenticateSuccessResponse = {
          account: auth.account,
          realm_id: auth.realmId,
          auth_token: authToken,
          temp_token: auth.tempToken,
        };

        reactNativeWebView.postMessage(JSON.stringify({ value: authenticateSuccessResponse }));
      }
    }
  }, [auth, authToken, getRedirectDomain, me?.account?.is_tos_compliant, searchParams]);

  const isAuthenticated = auth.isAuthenticated;
  const tempToken = auth.tempToken;

  useEffect(() => {
    if (isAuthenticated || tempToken) {
      redirectIfLoggedIn();
    }
  }, [isAuthenticated, redirectIfLoggedIn, tempToken]);

  useEffect(() => {
    if (authToken && !me) {
      dispatch(fetchUser(authToken));
    }
  }, [authToken, dispatch, me]);

  useEffect(() => {
    if (isAuthenticated && isAcceptedTermsInitialValue === false) {
      dispatch(
        updateAccountInfo({
          account: {
            is_tos_compliant: true,
          },
          meta: {
            onSuccess: () => {
              if (authToken) {
                dispatch(fetchUser(authToken));
              }
            },
          },
        }),
      );
    }
  }, [authToken, dispatch, isAcceptedTermsInitialValue, isAuthenticated]);
};
