import { Selector, createSelector } from 'reselect';
import { AuthState } from './reducer';
import { RootState } from '../../shared/redux/shared';

export const getAuth: Selector<RootState, AuthState> = (state) => state.auth;

export const getAuthToken = createSelector(getAuth, (auth) => auth.token);
export const getIsAuthenticated = createSelector(getAuth, (auth) => auth.isAuthenticated);
export const getMyId = createSelector(getAuth, (auth) =>
  auth.account ? auth.account.id : undefined,
);

export const getMFASetupVerifyState = createSelector(getAuth, (state) => state.mfaSetupVerifyState);

export const getAuthMethodType = createSelector(getAuth, (state) => state.authMethodType);

export const getAuthMethodPerEnv = createSelector(getAuth, (state) => state.authMethodPerEnv);

export const getCachedSourceUrl = createSelector(getAuth, (state) => state.cachedSourceUrl);

export const getIsAcceptedTerms = createSelector(getAuth, (state) => state.isAcceptedTerms);
