import { useState, useEffect, useCallback, useMemo } from 'react';
import { LandingFormContainer } from './common/generic';
import {
  getAuthMethodPerEnv,
  getAuthMethodType,
  getIsAuthenticated,
  getMFASetupVerifyState,
} from '../../auth/redux/selectors';
import { useAppSelector } from '../../shared/redux/hooks';
import { MFAForm } from './MFAForm';
import { InitialLoginForm } from './InitialLoginForm';
import { EnvSelectionForm } from './EnvSelectionForm/EnvSelectionForm';
import { useFeatureFlags } from '../../shared/hooks/useFeatureFlags';

export const LoginForm = () => {
  const [email, _setEmail] = useState('');
  const [mfaEmail, setMfaEmail] = useState('');
  const [password, _setPassword] = useState('');
  const [mfaPassword, setMfaPassword] = useState('');

  const { rotatingLoginApplicationFlag } = useFeatureFlags();

  // we maintain in sync the state of the email and password values, both regular (used for initial login) and mfa (used for mfa setup/verify)
  const setEmail = useCallback((updatedEmail: string) => {
    _setEmail(updatedEmail);
    setMfaEmail(updatedEmail);
  }, []);

  const setPassword = useCallback((updatedPassword: string) => {
    _setPassword(updatedPassword);
    setMfaPassword(updatedPassword);
  }, []);

  const mfaSetupVerifyState = useAppSelector(getMFASetupVerifyState);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const authMethodType = useAppSelector(getAuthMethodType);
  const authMethodPerEnv = useAppSelector(getAuthMethodPerEnv);

  const possibleEnvsToSelect = useMemo(
    () => Object.keys(authMethodPerEnv) as Array<keyof typeof authMethodPerEnv>,
    [authMethodPerEnv],
  );

  const isShowEnvSelectionScreen = useMemo(
    () => !authMethodType && possibleEnvsToSelect.length > 1 && rotatingLoginApplicationFlag,
    [authMethodType, possibleEnvsToSelect.length, rotatingLoginApplicationFlag],
  );

  // if we just finished being authenticated (whether required to also go through MFA as well or not), then don't show login screen to avoid any brief flashes of the login screen
  const isShowInitialLoginForm = !isAuthenticated;

  useEffect(() => {
    // if we have just become in state that we now require mfa to be setup/verified, clear out the email and password values from the initial login form but maintain the mfa email and password values
    if (mfaSetupVerifyState) {
      _setEmail('');
      _setPassword('');
    }
  }, [mfaSetupVerifyState]);

  return (
    <LandingFormContainer className="input-login">
      {mfaSetupVerifyState ? (
        <MFAForm email={mfaEmail} password={mfaPassword} />
      ) : isShowEnvSelectionScreen ? (
        <EnvSelectionForm email={email} />
      ) : (
        isShowInitialLoginForm && (
          <InitialLoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        )
      )}
    </LandingFormContainer>
  );
};
