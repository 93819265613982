import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchTeam, logUserAction } from '../../actionCreators';
import {
  getIsSkipCCForTeam,
  getSelectedTeamBillingSubscriptionIsValid,
  getSelectedTeamId,
} from '../../selectors';

import * as constants from '../../constants';

import { BlueButton, FormWrapper, HeaderWrapper, ModalWrapper } from './common/generic';
import WidgetContainer from './common/WidgetContainer';
import { getAuth } from '../../auth/redux/selectors';

const BeginBlurbWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
`;

const BeginCancelBlurb = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  margin-top: 10px;
`;

const BeginListHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  margin-top: 29px;
`;

const BeginList = styled.ul`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;

  margin: 0px;
  padding-left: 16px;
  margin-bottom: 50px;
`;

interface BeginSignupProps {
  onDone: () => void;
  onSkipBilling: () => void;
  testOnBegin: () => void;
}

const BeginSignup: React.FC<BeginSignupProps> = ({
  children,
  onDone,
  onSkipBilling,
  testOnBegin,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const skipCCForTeam = useSelector(getIsSkipCCForTeam);
  const isTeamBillingSubscriptionValid = useSelector(getSelectedTeamBillingSubscriptionIsValid);
  const teamId = useSelector(getSelectedTeamId);

  useEffect(() => {
    if (!teamId) {
      dispatch(fetchTeam());
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (skipCCForTeam || isTeamBillingSubscriptionValid) {
      onSkipBilling();
    }
  }, [isTeamBillingSubscriptionValid, onDone, onSkipBilling, skipCCForTeam]);

  const doneAction = useCallback(() => {
    if (auth.account?.id) {
      dispatch(
        logUserAction({
          actionable_id: auth.account.id,
          actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
          action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
          entity_information: {
            user_action_type: 'signup_begin_trial',
            user_action_id: 3,
            success: true,
            error: null,
          },
        }),
      );
      testOnBegin();
      onDone();
    }
  }, [dispatch, auth.account?.id, testOnBegin, onDone]);

  return (
    <ModalWrapper>
      <FormWrapper>
        <HeaderWrapper>Try Mosaic for free</HeaderWrapper>
        <BeginBlurbWrapper>
          Manage more efficiently, increase profits. Guaranteed.
          <br />
          Try our most loved features, free.
        </BeginBlurbWrapper>
        <BeginCancelBlurb>Cancel any time.</BeginCancelBlurb>
        <BeginListHeader>Here&apos;s what I get with Mosaic:</BeginListHeader>
        <BeginList>
          <li>Resource Management</li>
          <li>Dashboard &amp; Reporting</li>
          <li>AI Automation &amp; Forecasting</li>
          <li>Project Management</li>
          <li>Budget Tracking</li>
          <li>Time Tracking</li>
        </BeginList>
        <BlueButton onClick={doneAction}>Start my free trial</BlueButton>
      </FormWrapper>
      <WidgetContainer>{children}</WidgetContainer>
    </ModalWrapper>
  );
};

export default BeginSignup;
