import VerificationInput, { VerificationInputProps } from 'react-verification-input';
import styled from 'styled-components';
import { appTheme } from '../../../shared/styles';

const containerClass = 'containerClass';
const characterClass = 'characterClass';
const characterInactiveClass = 'characterInactiveClass';
const characterSelectedClass = 'characterSelectedClass';

type AppVerificationInputProps = Pick<VerificationInputProps, 'value' | 'onChange' | 'onFocus'>;

export const AppVerificationInput = ({ value, onChange, onFocus }: AppVerificationInputProps) => {
  return (
    <InputContainer>
      <VerificationInput
        value={value}
        validChars="0-9"
        autoFocus
        placeholder=" "
        classNames={{
          container: containerClass,
          character: characterClass,
          characterInactive: characterInactiveClass,
          characterSelected: characterSelectedClass,
        }}
        onChange={onChange}
        onFocus={onFocus}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  .${containerClass} {
    height: 48px;
    margin: auto;
  }

  .${characterClass}, .${characterInactiveClass} {
    background: white;
    display: flex;
    align-items: center;
    padding: 8px 13px;
    border: 1px solid ${appTheme.colors.colorLightGray12};
    font-size: 24px;
    margin-left: 10px;
  }

  .${characterSelectedClass} {
    outline: 1px solid ${appTheme.colors.colorRoyalBlue};
  }
`;
