import { fork, take, takeEvery, takeLatest, cancel, all } from 'redux-saga/effects';
import * as constants from '../constants';

import authSagaWorkers from '../auth/redux/sagaWorkers';
import { validateInvite } from './invite';
import { logUserActionWorker } from './logging';
import {
  fetchRecurlyBillingInfoWorker,
  setRecurlyBillingTokenWorker,
  updateRecurlyBillingInfoWorker,
} from './recurly';
import { fetchUserTeams } from './teams';
import { fetchUser, updateCompany } from './users';
import { handleRequestStatusWorker } from './statuses';
import {
  activateGalaxyAccount,
  forgotPassword,
  loginUser,
  respondToMFA,
  updateAccountInfo,
  updateAccountSMS,
  updateCognitoPassword,
  updatePassword,
  verifyTOTP,
  associateTOTP,
  logoutUser,
  checkAuthMethodPerEnv,
  checkAuthMethod,
  initiateSSOLogin,
  validateSSOLogin,
} from '../auth/redux/actionCreators';

const appSagas = [
  fork(takeLatest, constants.INVITE_VALIDATION.TRIGGER, validateInvite),
  fork(takeLatest, updateAccountInfo, authSagaWorkers.updateAccountInfoWorker),
  fork(takeLatest, constants.TEAM_UPDATE.TRIGGER, updateCompany),
  fork(takeLatest, activateGalaxyAccount, authSagaWorkers.activateGalaxyAccountWorker),

  fork(takeLatest, updateAccountSMS, authSagaWorkers.updateAccountSMSWorker),
  fork(takeLatest, associateTOTP, authSagaWorkers.associateTOTPWorker),
  fork(takeLatest, verifyTOTP, authSagaWorkers.verifyTOTPWorker),
  fork(takeLatest, respondToMFA, authSagaWorkers.respondToMFAWorker),
  fork(takeLatest, forgotPassword, authSagaWorkers.forgotPasswordWorker),
  fork(takeLatest, updatePassword, authSagaWorkers.updatePasswordWorker),
  fork(takeLatest, updateCognitoPassword, authSagaWorkers.updateCognitoPasswordWorker),
  fork(takeLatest, checkAuthMethodPerEnv, authSagaWorkers.checkAuthMethodPerEnvWorker),
  fork(takeLatest, checkAuthMethod, authSagaWorkers.checkAuthMethodWorker),
  fork(takeLatest, initiateSSOLogin, authSagaWorkers.initiateSSOLoginWorker),
  fork(takeLatest, validateSSOLogin, authSagaWorkers.validateSSOLoginWorker),

  fork(takeLatest, constants.FETCH_TEAMS.TRIGGER, fetchUserTeams),
  fork(takeLatest, constants.FETCH_USER.TRIGGER, fetchUser),

  fork(takeLatest, constants.FETCH_RECURLY_BILLING_INFO.TRIGGER, fetchRecurlyBillingInfoWorker),
  fork(takeLatest, constants.SET_RECURLY_BILLING_INFO.TRIGGER, updateRecurlyBillingInfoWorker),
  fork(takeLatest, constants.SET_RECURLY_BILLING_TOKEN.TRIGGER, setRecurlyBillingTokenWorker),

  fork(takeEvery, constants.LOG_USER_ACTION.TRIGGER, logUserActionWorker),
];

export default function* rootSaga() {
  yield fork(takeLatest, loginUser, authSagaWorkers.loginUserWorker);
  yield fork(takeLatest, logoutUser, authSagaWorkers.logoutUserWorker);
  yield fork(takeEvery, '*', handleRequestStatusWorker);

  // listening for these actions lets us restart sagas after cancelling them (due to logout or refresh)
  while (take([loginUser])) {
    const nonLoginSagas = yield all([...appSagas]);
    const registeredSagas = yield all(nonLoginSagas);
    //     // upon receiving the LOGOUT action, cancel all sagas midflight
    //     // prevents redux being populated by requests that come back post-logout
    yield take(logoutUser.type);
    yield cancel(...registeredSagas);
  }
}
