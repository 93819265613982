import { createAction } from '@reduxjs/toolkit';
import * as constants from '../constants/statuses';
import { RequestStatusFields } from '../reducers/statuses';

export const removeRequestStatusId = createAction<RemoveRequestStatusIdParams>(
  constants.REMOVE_REQUEST_STATUS_ID,
);

export const setRequestStatus = createAction<SetRequestStatusParams>(constants.SET_REQUEST_STATUS);

export type RemoveRequestStatusIdParams = string | string[];

export interface SetRequestStatusParams extends RequestStatusFields {
  requestStatusId: string;
}
