import { useAppSelector } from '../redux/hooks';
import { splitFlags, SplitFlags } from '../../constants/splitFlags';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from '../redux/shared';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';

export const useFeatureFlags = () => {
  const flags = useAppSelector(getSplitFlags) as SplitFlags;
  const isLoaded = useAppSelector(getIsSplitLoaded) as boolean;

  return {
    ...flags,
    isLoaded,
  };
};
const getSplitState: Selector<RootState, RootState['splitio']> = (state) => state.splitio;

/**
 * Provides a mapping of split flags to boolean values indicating whether the
 * feature should be active or not. Add new split flags to `splitFlags` inside
 * `src/constants/splitFlags.ts` for it to be picked up.
 */
const getSplitFlags = createSelector(getSplitState, (splitState) => {
  const splitFlags = {};
  if (splitState.isReady && splitState.treatments) {
    Object.keys(splitState.treatments).forEach((flagName) => {
      splitFlags[flagName] = selectTreatmentValue(splitState, flagName) === 'on';
    });
  }

  return splitFlags;
});

const getIsSplitLoaded = createSelector(
  getSplitState,
  // we also add the check for non-empty treatments because it is possible for `splitState.isReady` to be true yet `splitState.treatments` is an empty object while there is a non-empty list of treatments
  (splitState) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore want to ignore TS knowing the length of list with exact value in lieu of having more general code written
    splitFlags.length === 0 ||
    (splitState.isReady && Object.keys(splitState.treatments).length > 0),
);
