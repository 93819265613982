import styled from 'styled-components';

export const Footer = () => {
  return (
    <RootContainer>
      <div>Mosaic ©2022</div>
      <div>
        <StyledLink href="https://resources.mosaicapp.com/privacy">Privacy</StyledLink>
      </div>
      <div>
        <StyledLink href="https://resources.mosaicapp.com/terms">Terms</StyledLink>
      </div>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  position: absolute;
  bottom: 8vh;
  font-size: 14px;
  left: 4vh;
  display: flex;
  gap: 10px;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.colorRoyalBlue};
`;
