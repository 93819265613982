import { changeEntity } from '../../sagas/generics';
import { put, select } from 'redux-saga/effects';
import * as actionCreators from '../../actionCreators';
import {
  activateGalaxyAccount,
  activateGalaxyAccountActionCreatorsMap,
  forgotPassword,
  forgotPasswordActionCreatorsMap,
  loginUser,
  loginUserActionCreatorsMap,
  updateAccountInfo,
  updateAccountInfoActionCreatorsMap,
  updateCognitoPassword,
  updateCognitoPasswordActionCreatorsMap,
  updatePassword,
  updatePasswordActionCreatorsMap,
  respondToMFA,
  respondToMFAActionCreatorsMap,
  updateAccountSMS,
  updateAccountSMSActionCreatorsMap,
  verifyTOTP,
  verifyTOTPActionCreatorsMap,
  associateTOTPActionCreatorsMap,
  associateTOTP,
  checkAuthMethod,
  checkAuthMethodActionCreatorsMap,
  initiateSSOLogin,
  initiateSSOLoginActionCreatorsMap,
  validateSSOLogin,
  validateSSOLoginActionCreatorsMap,
  checkAuthMethodPerEnvActionCreatorsMap,
  checkAuthMethodPerEnv,
} from './actionCreators';
import { getAuthToken } from './selectors';
import { authApi } from '../api';
import { mosaicAPIClients } from '../../core/API/apiClients';

function* loginUserWorker(action: ReturnType<typeof loginUser>) {
  const { payload, meta } = action;
  const { response } = yield changeEntity(
    loginUserActionCreatorsMap,
    authApi.login,
    [payload.email, payload.password],
    action,
    payload,
  );

  if (response?.realm_id) {
    mosaicAPIClients.mosaicAPI.useRealm(response.realm_id);
  }

  if (response && meta?.onSuccess) {
    meta.onSuccess(response);
  }
}

function* logoutUserWorker() {
  mosaicAPIClients.resetToDefaultBaseURL();
}

function* forgotPasswordWorker(action: ReturnType<typeof forgotPassword>) {
  yield changeEntity(
    forgotPasswordActionCreatorsMap,
    authApi.forgotPassword,
    [action.payload.email],
    action,
    action.payload,
  );
}

function* activateGalaxyAccountWorker(action: ReturnType<typeof activateGalaxyAccount>) {
  const { error, response } = yield changeEntity(
    activateGalaxyAccountActionCreatorsMap,
    authApi.activateGalaxyAccount,
    [action.payload],
    action,
    action.payload,
  );

  if (!error) {
    const authToken = response.auth_token;
    yield put(actionCreators.setToken(authToken));
    // once latest BE is merged across all envs, this call will no longer be needed
    // and can be safely removed
    yield put(
      updateAccountInfo({
        account: action.payload.accountInfo,
      }),
    );
  }
}

function* updateAccountInfoWorker(action: ReturnType<typeof updateAccountInfo>) {
  const token = yield select(getAuthToken);
  const { payload, meta } = action;

  const { response, error } = yield changeEntity(
    updateAccountInfoActionCreatorsMap,
    authApi.updateAccountInfo,
    [token, payload],
    action,
    payload,
  );

  if (response) {
    if (meta?.onSuccess) {
      meta.onSuccess();
    }
  } else if (error) {
    if (meta?.onFailure) {
      meta.onFailure();
    }
  }
}

function* updateAccountSMSWorker(action: ReturnType<typeof updateAccountSMS>) {
  const { payload, meta } = action;
  const { email, password, phoneNumber } = payload;

  const { error, response } = yield changeEntity(
    updateAccountSMSActionCreatorsMap,
    authApi.updateAccountSMS,
    [{ email, password, phoneNumber }],
    action,
    action.payload,
  );

  if (response && meta && meta.onSuccess) {
    meta.onSuccess();
  } else if (error && meta && meta.onFailure) {
    meta.onFailure();
  }
}

function* associateTOTPWorker(action: ReturnType<typeof associateTOTP>) {
  const { payload } = action;
  const { email, password } = payload;
  yield changeEntity(
    associateTOTPActionCreatorsMap,
    authApi.associateTOTP,
    [{ email, password }],
    action,
  );
}

function* verifyTOTPWorker(action: ReturnType<typeof verifyTOTP>) {
  const { payload, meta } = action;
  const { email, password, totpToken } = payload;

  const { error, response } = yield changeEntity(
    verifyTOTPActionCreatorsMap,
    authApi.verifyTOTP,
    [{ email, password, totpToken }],
    action,
    action.payload,
  );

  if (response?.realm_id) {
    mosaicAPIClients.mosaicAPI.useRealm(response.realm_id);
  }

  if (response && meta && meta.onSuccess) {
    meta.onSuccess(response);
  } else if (error && meta && meta.onFailure) {
    meta.onFailure();
  }
}

function* respondToMFAWorker(action: ReturnType<typeof respondToMFA>) {
  const { payload, meta } = action;
  const { email, mfaCode, challengeName, sessionToken } = payload;

  const { response, error } = yield changeEntity(
    respondToMFAActionCreatorsMap,
    authApi.respondToMFA,
    [
      {
        email,
        mfaCode,
        challengeName,
        sessionToken,
      },
    ],
    action,
    action.payload,
  );

  if (response && meta && meta.onSuccess) {
    meta.onSuccess(response);
  }

  if (error && meta && meta.onFailure) {
    meta.onFailure();
  }
}

function* updateCognitoPasswordWorker(action: ReturnType<typeof updateCognitoPassword>) {
  const { response } = yield changeEntity(
    updateCognitoPasswordActionCreatorsMap,
    authApi.updateCognitoPassword,
    [action.payload],
    action,
    action.payload,
  );

  if (response?.auth_token) {
    yield put(actionCreators.fetchUser(response?.auth_token));
  }
}

function* updatePasswordWorker(action: ReturnType<typeof updatePassword>) {
  const authToken = yield select(getAuthToken);
  const { error } = yield changeEntity(
    updatePasswordActionCreatorsMap,
    authApi.updatePassword,
    [authToken, action.payload.password, action.payload.firstName, action.payload.lastName],
    action,
  );

  if (!error) {
    yield put(actionCreators.fetchUser(authToken));
  }
}

function* checkAuthMethodPerEnvWorker(action: ReturnType<typeof checkAuthMethodPerEnv>) {
  yield changeEntity(
    checkAuthMethodPerEnvActionCreatorsMap,
    authApi.checkAuthMethodPerEnv,
    [action.payload],
    action,
  );
}

function* checkAuthMethodWorker(action: ReturnType<typeof checkAuthMethod>) {
  yield changeEntity(
    checkAuthMethodActionCreatorsMap,
    authApi.checkAuthMethod,
    [action.payload],
    action,
  );
}

function* initiateSSOLoginWorker(action: ReturnType<typeof initiateSSOLogin>) {
  const { payload, meta } = action;

  const { response } = yield changeEntity(
    initiateSSOLoginActionCreatorsMap,
    authApi.initiateSSOLogin,
    [payload],
    action,
  );

  if (response) {
    if (meta?.onSuccess) {
      meta.onSuccess(response);
    }
  }
}

function* validateSSOLoginWorker(action: ReturnType<typeof validateSSOLogin>) {
  const { payload, meta } = action;

  const { response } = yield changeEntity(
    validateSSOLoginActionCreatorsMap,
    authApi.validateSSOLogin,
    [payload],
    action,
  );

  if (response?.realm_id) {
    mosaicAPIClients.mosaicAPI.useRealm(response.realm_id);
  }

  if (response && meta?.onSuccess) {
    meta?.onSuccess(response);
  }
}

export default {
  loginUserWorker,
  logoutUserWorker,
  forgotPasswordWorker,
  activateGalaxyAccountWorker,
  updateAccountInfoWorker,
  updateAccountSMSWorker,
  associateTOTPWorker,
  verifyTOTPWorker,
  respondToMFAWorker,
  updateCognitoPasswordWorker,
  updatePasswordWorker,
  checkAuthMethodPerEnvWorker,
  checkAuthMethodWorker,
  initiateSSOLoginWorker,
  validateSSOLoginWorker,
};
