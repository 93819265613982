const mfaMethods = {
  sms: 'SMS_MFA',
  totp: 'SOFTWARE_TOKEN_MFA',
} as const;

const authMethods = {
  saml: 'saml',
  default: 'default',
} as const;

export const authConstants = {
  mfaMethods,
  authMethods,
};
