import warning from 'warning';
import * as Sentry from '@sentry/react';
import { mosaicAPIClients } from '../core/API/apiClients';

const HOST = process.env.REACT_APP_GATEWAY_HOST;

export function callApi(endpoint, token, fetchInit = {}, options = {}) {
  const client = options.client || mosaicAPIClients.mosaicAPI;
  let apiDone = false;
  const allowedWaitTime = 5000;
  const fullUrl = client.getFullUrl(endpoint);

  const fetchArgs = { ...fetchInit };
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = fetchArgs.headers || {};
    fetchArgs.headers.Authorization = `Bearer ${token}`;
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  fetchArgs.headers['mosaic-host'] = HOST;

  // Log to sentry if any individual api call takes longer than `allowedWaitTime`.
  const callback = setInterval(() => {
    if (!apiDone) {
      Sentry.captureMessage(`Api call took greater than ${allowedWaitTime / 1000} seconds.`);
    }
    clearInterval(callback);
  }, allowedWaitTime);

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      apiDone = true;

      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return json.then((data) => Promise.reject({ error: data }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      ({ error, response }) => ({
        error:
          !response || !response.ok
            ? error?.error_message ||
              error?.errors?.[0] ||
              error ||
              (response && response.statusText) ||
              'Error'
            : '',
        isUserFriendlyError: !!error?.error_message,
      }),
    );
}
