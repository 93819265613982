import { createReducer, Draft } from '@reduxjs/toolkit';
import { setRequestStatus, removeRequestStatusId } from '../actionCreators/statuses';

export interface RequestStatusFields<E = unknown> {
  error: E | null;
  isLoading: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
}

export const initialRequestStatusState: RequestStatusFields = {
  error: null,
  isLoading: false,
  isExecuting: false,
  isSuccess: false,
};

export interface StatusesState {
  requestStatuses: Record<string, RequestStatusFields>;
}

const initialState: StatusesState = {
  requestStatuses: {},
};

const handleSetRequestStatus = (
  state: Draft<StatusesState>,
  action: ReturnType<typeof setRequestStatus>,
) => {
  const { requestStatusId, ...statuses } = action.payload;
  state.requestStatuses[requestStatusId] = statuses;
};

const handleRemoveRequestStatusId = (
  state: Draft<StatusesState>,
  action: ReturnType<typeof removeRequestStatusId>,
) => {
  const requestStatusIdOrArray = action.payload;
  const requestStatusIdsToRemove = Array.isArray(requestStatusIdOrArray)
    ? requestStatusIdOrArray
    : [requestStatusIdOrArray];

  requestStatusIdsToRemove.forEach((requestStatusId) => {
    delete state.requestStatuses[requestStatusId];
  });
};

export const statusesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setRequestStatus, handleSetRequestStatus);
  builder.addCase(removeRequestStatusId, handleRemoveRequestStatusId);
});
