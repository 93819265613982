import 'isomorphic-fetch';
import qs from 'qs';

import { callApi } from '../appUtils/api';

export const fetchMe = (id: string, token: string) => callApi('me', token);

export const updateCompany = (token: string, account_id: number, companyName: string) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: {
        company: companyName,
        account_id: account_id,
      },
    }),
  };

  return callApi('settings', token, fetchInit);
};

export const validateInvite = (inviteToken: string) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const apiEndpoint = `invite/?id=${inviteToken}`;
  return callApi(apiEndpoint, null, fetchInit);
};

export const fetchAllTeams = (token: any) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return callApi('teams', token, fetchInit);
};

export const fetchRecurlyBillingInfo = (teamId: number, token: string) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const apiEndpoint = `teams/${teamId}/recurly_billing_info`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateRecurlyBillingInfo = (token: string, teamId: number, billingTokenId: string) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const queryParams = {
    token: billingTokenId,
  };
  const apiEndpoint = `teams/${teamId}/recurly_billing_token?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const logUserAction = (token: string, payload: any) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return callApi('log_user_activity', token, fetchInit);
};
