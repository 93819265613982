import * as constants from '../constants';
import { createActionWithMeta } from '../shared/redux/utils/createActionWithMeta';

export const setModal = () => ({});
export const updateTeam = () => ({});
export const cancelTrialExpiry = () => ({ type: 'NOOP' });
export const navigateToHome = () => ({});

export * from './recurly';

const createAction = (type: string, payload: any, metadata: any) => {
  const action: any = {
    type,
  };
  if (payload) {
    action.payload = payload;
  }
  if (metadata || payload?.metadata) {
    action.metadata = metadata || payload?.metadata;
  }
  return action;
};

export const validateInvite = (inviteToken: string) => {
  return {
    type: constants.INVITE_VALIDATION.TRIGGER,
    payload: { inviteToken },
  };
};

export const updateCompany = createActionWithMeta<UpdateCompanyInitialParams>(
  constants.TEAM_UPDATE.TRIGGER,
);

export const fetchTeam = () => ({
  type: constants.FETCH_TEAMS.TRIGGER,
  payload: {},
});

export const denyPermission = (action: any) =>
  createAction(constants.DENY_PERMISSION, action, null);

export const setToken = (token: string) => createAction(constants.SET_TOKEN, { token }, null);

export const fetchUser = (token: string) =>
  createAction(constants.FETCH_USER.TRIGGER, { token }, null);

export const logUserAction = createActionWithMeta<
  LogUserActionInitialParams,
  IMeta<() => void, () => void>
>(constants.LOG_USER_ACTION.TRIGGER);
interface UpdateCompanyInitialParams {
  token: string;
  account_id: number;
  company_name: string;
}

interface LogUserActionInitialParams {
  actionable_id: number;
  actionable_type: string;
  action_type_id: number;
  entity_information: {
    user_action_type: string;
    user_action_id: number;
    success: boolean;
    error: null;
  };
}
