import React from 'react';

import logo from '../../../images/mosaic-logo.png';
import { LogoWrapper, WidgetWrapper } from './generic';

interface WidgetContainerProps {
  children: React.ReactNode;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({ children }) => {
  return (
    <WidgetWrapper>
      <LogoWrapper>
        <img src={logo} className="login-logo" alt="Mosaic Logo" />
      </LogoWrapper>
      <div>{children}</div>
    </WidgetWrapper>
  );
};

export default WidgetContainer;
