import * as constants from '../constants';
import { logoutUser } from '../auth/redux/actionCreators';
export const initialState = {
  billingInfo: {},
  error: null,
  isLoaded: false,
  billingTokenId: null,
  isWaiting: false,
};
const recurly = (state = initialState, action) => {
  switch (action.type) {
    case logoutUser.type:
      return initialState;
    case constants.SET_RECURLY_BILLING_TOKEN.TRIGGER: {
      const { billingTokenId } = action.payload;
      return {
        ...state,
        billingTokenId,
        isWaiting: true,
      };
    }
    case constants.SET_RECURLY_BILLING_INFO.SUCCESS: {
      return {
        ...state,
        isWaiting: false,
      };
    }
    case constants.SET_RECURLY_BILLING_INFO.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isWaiting: false,
        error,
      };
    }
    case constants.FETCH_RECURLY_BILLING_INFO.SUCCESS: {
      const { response } = action.payload;
      return {
        billingInfo: response.billing_info,
        isLoaded: true,
        error: null,
      };
    }
    default:
      return state;
  }
};
export default recurly;
