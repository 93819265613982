import ReactTooltip from 'react-tooltip';
import sanitizeHtml from 'sanitize-html';

export const AppTooltip = () => {
  return (
    <ReactTooltip
      id="app-tooltip"
      getContent={(dataTip) => {
        return sanitizeHtml(dataTip, {
          allowedClasses: { '*': ['*'] },
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'input']),
        });
      }}
    />
  );
};
