import snakeCase from 'lodash/snakeCase';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Returns the item back with all object keys (INCLUDING NESTED OBJECTS AND OBJECTS WITHIN ARRAYS)
 * converted to snake case.
 * @param {any} item the item to be converted
 * @returns a copy of the item with snake case keys
 */
export const convertKeysToSnakeCase = (item: any[] | Record<any, any>) => {
  if (Array.isArray(item)) {
    return item.map((subItem) => convertKeysToSnakeCase(subItem));
  } else if (isPlainObject(item)) {
    return Object.entries(item).reduce((acc, cur) => {
      const [key, value] = cur;
      const snakeCaseKey = snakeCase(key);

      if (typeof value === 'object' && value !== null) {
        // value is array or object
        acc[snakeCaseKey] = convertKeysToSnakeCase(value);
      } else {
        acc[snakeCaseKey] = value;
      }
      return acc;
    }, {});
  } else {
    return item;
  }
};
