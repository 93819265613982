import React from 'react';
import { withRouter } from 'react-router-dom';
import ResetPasswordContainer from './ResetPasswordContainer';

const ResetRouter = (props) => {
  const inviteToken = props.match.params.inviteToken;
  const params = new URLSearchParams(props.location.search);
  const email = params.get('email');
  const confirmation_code = params.get('confirmation_code');
  if (inviteToken) {
    return <ResetPasswordContainer inviteToken={inviteToken} />;
  } else if (email && confirmation_code) {
    return <ResetPasswordContainer email={email} confirmationCode={confirmation_code} isCognito />;
  }
  return null;
};

export default withRouter(ResetRouter);
