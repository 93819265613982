import * as constants from '../../constants';
import { logoutUser } from '../../auth/redux/actionCreators';
import { Me } from '../models/me';
import { AnyAction, createReducer, Draft } from '@reduxjs/toolkit';

export interface UsersState {
  me: Nullable<Me>;
}

const initialState: UsersState = {
  me: null,
};

const handleUpdateMe = (state: Draft<UsersState>, action: AnyAction) => {
  state.me = action.payload.response;
};

export const usersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutUser, () => initialState);
  builder.addCase(constants.FETCH_USER.SUCCESS, handleUpdateMe);
});
