import { buildRealmURL, updateAPIClientBaseURL } from './utils';
import { APIClient, APIClientConfig } from './APIClient';

// it only handles baseURL for now to support dynamic baseURL for realm
export class RealmAPIClient extends APIClient {
  private readonly defaultBaseURL: string;

  constructor(config: APIClientConfig) {
    super(config);
    this.config = config;
    this.defaultBaseURL = config.baseURL;
    this.retrieveRealmBaseURL();
  }

  private retrieveRealmBaseURL() {
    updateAPIClientBaseURL({ APIClient: this });
  }

  private updateBaseURL(baseURL: string) {
    this.config.baseURL = baseURL;
  }

  resetToDefaultBaseURL() {
    this.updateBaseURL(this.defaultBaseURL);
  }

  useRealm(realmId: string) {
    const newBaseURL = buildRealmURL({
      baseURL: this.defaultBaseURL,
      realmId: realmId,
    });

    this.updateBaseURL(newBaseURL);
  }
}
