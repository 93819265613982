import { put } from 'redux-saga/effects';
import { api } from '../service';
import { changeEntity, fetchEntity } from './generics';
import * as entityActions from '../actions';
import * as actionCreators from '../actionCreators';
import { logoutUser } from '../auth/redux/actionCreators';

const { userFetch, companyUpdate } = entityActions;

export function* updateCompany(action) {
  const { error } = yield changeEntity(
    companyUpdate,
    api.updateCompany,
    [action.payload.token, action.payload.account_id, action.payload.company_name],
    action,
    action.payload,
  );

  if (!error) {
    yield put(actionCreators.fetchTeam());
  }
}

export function* fetchUser(action) {
  /*
    condition prevents fetch of null user on nav to invite
    during active session
  */
  const token = action.payload.token;

  if (!window.location.href.includes('/invite/')) {
    const { error, response } = yield fetchEntity(
      userFetch,
      api.fetchMe,
      undefined,
      [token],
      action,
    );
    if (error || !response) {
      yield put(logoutUser());
    }
  }
}
