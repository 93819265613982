import { Input } from 'reactstrap';
import styled from 'styled-components';

interface TermsOfServiceCheckboxProps {
  isChecked: boolean;
  onToggle: () => void;
}

export const TermsOfServiceCheckbox = ({ isChecked, onToggle }: TermsOfServiceCheckboxProps) => {
  return (
    <CheckBoxWrapper className="no-wrap">
      <CheckBoxInput addon checked={isChecked} onChange={onToggle} type="checkbox" />
      <CheckBoxSpan>
        {' '}
        I agree to all{' '}
        <a
          href="https://mosaicapp.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'none' }}
        >
          Terms, Policies and Conditions.
        </a>
      </CheckBoxSpan>
    </CheckBoxWrapper>
  );
};

const CheckBoxWrapper = styled.div`
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;

  &.no-wrap {
    white-space: nowrap;
  }
`;

const CheckBoxInput = styled(Input)`
  width: 18px;
  height: 18px;
  margin: 3px;
  vertical-align: middle;

  color: ${({ theme }) => theme.colors.colorDarkBlueTooltip};
`;

const CheckBoxSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  vertical-align: middle;
`;
