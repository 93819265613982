import { put, apply, cancelled } from 'redux-saga/effects';
import { denyPermission } from '../actionCreators';
import { logoutUser } from '../auth/redux/actionCreators';

import { GENERIC_ACTION } from '../constants';

import { IGNORED_ERROR_ACTIONS, IGNORED_ERROR_RESPONSES } from '../constants/ignoredErrors';

import { SURFACE_403_ACTIONS } from '../constants/surfaceForbiddenFailures';

const makePermissionDeniedMissingActionDevWarning = (apiFn, entityFnName, position) =>
  // eslint-disable-next-line no-console
  console.error(
    `Permission was denied by the server, but changeEntity was not passed the action as the argument at position ${position}.
    Check the saga that calls ${apiFn.name} to ensure it passes the action to ${entityFnName}`,
  );
const makePermissionDeniedMissingPermissionDevWarning = (actionType) =>
  // eslint-disable-next-line no-console
  console.error(
    `Permission was denied by the server, but the permissions middleware permitted the action.
    Check the actionCreator that creates actions with a type of ${actionType} to ensure it is checking permission before making the request`,
  );

// TODO: clean this up

export function* changeEntity(entity, apiFn, body, action, sagaPayload = null) {
  const meta = action?.meta;
  const initialPayload = sagaPayload || action?.payload;
  try {
    yield put(entity.request(initialPayload));
    const obj = yield apply(null, apiFn, body);
    const { response, error } = obj;
    if (response && response.status === 403) {
      yield put(
        denyPermission({
          type: action?.type ?? GENERIC_ACTION,
          errorMessage: error,
          apiFnName: apiFn.name,
        }),
      );
      if (SURFACE_403_ACTIONS[action?.type]) {
        yield put(entity.failure(error, response, initialPayload, meta));
      }
      if (process.env.NODE_ENV !== 'production') {
        if (!action?.type) {
          makePermissionDeniedMissingActionDevWarning(apiFn, 'changeEntity', 4);
        } else {
          makePermissionDeniedMissingPermissionDevWarning(action?.type);
        }
      }
    } else if (response && response.status === 401) {
      // yield call(window.Intercom, 'shutdown');
      yield put(entity.failure(error, response, initialPayload, meta));
    } else if (!error) {
      yield put(entity.success(response, body, initialPayload, meta));
    } else if (error) {
      yield put(entity.failure(error, response, initialPayload, meta));
      if (!IGNORED_ERROR_RESPONSES[error] && !IGNORED_ERROR_ACTIONS[action?.type]) {
        // yield put(
        // TODO: Handle Errors.
        // handleErrorMessage({
        //   type: action?.type ?? GENERIC_ACTION,
        //   isUserFriendlyError,
        //   errorMessage: error,
        //   apiFnName: apiFn.name
        // })
        // );
        // TODO: Sentry capture errors.
        // captureError(error, response, action?.type);
      }
    } else {
      yield put(entity.failure(error, response, initialPayload, meta));
    }
    return { error, response };
  } finally {
    if (yield cancelled() && entity.abort) {
      yield put(entity.abort(initialPayload, meta));
    }
  }
}

export function* fetchEntity(entity, apiFn, id, body, action) {
  const initialActionPayload = action.payload;
  const meta = action.meta;

  try {
    yield put(entity.request(id, initialActionPayload));
    const { response, error, isUserFriendlyError } = yield apply(null, apiFn, [id, ...body]);
    if (response && response.status === 403) {
      yield put(
        denyPermission({
          type: action?.type ?? GENERIC_ACTION,
          isUserFriendlyError,
          errorMessage: error,
          apiFnName: apiFn.name,
        }),
      );
      if (SURFACE_403_ACTIONS[action?.type]) {
        yield put(entity.failure(error, id, initialActionPayload));
      }
      if (process.env.NODE_ENV !== 'production') {
        if (!action?.type) {
          makePermissionDeniedMissingActionDevWarning(apiFn, 'fetchEntity', 4);
        } else {
          makePermissionDeniedMissingPermissionDevWarning(action?.type);
        }
      }
    } else if (response && response.status === 401) {
      yield put(logoutUser());
      // yield call(window.Intercom, 'shutdown');
      // history.push('/login');
    } else if (!error) {
      yield put(entity.success(response, id, initialActionPayload));
    } else if (error) {
      yield put(entity.failure(error, response, initialActionPayload));
      if (!IGNORED_ERROR_RESPONSES[error] && !IGNORED_ERROR_ACTIONS[action?.type]) {
        // yield put(
        //   handleErrorMessage({
        //     type: action?.type ?? GENERIC_ACTION,
        //     isUserFriendlyError,
        //     errorMessage: error,
        //     apiFnName: apiFn.name
        //   })
        // );
        // captureError(error, response, action?.type);
      }
    } else {
      yield put(entity.failure(error, id, initialActionPayload));
    }
    return { error, response };
  } finally {
    if (yield cancelled() && entity.abort) {
      yield put(entity.abort(initialActionPayload, meta));
    }
  }
}
