import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { BaseButton } from './Button/styles';
import { appTheme } from '../../shared/styles';

interface TextButtonProps {
  children: ReactNode;
  disabled?: boolean;
  isIncludeHoverEffect?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const TextButton = ({
  children,
  disabled,
  isIncludeHoverEffect = true,
  onClick,
}: TextButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      $isIncludeHoverEffect={isIncludeHoverEffect}
    >
      {children}
    </Button>
  );
};

const Button = styled(BaseButton)<{ $isIncludeHoverEffect: boolean }>`
  &:hover {
    color: ${appTheme.colors.colorRoyalBlue};

    ${(props) =>
      props.$isIncludeHoverEffect ? `background-color: ${appTheme.colors.colorLightGray19}` : ''};
  }
`;
