import React, { ReactNode } from 'react';

export interface SvgIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  children?: ReactNode;
  viewBox?: string;
  className?: string;
  title?: string;
  fill?: string;
  xmlns?: string;
}

const _SvgIcon = ({
  width,
  height,
  color,
  title,
  children,
  viewBox,
  className,
  ...rest
}: SvgIconProps) => {
  return (
    <svg className={className} width={width} height={height} viewBox={viewBox} {...rest}>
      {title ? <title>{title}</title> : null}
      {children}
      <style>{`
        svg {
          vertical-align: middle;
          font-weight: 400;
          fill: ${color};
        }
      `}</style>
    </svg>
  );
};

export const SvgIcon = React.memo(_SvgIcon);
