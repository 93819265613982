export const action = (type: string, payload = {}) => ({ type, ...payload });

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const TRIGGER = 'TRIGGER';
const ABORT = 'ABORT';

export const SUCCESS_SUFFIX = '_SUCCESS';
export const FAILURE_SUFFIX = '_FAILURE';
export const ABORT_SUFFIX = '_ABORT';
export const REQUEST_SUFFIX = '_REQUEST';

export const REQUEST_TYPES = {
  REQUEST,
  SUCCESS,
  FAILURE,
  TRIGGER,
  ABORT,
};

const OPTS_DEFAULT = {
  createTrigger: true,
};
export const createRequestTypes = (
  base: string,
  { createTrigger } = OPTS_DEFAULT,
): RequestTypes => {
  const actions = [REQUEST, SUCCESS, FAILURE, ABORT];
  if (createTrigger) {
    actions.push(TRIGGER);
  }
  return actions.reduce((acc, type) => ({ ...acc, [type]: `${base}_${type}` }), {}) as RequestTypes;
};

export const getActionWithSuffix =
  (suffix: string) =>
  (actionType: string): string =>
    actionType.replace('_TRIGGER', '') + suffix;

export const success = getActionWithSuffix(SUCCESS_SUFFIX);
export const failure = getActionWithSuffix(FAILURE_SUFFIX);
export const abort = getActionWithSuffix(ABORT_SUFFIX);
export const request = getActionWithSuffix(REQUEST_SUFFIX);

export const getIsSuccessAction = (actionType: string): boolean =>
  actionType.endsWith(SUCCESS_SUFFIX);

export const getIsFailureAction = (actionType: string): boolean =>
  actionType.endsWith(FAILURE_SUFFIX);

export const getIsRequestAction = (actionType: string): boolean =>
  actionType.endsWith(REQUEST_SUFFIX);

export const getIsAbortAction = (actionType: string): boolean => actionType.endsWith(ABORT_SUFFIX);

export const getIsTriggerAction = (actionType: string): boolean => actionType.endsWith('_TRIGGER');

export const getActionRequestType = (actionType: string): string | null => {
  const splitActionType = actionType.split('_');
  const requestType = splitActionType[splitActionType.length - 1];
  return REQUEST_TYPES[requestType] || null;
};

export interface RequestTypes {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  TRIGGER: string;
  ABORT: string;
}
