import { logoutUser } from '../auth/redux/actionCreators';
import * as constants from '../constants';

export const initialState = {
  allTeams: [],
  selectedTeam: null,
  selectedMemberId: null,
  isFetchingMember: false,
};

const teams = (state = initialState, action) => {
  switch (action.type) {
    case logoutUser.type: {
      return initialState;
    }
    case constants.FETCH_TEAMS.SUCCESS: {
      const { teams } = action.payload.response;
      return {
        ...state,
        allTeams: teams,
        selectedTeam:
          (state.selectedTeam
            ? teams.find((team) => team.id === state.selectedTeam.id)
            : state.selectedTeam) || teams[0],
      };
    }
    default: {
      return state;
    }
  }
};

export default teams;
