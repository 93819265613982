import styled from 'styled-components';

export const PlanCostYearly = styled.span`
  font-size: 14px;
  line-height:14px;

  color: #626E8E;
`;

export const PlanCostToday = styled.span`
  font-size: 14px;
  line-height:14px;

  color: #13225C;
`;

export const LeftDiv = styled.div`
  width: 60%;
  text-align: left;
`;

export const RightDiv = styled.div`
  width: 40%;
  text-align: right;
`;