import { getRequestStatus } from '../../selectors/statuses';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeRequestStatusId } from '../../actionCreators/statuses';
import { useCallback } from 'react';
import { useUnmount } from 'react-use';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRequestStatus = <E = unknown>({
  requestStatusId,
}: {
  requestStatusId: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector((state) =>
    requestStatusId ? getRequestStatus<E>(state, requestStatusId) : undefined,
  );

  const removeStatus = useCallback(() => {
    if (requestStatus && requestStatusId) {
      dispatch(removeRequestStatusId(requestStatusId));
    }
  }, [dispatch, requestStatus, requestStatusId]);

  useUnmount(removeStatus);

  return { status: requestStatus, removeStatus };
};
