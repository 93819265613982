import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BlueButton, HeaderWrapper, ModalWrapper } from './common/generic';
import { CompletedFilledIcon } from '../../images/CompletedFilledIcon';
import FileIcon from '../../assets/imgs/File.svg';
import GroupIcon from '../../assets/imgs/Group.svg';
import CalendarIcon from '../../assets/imgs/Calendar.svg';
import BankCardIcon from '../../assets/imgs/BankCard.svg';
import ClockIcon from '../../assets/imgs/Clock.svg';
import BriefcaseIcon from '../../assets/imgs/Briefcase.svg';

import { Button } from 'reactstrap';
import styled from 'styled-components';
import { logUserAction } from '../../actionCreators';

import * as constants from '../../constants';
import { appTheme } from '../../shared/styles';
import { updateAccountInfo } from '../../auth/redux/actionCreators';
import { getAuth } from '../../auth/redux/selectors';

const UsageModalWrapper = styled.div`
  position: relative;

  padding-left: 54px;
  padding-right: 54px;
  padding-top: 25px;
`;

const UsageTypesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 864px;
  height: 320px;

  margin-top: 32px;
  margin-bottom: 26px;
`;

const UsageButton = styled(Button)`
  position: relative;
  display: inline-block;
  width: 268px;
  height: 150px;

  background: transparent;

  border: 1px solid ${(props) => (props.selected ? '#2952EC' : 'rgba(17,17,19,0.2)')};

  text-align: center;

  border-radius: 4px;

  margin: 10px;
  padding: 0px;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: inherit;
    border: 1px solid ${(props) => (props.selected ? '#2952EC' : 'rgba(17,17,19,0.2)')};
    color: inherit;
    box-shadow: none;
  }
`;

const UsageLabel = styled.div`
  margin-top: 13px;

  font-size: 14px;
  line-height: 28px;
  color: black;
`;

const UsageNextButton = styled(BlueButton)`
  position: absolute;
  width: 160px;
  height: 64px;
  font-size: 16px;
  line-height: 24px;

  left: initial;
  right: 64px;
  bottom: 36px;
`;

const UsageSelectedImage = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`;

type UsageType = {
  title: string;
  alt: string;
  icon: string;
};

interface UsageSignupProps {
  selectedUsageTypes: Set<string>;
  setSelectedUsageTypes: (values: Set<string>) => void;
  onDone: () => void;
}

const UsageSignup: React.FC<UsageSignupProps> = ({
  selectedUsageTypes,
  setSelectedUsageTypes,
  onDone,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const usageTypes: { [key: string]: UsageType } = {
    staffing_projects: {
      title: 'Staffing Projects',
      alt: 'staffing projects',
      icon: GroupIcon,
    },
    project_management: {
      title: 'Project Management',
      alt: 'project management',
      icon: FileIcon,
    },
    schedule_projects: {
      title: 'Schedule Projects',
      alt: 'schedule projects',
      icon: CalendarIcon,
    },
    monitor_budgets: {
      title: 'Monitor Budgets',
      alt: 'monitor budgets',
      icon: BankCardIcon,
    },
    track_time: {
      title: 'Track Time',
      alt: 'track time',
      icon: ClockIcon,
    },
    understand_the_business: {
      title: 'Understanding the Business',
      alt: 'understanding the business',
      icon: BriefcaseIcon,
    },
  };

  const handleSubmit = useCallback(() => {
    if (selectedUsageTypes.size > 0) {
      dispatch(
        updateAccountInfo({
          usage_types: Array.from(selectedUsageTypes),
        }),
      );
    }
    onDone();
  }, [dispatch, onDone, selectedUsageTypes]);

  const doneAction = useCallback(() => {
    if (auth.account?.id) {
      dispatch(
        logUserAction({
          actionable_id: auth.account.id,
          actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
          action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
          entity_information: {
            user_action_type: 'signup_usage_info',
            user_action_id: 6,
            success: true,
            error: null,
          },
        }),
      );
      handleSubmit();
    }
  }, [dispatch, handleSubmit, auth.account?.id]);

  const renderUsageButton = (usage: string, usageParams: UsageType) => {
    const handleClick = () => {
      if (selectedUsageTypes.has(usage)) {
        setSelectedUsageTypes(new Set(Array.from(selectedUsageTypes).filter((x) => x !== usage)));
      } else {
        setSelectedUsageTypes(new Set(selectedUsageTypes).add(usage));
      }
    };

    return (
      <UsageButton selected={selectedUsageTypes.has(usage)} onClick={handleClick}>
        <img src={usageParams.icon} alt={usageParams.alt} />
        <UsageLabel>
          {selectedUsageTypes.has(usage) ? (
            <UsageSelectedImage>
              <CompletedFilledIcon
                fillColor={appTheme.colors.colorDarkGreen2}
                title="selected"
                width={22}
                height={22}
              />
            </UsageSelectedImage>
          ) : null}
          {usageParams.title}
        </UsageLabel>
      </UsageButton>
    );
  };

  return (
    <ModalWrapper>
      <UsageModalWrapper>
        <HeaderWrapper>What brings you to Mosaic?</HeaderWrapper>
        <div>
          Get the most out of Mosaic by telling us what you will be using it for. Select all that
          apply.
        </div>
        <UsageTypesWrapper>
          {Object.keys(usageTypes).map((key) => renderUsageButton(key, usageTypes[key]))}
        </UsageTypesWrapper>
        <UsageNextButton onClick={doneAction}>Next</UsageNextButton>
      </UsageModalWrapper>
    </ModalWrapper>
  );
};

export default UsageSignup;
