import { useCallback, useState } from 'react';
import EyeIcon from '../../assets/imgs/Eye.svg';
import EyeSlashIcon from '../../assets/imgs/EyeSlash.svg';

export const usePasswordShowHideIcon = () => {
  const [isPasswordVisibleInPlainText, setIsPasswordVisibleInPlainText] = useState(false);

  const PasswordVisibilityIcon = isPasswordVisibleInPlainText ? EyeSlashIcon : EyeIcon;
  const passwordInputType = isPasswordVisibleInPlainText ? 'text' : 'password';

  const togglePasswordVisibility = useCallback(
    () => setIsPasswordVisibleInPlainText(!isPasswordVisibleInPlainText),
    [isPasswordVisibleInPlainText],
  );

  return { passwordInputType, PasswordVisibilityIcon, togglePasswordVisibility };
};
