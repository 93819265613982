import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { Form } from 'reactstrap';
import styled from 'styled-components';

import {
  AccountInput,
  BackIcon,
  BlueButton,
  FormWrapper,
  HeaderWrapper,
  ModalWrapper,
} from './common/generic';
import WidgetContainer from './common/WidgetContainer';

import { logUserAction, updateCompany } from '../../actionCreators';

import * as constants from '../../constants';
import { useAppSelector, useAppDispatch } from '../../shared/redux/hooks';
import { getRequestStatus } from '../../selectors/statuses';
import { removeRequestStatusId } from '../../actionCreators/statuses';
import { Space } from '../shared/Space';
import { LoadingWheel } from '../../images/LoadingWheel';
import { getAuthToken, getMyId } from '../../auth/redux/selectors';
import { getMe } from '../../users/redux/selectors';

const TeamBlurb = styled.div`
  width: 330px;

  margin-bottom: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  color: #13225c;
`;

const TeamLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  margin-top: 16px;

  color: rgba(19, 34, 92, 0.7);
`;

const ErrorMessage = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorCalendarRed};
`;

const GapContainer = styled.div.attrs<{ $gap: number }>((props) => ({
  style: { gap: `${props.$gap}px` },
}))<{ $gap: number }>`
  display: flex;
  flex-direction: column;
`;

const TeamNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface TeamSignupProps {
  teamName: string;
  setTeamName: (value: string) => void;
  onDone: () => void;
  onBackStep: () => void;
}

const TeamSignup: React.FC<TeamSignupProps> = ({
  children,
  teamName,
  setTeamName,
  onDone,
  onBackStep,
}) => {
  const dispatch = useAppDispatch();

  const me = useAppSelector(getMe);
  const token = useAppSelector(getAuthToken);
  const myId = useAppSelector(getMyId);

  const { updateCompanyRequestStatus } = useAppSelector((state) => ({
    updateCompanyRequestStatus: getRequestStatus<unknown>(state, updateCompany.type),
  }));

  const sendTeamInfo = useCallback(() => {
    // if already have a team, just move on to next step
    if (me?.hasCompany) {
      onDone();
    }

    // Skip apicall if teamName not ready
    if (!teamName) {
      return;
    }

    if (token && myId) {
      dispatch(
        updateCompany({
          token,
          account_id: myId,
          company_name: teamName,
          meta: {
            requestStatusId: updateCompany.type,
          },
        }),
      );
    }
  }, [dispatch, me?.hasCompany, myId, onDone, teamName, token]);

  const doneAction = useCallback(() => {
    if (myId) {
      dispatch(
        logUserAction({
          actionable_id: myId,
          actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
          action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
          entity_information: {
            user_action_type: 'signup_tream',
            user_action_id: 2,
            success: true,
            error: null,
          },
        }),
      );
      sendTeamInfo();
    }
  }, [dispatch, myId, sendTeamInfo]);

  useEffect(() => {
    if (updateCompanyRequestStatus?.isSuccess) {
      dispatch(removeRequestStatusId(updateCompany.type));
      onDone();
    }
  }, [updateCompanyRequestStatus?.isSuccess, dispatch, onDone]);

  const clearRequestStatuses = useCallback(() => {
    if (updateCompanyRequestStatus) {
      dispatch(removeRequestStatusId(updateCompany.type));
    }
  }, [dispatch, updateCompanyRequestStatus]);

  const isPageDone = useMemo(() => {
    return teamName.trim().length > 0;
  }, [teamName]);

  const handleTeamNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTeamName(event.target.value);
      clearRequestStatuses();
    },
    [clearRequestStatuses, setTeamName],
  );

  const teamValue = me?.account?.default_team?.name || teamName;
  const isExecuting = updateCompanyRequestStatus?.isExecuting;

  return (
    <ModalWrapper>
      <FormWrapper>
        <Form>
          <HeaderWrapper onClick={onBackStep}>
            <BackIcon />
            Tell us about your team
          </HeaderWrapper>
          <TeamBlurb>
            Personalizing your team with your brand makes all your team members feel at home.
          </TeamBlurb>
          <TeamNameContainer>
            <GapContainer $gap={10}>
              <TeamLabel>Team Name</TeamLabel>
              <AccountInput
                value={teamValue}
                disabled={me?.hasCompany}
                onChange={handleTeamNameChange}
                placeholder="Team Name"
              />
            </GapContainer>
            <BlueButton
              disabled={me?.hasCompany ? false : !isPageDone || isExecuting}
              onClick={doneAction}
            >
              {isExecuting ? <LoadingWheel /> : 'Continue'}
            </BlueButton>
            {updateCompanyRequestStatus?.error && (
              <>
                <Space value={10} vertical />
                <ErrorMessage>An error occurred. Please try again</ErrorMessage>
              </>
            )}
          </TeamNameContainer>
        </Form>
      </FormWrapper>
      <WidgetContainer>{children}</WidgetContainer>
    </ModalWrapper>
  );
};

export default TeamSignup;
