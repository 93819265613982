export interface APIClientConfig {
  baseURL: string;
}

export class APIClient {
  config: APIClientConfig;

  constructor(config: APIClientConfig) {
    this.config = config;
  }

  updateConfig(config: Partial<APIClientConfig>) {
    this.config = { ...this.config, ...config };
  }

  getFullUrl(endpoint: string): string {
    const apiURL = this.config.baseURL;
    return endpoint.indexOf(apiURL) === -1 ? apiURL + endpoint : endpoint;
  }
}
