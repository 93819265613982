export const plansHash: any = {
  professional: {
    name: 'Professional',
    price: 9.99,
  },
  business: {
    name: 'Business',
    price: 14.99,
  },
  enterprise: {
    name: 'Enterprise',
    price: null,
  },
};

export const renderYearlyPrice = (price: number) => {
  return <>{price ? '$' + (price * 12).toFixed(2) + '/year' : 'contact us'}</>;
};
