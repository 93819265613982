import React, { useState, useCallback } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import logo from '../../images/mosaic-logo.png';
import close from '../../images/close-to-login.svg';

import { LandingContainerStyledWrapper } from './common/generic';
import { useLifecycles } from 'react-use';
import { Footer } from '../App/Footer/Footer';

const ForgotPasswordContainer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();

  const updateWindowDimensions = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLifecycles(
    () => {
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);
    },
    () => {
      window.removeEventListener('resize', updateWindowDimensions);
    },
  );

  const loginClick = useCallback(
    (email?: string) => {
      const isMobile = width < 500;

      if (isMobile) {
        window.location.href = 'mosaicmobile://login';
      } else {
        history.push({
          pathname: '/login',
          search: email ? `?prefilledEmail=${email}` : undefined,
        });
      }
    },
    [history, width],
  );

  return (
    <LandingContainerStyledWrapper>
      <>
        <div className="auth-page">
          {width > 500 ? (
            <div className="header-container">
              <img
                src={logo}
                className="login-logo"
                onClick={() => loginClick()}
                alt="Mosaic Logo"
              />
              <img
                src={close}
                className="close-button"
                onClick={() => loginClick()}
                alt="Exit Button"
              />
            </div>
          ) : null}
          <ForgotPasswordForm width={width} loginClick={loginClick} />
        </div>
        <Footer />
      </>
    </LandingContainerStyledWrapper>
  );
};

export default withRouter(ForgotPasswordContainer);
