import { SyntheticEvent, useEffect, useState, useCallback } from 'react';

import styled from 'styled-components';

import { LandingFormContainer } from './common/generic';
import { useAppDispatch } from '../../shared/redux/hooks';
import { useRequestStatus } from '../../shared/hooks/useRequestStatus';
import { OutlinedButton } from '../shared/OutlinedButton';
import { LoadingWheel } from '../../images/LoadingWheel';
import { forgotPassword } from '../../auth/redux/actionCreators';

interface ForgotPasswordFormProps {
  width: number;
  loginClick: (email: string) => void;
}

export const ForgotPasswordForm = ({ width, loginClick }: ForgotPasswordFormProps) => {
  const [email, setEmail] = useState('');
  const [headerText, setHeaderText] = useState('Reset Password');
  const [messageSent, setMessageSent] = useState(
    "We'll send you an email with a link to reset your password.",
  );
  const [reminderMessage, setReminderMessage] = useState('');
  const [validationError, setValidationError] = useState('');
  const dispatch = useAppDispatch();

  const { status: forgotPasswordRequestStatus, removeStatus: removeForgotPasswordRequestStatus } =
    useRequestStatus<string>({ requestStatusId: forgotPassword.type });

  const isMobile = width < 500;

  const clearValidations = useCallback(() => {
    setValidationError('');
    removeForgotPasswordRequestStatus();
  }, [removeForgotPasswordRequestStatus]);

  const formSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    clearValidations();

    if (!email) {
      setValidationError('Cannot enter a blank email address.');
      return;
    }

    dispatch(forgotPassword({ email, requestStatusId: forgotPassword.type }));
  };

  useEffect(() => {
    if (forgotPasswordRequestStatus?.isSuccess) {
      setHeaderText(`Email Sent`);
      setMessageSent(`Email with reset link has been sent to ${email}`);
      setReminderMessage(
        `If you don't get an email from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder, look for an email coming from login.mosaicapp.com domain.`,
      );
    }
  }, [forgotPasswordRequestStatus?.isSuccess, email]);

  const _loginClick = () => {
    loginClick(email);
  };

  return (
    <ForgotPasswordFormContainer className="input-forgot">
      <h2 className="forgot-header">{headerText}</h2>
      <p className="forgot-tagline"> {messageSent}</p>
      <p className="password-reset-reminder">{reminderMessage}</p>
      {validationError || forgotPasswordRequestStatus?.error ? (
        <div className="auth-modal-error">
          <span className="auth-modal-error-message">
            {validationError || forgotPasswordRequestStatus?.error}
          </span>
        </div>
      ) : null}

      <div className="form-group">
        <StyledForm onSubmit={formSubmit}>
          <InputContainer>
            {!reminderMessage ? (
              <StyledInput
                type="email"
                defaultValue={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={clearValidations}
                disabled={forgotPasswordRequestStatus?.isExecuting}
                placeholder="Enter your email address"
                required
                className={'user-input'}
              />
            ) : null}
          </InputContainer>
          {!reminderMessage ? (
            <div className="forgot-submit set-password-modal-actions">
              <OutlinedButton
                label={isMobile ? 'Submit' : 'Request Reset Link'}
                isDisabled={forgotPasswordRequestStatus?.isExecuting}
                isLoading={forgotPasswordRequestStatus?.isExecuting}
                onClick={formSubmit}
              />

              <br />
              <div id="login-link" onClick={_loginClick}>
                Back to Login
              </div>
            </div>
          ) : (
            <div className="forgot-resend set-password-modal-actions">
              <div id="forgot-password-button" onClick={formSubmit}>
                {forgotPasswordRequestStatus?.isExecuting ? <LoadingWheel /> : 'Resend'}
              </div>

              <br />
              <OutlinedButton label="Back to Login" onClick={_loginClick} />
            </div>
          )}
        </StyledForm>
      </div>
    </ForgotPasswordFormContainer>
  );
};

const ForgotPasswordFormContainer = styled(LandingFormContainer)`
  margin: auto;
  max-width: 550px;

  .form-group {
    margin: 20px;
  }

  .auth-modal-error {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .auth-modal-error-message {
      font-size: 0.9rem;
      color: #b51732;
      width: 66%;
      text-align: center;
    }
  }
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 69%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
`;
