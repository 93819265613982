import { createAction } from '@reduxjs/toolkit';
import { RequestTypes } from '../../../appUtils';

const makeRequestCreateAction = <InitialPayloadType>(actionRequestTypes: RequestTypes) =>
  createAction<{ initialPayload: InitialPayloadType }>(actionRequestTypes.REQUEST);

const makeSuccessCreateAction = <SuccessResponseType, InitialPayloadType, MetaType>(
  actionRequestTypes: RequestTypes,
) =>
  createAction(
    actionRequestTypes.SUCCESS,
    (
      response: SuccessResponseType,
      _: unknown,
      initialPayload: InitialPayloadType,
      meta: MetaType,
    ) => ({
      payload: {
        response,
        initialPayload,
      },
      meta,
    }),
  );

const makeFailureCreateAction = <ErrorType, FailureResponseType, InitialPayloadType, MetaType>(
  actionRequestTypes: RequestTypes,
) =>
  createAction(
    actionRequestTypes.FAILURE,
    (
      error: ErrorType,
      response: FailureResponseType,
      initialPayload: InitialPayloadType,
      meta: MetaType,
    ) => ({
      payload: {
        error,
        response,
        initialPayload,
      },
      meta,
    }),
  );

const makeAbortCreateAction = <InitialPayloadType, MetaType>(actionRequestTypes: RequestTypes) =>
  createAction(actionRequestTypes.ABORT, (initialPayload: InitialPayloadType, meta: MetaType) => ({
    payload: {
      initialPayload,
    },
    meta,
  }));

export const createActionCreatorsMap = <
  InitialPayloadType = unknown,
  SuccessResponseType = unknown,
  MetaType = IMeta | undefined,
  ErrorType = unknown,
  FailureResponseType = unknown,
>(
  actionRequestTypes: RequestTypes,
  includeAbort = false,
) => {
  return {
    request: makeRequestCreateAction<InitialPayloadType>(actionRequestTypes),
    success: makeSuccessCreateAction<SuccessResponseType, InitialPayloadType, MetaType>(
      actionRequestTypes,
    ),
    failure: makeFailureCreateAction<ErrorType, FailureResponseType, InitialPayloadType, MetaType>(
      actionRequestTypes,
    ),
    ...(includeAbort && {
      abort: makeAbortCreateAction<InitialPayloadType, MetaType>(actionRequestTypes),
    }),
  };
};
