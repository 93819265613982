import React from 'react';
import { LoginForm } from './LoginForm';
import { LandingContainerStyledWrapper } from './common/generic';
import logo from '../../images/mosaic-logo.png';
import { useRedirectIfLoggedIn } from '../../auth/hooks/useRedirectIfLoggedIn';
import { Footer } from '../App/Footer/Footer';

const LoginContainer = () => {
  useRedirectIfLoggedIn();

  return (
    <LandingContainerStyledWrapper>
      <div className="auth-page">
        <div className="header-container">
          <img src={logo} className="login-logo" alt="Mosaic Logo" />
        </div>
        <LoginForm />
      </div>
      <Footer />
    </LandingContainerStyledWrapper>
  );
};

export default LoginContainer;
