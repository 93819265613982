import { select } from 'redux-saga/effects';
import { changeEntity } from './generics';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from '../auth/redux/selectors';

const { fetchTeams } = entityActions;

export function* fetchUserTeams(action) {
  const token = yield select(getAuthToken);
  yield changeEntity(fetchTeams, api.fetchAllTeams, [token], action);
}
