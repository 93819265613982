import { authReducer as auth } from '../auth/redux/reducer';
import invite from './invite';
import recurly from './recurly';
import teams from './teams';
import { usersReducer as users } from '../users/redux/reducer';
import { statusesReducer as statuses } from './statuses';
import { splitReducer } from '@splitsoftware/splitio-redux';

// WARNING: If adding any new reducers that should be persisted to local storage, refer to `auth` reducer
// for what to know before adding logic to persist the given reducer state to local storage
export const reducerMap = {
  auth,
  invite,
  recurly,
  splitio: splitReducer,
  statuses,
  teams,
  users,
};
