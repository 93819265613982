import React from 'react';

interface RightModalImageProps{
  src: string,
  top: number,
  left: number,
  alt: string
}

const RightModalImage: React.FC<RightModalImageProps> = ({
  src,
  top,
  left,
  alt
}) => {
  return <img 
    style={{
      position: `absolute`,
      top: `${top}px`,
      left: `${left}px`
    }} 
    src={src} 
    alt={alt} 
  />
}

export default RightModalImage;