import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../shared/redux/hooks';
import { getCachedSourceUrl } from '../redux/selectors';

export const useNavigation = () => {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const cachedSourceUrl = useAppSelector(getCachedSourceUrl);

  // we add a fallback to REACT_APP_MOSAIC_APP_DOMAIN as a business requirement so that even if users visit this
  // application directly without visiting this application through the client, we still have some `sourceUrl`
  // to redirect to (even though search params don't contain the value and even though the user never visited
  // this application through a client before [i.e. there is no `cachedSourceUrl`])
  const sourceUrl = useMemo(() => {
    return (
      cachedSourceUrl || searchParams.get('sourceUrl') || process.env.REACT_APP_MOSAIC_APP_DOMAIN
    );
  }, [cachedSourceUrl, searchParams]);

  const navigate = useCallback(({ url, queryParams }: { url: string; queryParams?: unknown }) => {
    const stringifiedQueryParams = qs.stringify(queryParams);
    const navigationUrl = `${url}/?${stringifiedQueryParams}`;
    window.location.href = navigationUrl;
  }, []);

  return {
    searchParams,
    sourceUrl,
    navigate,
  };
};
