import styled from 'styled-components';
import { passwordUtils } from '../../appUtils/passwordUtils';
import { appTheme } from '../../shared/styles';
import { Space } from './Space';
import { CompletedFilledIcon } from '../../images/CompletedFilledIcon';
import FilledXIcon from '../../assets/imgs/FilledX.svg';

interface RequirementRowComponentProps {
  label: string;
  secondaryLabel?: string;
  isMet: boolean;
  isShowNeutralRequirementsState: boolean;
}

const RequirementRowComponent = ({
  isMet,
  label,
  secondaryLabel,
  isShowNeutralRequirementsState,
}: RequirementRowComponentProps) => {
  const Icon =
    isShowNeutralRequirementsState || isMet ? (
      <CompletedFilledIcon
        fillColor={isShowNeutralRequirementsState ? appTheme.colors.colorCalendarGray : undefined}
      />
    ) : (
      <img src={FilledXIcon} />
    );
  return (
    <>
      <RequirementRow>
        {Icon}
        <Space value={6} />
        <RequirementPassText>{label}</RequirementPassText>
      </RequirementRow>
      {secondaryLabel ? <SecondaryLabelContainer>{secondaryLabel}</SecondaryLabelContainer> : null}
    </>
  );
};

interface PasswordPolicyTooltipProps {
  password: string;
  confirmPassword?: string;
  isShowNeutralRequirementsState: boolean;
}

export const PasswordPolicyTooltip = ({
  password,
  confirmPassword,
  isShowNeutralRequirementsState,
}: PasswordPolicyTooltipProps) => {
  const arePasswordsMatching = passwordUtils.getArePasswordsMatching({
    password,
    confirmPassword: confirmPassword ?? '',
  });

  const isShowPasswordNotMatchText =
    password.length > 0 && !!confirmPassword?.length && !arePasswordsMatching;

  const _isShowNeutralRequirementsState = isShowNeutralRequirementsState && !password.length;

  const { isPasswordLengthValid, hasDigits, hasLowercase, hasUppercase, hasSpecialCharacters } =
    passwordUtils.getPasswordValidationResult(password);

  const requirementRows: RequirementRowComponentProps[] = [
    {
      label: 'At least 14 characters',
      isMet: isPasswordLengthValid,
      isShowNeutralRequirementsState: _isShowNeutralRequirementsState,
    },
    {
      label: 'At least one number',
      isMet: hasDigits,
      isShowNeutralRequirementsState: _isShowNeutralRequirementsState,
    },
    {
      label: 'At least one lower case letter',
      isMet: hasLowercase,
      isShowNeutralRequirementsState: _isShowNeutralRequirementsState,
    },
    {
      label: 'At least one upper case letter',
      isMet: hasUppercase,
      isShowNeutralRequirementsState: _isShowNeutralRequirementsState,
    },
    {
      label: 'At least one special character',
      secondaryLabel: '(^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =)',
      isMet: hasSpecialCharacters,
      isShowNeutralRequirementsState: _isShowNeutralRequirementsState,
    },
  ];

  return (
    <RootContainer>
      <Header>Password Requirements</Header>
      <Space vertical value={12} />
      {isShowPasswordNotMatchText ? (
        <>
          <PasswordNotMatchText>Passwords Do Not Match</PasswordNotMatchText>
          <Space vertical value={12} />
        </>
      ) : null}
      {requirementRows.map((row) => (
        <RequirementRowComponent key={row.label} {...row} />
      ))}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  padding: 16px 20px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${appTheme.colors.colorMediumGray9};
`;

const PasswordNotMatchText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${appTheme.colors.colorCalendarRed};
`;

const RequirementRow = styled.div`
  display: flex;
  align-items: center;
`;

const RequirementPassText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${appTheme.colors.colorMediumGray9};
`;

const SecondaryLabelContainer = styled.div`
  padding-left: 20px;
  font-size: 13px;
  font-weight: 400;
  color: ${appTheme.colors.colorMediumGray9};
`;
