import { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownItem } from 'reactstrap';
import noop from 'lodash/noop';
import { useNavigation } from '../../../auth/hooks/useNavigation';
import { getAuthMethodPerEnv } from '../../../auth/redux/selectors';
import { EnvTypes, envTypesFullConfig } from '../../../core/env/utils';
import { useAppDispatch, useAppSelector } from '../../../shared/redux/hooks';
import { appTheme } from '../../../shared/styles';
import { checkAuthMethod, resetPendingLoginSessionState } from '../../../auth/redux/actionCreators';
import { batch } from 'react-redux';

const envTypeToName: Record<EnvTypes, string> = {
  demo: 'Demo',
  gov: 'Gov',
  party: 'Party',
  pilot: 'Pilot',
  prod: 'Prod',
  staging: 'Staging',
};

interface EnvSelectionFormProps {
  email: string;
}

export const EnvSelectionForm = ({ email }: EnvSelectionFormProps) => {
  const authMethodPerEnv = useAppSelector(getAuthMethodPerEnv);

  return (
    <div className="input-form">
      <ContentContainer>
        <HeaderText>Choose an account</HeaderText>
        <Dropdown toggle={noop}>
          <EnvCardsContainer>
            {(Object.keys(authMethodPerEnv) as Array<keyof typeof authMethodPerEnv>).map(
              (envType, index) => {
                return (
                  <Fragment key={envType}>
                    <EnvCard email={email} envType={envType} />
                    {index !== Object.keys(authMethodPerEnv).length - 1 && <DropdownItem divider />}
                  </Fragment>
                );
              },
            )}
          </EnvCardsContainer>
        </Dropdown>
      </ContentContainer>
    </div>
  );
};

interface EnvCardProps {
  email: string;
  envType: EnvTypes;
}

const EnvCard = ({ email, envType }: EnvCardProps) => {
  const { navigate } = useNavigation();
  const dispatch = useAppDispatch();
  const authMethodPerEnv = useAppSelector(getAuthMethodPerEnv);

  const navigateToAppropriateEnv = useCallback(
    (env: EnvTypes) => () => {
      // don't need to redirect if already on the correct env
      if (env === process.env.REACT_APP_GATEWAY_HOST) {
        const authMethodForEnv = authMethodPerEnv[env];
        if (authMethodForEnv) {
          batch(() => {
            // important to reset state before checking auth method
            // we need to explicitly check auth method via api because api returns some information that
            // we are not able to determine ourselves (e.g. whether the user is TOS compliant)
            dispatch(resetPendingLoginSessionState());
            dispatch(checkAuthMethod({ email, meta: { requestStatusId: checkAuthMethod.type } }));
          });
          return;
        }
      }

      if (envTypesFullConfig[env].selfUrl) {
        const { selfUrl, webUrl } = envTypesFullConfig[env];

        navigate({
          url: `${selfUrl}/login`,
          // we can't rely on `sourceUrl` from `useNavigation` because it will be referencing the
          // current env and not the env we are navigating to
          queryParams: { sourceUrl: webUrl, env, prefilledEmail: email },
        });
      }
    },
    [authMethodPerEnv, dispatch, email, navigate],
  );

  return (
    <EnvCardRootContainer key={envType} onClick={navigateToAppropriateEnv(envType)}>
      <EnvNameText>{envTypeToName[envType]}</EnvNameText>
      <EnvEmailText>{email}</EnvEmailText>
    </EnvCardRootContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  border: 1px solid ${appTheme.colors.colorLightGray12};
`;

const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: ${appTheme.colors.colorSemiDarkGray1};
`;

const EnvCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 30px;
`;

const EnvCardRootContainer = styled(DropdownItem)``;

const EnvNameText = styled.div`
  font-weight: 600;
`;

const EnvEmailText = styled.div``;
