import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchUser, setToken, validateInvite } from '../../actionCreators';

import {
  getInvite,
  getIsSkipCCForTeam,
  getSelectedTeamBillingSubscriptionIsValid,
} from '../../selectors';

import { BadInvite } from './BadInvite';

import TeamSignup from './TeamSignup';
import AccountSignup from './AccountSignup';
import BillingSignup from './BillingSignup';
import PlanSignup from './PlanSignup';
import BeginSignup from './BeginSignup';
import UsageSignup from './UsageSignup';
import FinishSignup from './FinishSignup';

import TestBlurb1 from '../../assets/placeholders/Group_2553.svg';
import TestBlurb2 from '../../assets/placeholders/Group_2558.svg';
import Testimonials from '../../assets/placeholders/Group_t.svg';
import TestReports from '../../assets/placeholders/Group_1508.svg';
import TestWorkplan from '../../assets/placeholders/Group_1473.svg';
import TestIntegrations from '../../assets/placeholders/Group_integrations.svg';

import styled from 'styled-components';

import RightModalImage from './common/RightModalImage';
import { logoutUser } from '../../auth/redux/actionCreators';
import { getAuth } from '../../auth/redux/selectors';
import { getMe } from '../../users/redux/selectors';

const rightModals = {
  account: <RightModalImage src={TestBlurb1} top={103} left={36} alt="Testimonials Blurbs 1" />,
  team: <RightModalImage src={TestBlurb2} top={98} left={36} alt="Testimonials Blurbs 2" />,
  begin: (
    <RightModalImage
      src={TestReports}
      top={123}
      left={36}
      alt="Multiple Visual Reports - Stacked"
    />
  ),
  plan: <RightModalImage src={Testimonials} top={116} left={16} alt="Testimonials and Ratings" />,
  billing: (
    <div>
      <RightModalImage
        src={TestWorkplan}
        top={171}
        left={28}
        alt="Workplan and Scheduling Visual"
      />
      <div
        style={{
          position: 'absolute',
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '56px',
          top: '487px',
          left: '180px',
        }}
      >
        Control Workload
      </div>
    </div>
  ),
  done: (
    <div>
      <RightModalImage
        src={TestIntegrations}
        top={159}
        left={-8}
        alt="Map of Mosaic Integrations"
      />
      <div
        style={{
          position: 'absolute',
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '56px',
          top: '468px',
          left: '114px',
        }}
      >
        Unify your data with Mosaic
      </div>
    </div>
  ),
};

const InvitationContainerWrapper = styled.div`
  margin: auto;
  color: #13225c;
`;

const InvitationContainer = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [teamName, setTeamName] = useState('');
  // const [logo, setLogo] = useState<undefined | File>();
  const [plan, setPlan] = useState('');
  const [selectedUsageTypes, setSelectedUsageTypes] = useState(new Set<string>());
  const [ready, setReady] = useState(false);

  const recurlyRef = useRef<HTMLFormElement>(null);

  const query = new URLSearchParams(useLocation().search);
  const queryEmail = query.get('email') || undefined;
  const queryPassword = query.get('temp_password') || undefined;
  const inviteToken = query.get('inviteToken');
  const authToken = query.get('authToken');
  const queryHasTeam = query.get('hasTeam') === 'true';
  const errorMessage = query.get('errorMessage') || undefined;

  const auth = useSelector(getAuth);
  const invite = useSelector(getInvite);
  const me = useSelector(getMe);
  const skipCCForTeam = useSelector(getIsSkipCCForTeam);
  const isTeamBillingSubscriptionValid = useSelector(getSelectedTeamBillingSubscriptionIsValid);

  const sendSuccess = () => {
    window.parent.postMessage('Success', '*');
  };

  const onDoneStep = () => {
    if (step < orderedComponents.length) setStep(step + 1);
  };

  const onBackStep = () => {
    if (step > 0) setStep(step - 1);
  };

  useEffect(() => {
    if (inviteToken) {
      dispatch(validateInvite(inviteToken));
    } else if (authToken) {
      dispatch(setToken(authToken));
    } else {
      dispatch(logoutUser());
    }
  }, [dispatch, authToken, inviteToken]);

  useEffect(() => {
    if (auth && auth.token) {
      dispatch(fetchUser(auth.token));
    }
  }, [dispatch, auth, auth.token, auth.isLoadingToken]);

  useEffect(() => {
    if (queryEmail) {
      setEmail(queryEmail);
    } else if (auth && auth.account && auth.account.email) {
      setEmail(auth.account.email);
    }
  }, [dispatch, auth, auth.account, queryEmail]);

  useEffect(() => {
    if (!me || !(inviteToken || authToken)) {
      return;
    }

    const hasPassword = me.hasPassword;
    const hasCompany = me.hasCompany;
    const isBillingDone = skipCCForTeam || isTeamBillingSubscriptionValid;
    // If this is the first time me has loaded, and:

    // the user has password but not company, then skip to company set
    if (hasPassword && hasCompany === false) {
      const teamSignupStep = 1;

      if (step < teamSignupStep) {
        setStep(teamSignupStep);
      }
    }

    // the user has password and company, but billing is not set up, then skip to billing step
    if (hasPassword && hasCompany && !isBillingDone) {
      const beginSignupStep = 2;

      if (step < beginSignupStep) {
        setStep(beginSignupStep);
      }
    }

    // After me has loaded, turn on flow
    setReady(true);
  }, [authToken, inviteToken, isTeamBillingSubscriptionValid, me, skipCCForTeam, step]);

  const handleSkipBilling = useCallback(() => {
    const usageSignupStep = 5;
    setStep(usageSignupStep);
  }, []);

  const orderedComponents = [
    // eslint-disable-next-line react/jsx-key
    <AccountSignup
      name={name}
      password={password}
      phoneNumber={phoneNumber}
      email={email}
      queryEmail={queryEmail}
      queryPassword={queryPassword}
      queryHasTeam={queryHasTeam}
      setName={setName}
      setPassword={setPassword}
      setPhoneNumber={setPhoneNumber}
      setEmail={setEmail}
      onDone={onDoneStep}
    >
      {rightModals.account}
    </AccountSignup>,
    // eslint-disable-next-line react/jsx-key
    <TeamSignup
      teamName={teamName}
      setTeamName={setTeamName}
      onDone={onDoneStep}
      onBackStep={onBackStep}
    >
      {rightModals.team}
    </TeamSignup>,
    // eslint-disable-next-line react/jsx-key, @typescript-eslint/no-empty-function
    <BeginSignup onDone={onDoneStep} onSkipBilling={handleSkipBilling} testOnBegin={() => {}}>
      {rightModals.begin}
    </BeginSignup>,
    // eslint-disable-next-line react/jsx-key
    <PlanSignup
      plan={plan}
      setPlan={setPlan}
      onDone={onDoneStep}
      onSkipBilling={handleSkipBilling}
      onBackStep={onBackStep}
    >
      {rightModals.plan}
    </PlanSignup>,
    // eslint-disable-next-line react/jsx-key
    <BillingSignup
      name={name}
      plan={plan}
      recurlyRef={recurlyRef}
      onDone={onDoneStep}
      onSkipBilling={handleSkipBilling}
      onBackStep={onBackStep}
    >
      {rightModals.begin}
    </BillingSignup>,
    // eslint-disable-next-line react/jsx-key
    <UsageSignup
      selectedUsageTypes={selectedUsageTypes}
      setSelectedUsageTypes={setSelectedUsageTypes}
      onDone={onDoneStep}
    />,
    // eslint-disable-next-line react/jsx-key
    <FinishSignup onDone={sendSuccess}>{rightModals.done}</FinishSignup>,
  ];

  const hasInviteError = invite.error;
  const hasServerError = !!errorMessage;
  const noInviteInfo = !inviteToken && !auth.isAuthenticated;
  const noAccountActivationInfo = !(queryEmail && queryPassword);

  const showInvalidScreen =
    ((noInviteInfo || hasInviteError) && noAccountActivationInfo) || hasServerError;

  return (
    <InvitationContainerWrapper>
      {showInvalidScreen ? (
        <BadInvite error={errorMessage} />
      ) : ready || !noAccountActivationInfo ? (
        orderedComponents[step]
      ) : null}
    </InvitationContainerWrapper>
  );
};

export default InvitationContainer;
