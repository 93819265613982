import { initialState } from '../reducers/recurly';
import { createSelector } from 'reselect';

const getRecurlyState = (state) => state.recurly || initialState;

const getRecurlyBillingObj = createSelector(getRecurlyState, (state) => state.billingInfo);

export const getRecurlyBillingInfo = createSelector(getRecurlyBillingObj, (billingInfo) => ({
  firstName: billingInfo.attributes?.first_name,
  lastName: billingInfo.attributes?.last_name,
  lastFour: billingInfo.attributes?.payment_method?.attributes?.last_four,
}));

export const getRecurlyIsLoaded = createSelector(getRecurlyState, (state) => state.isLoaded);
