import { css } from 'styled-components';

export const TooltipsStyles = css`
  #app-tooltip,
  #password-policy-tooltip {
    opacity: 1;

    &.white-tooltip {
      background-color: white;
      color: black;
      box-shadow: 0px 3px 20px rgba(171, 171, 171, 0.8);

      &::after {
        border-top-color: white;
      }
    }

    &.mw-370 {
      padding: 0;
      width: 370px;
      max-width: 370px;
    }
  }
`;
