import * as actionCreators from '../actionCreators';
import * as entityActions from '../actions';
import { put, select } from 'redux-saga/effects';
import { api } from '../service';
import { getSelectedTeamId } from '../selectors';
import { changeEntity, fetchEntity } from './generics';
import {
  fetchRecurlyBillingInfo,
  updateRecurlyBillingInfo,
  setRecurlyBillingToken,
} from '../actionCreators';
import { getAuthToken } from '../auth/redux/selectors';

const { recurlyBillingUpdate, recurlyBillingFetch } = entityActions;

export function* fetchRecurlyBillingInfoWorker(action: ReturnType<typeof fetchRecurlyBillingInfo>) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(recurlyBillingFetch, api.fetchRecurlyBillingInfo, teamId, [token], action);
}

export function* updateRecurlyBillingInfoWorker(
  action: ReturnType<typeof updateRecurlyBillingInfo>,
) {
  const token = yield select(getAuthToken);
  const { payload, meta } = action;
  const { billingTokenId, teamId } = payload;
  const { error } = yield changeEntity(
    recurlyBillingUpdate,
    api.updateRecurlyBillingInfo,
    [token, teamId, billingTokenId],
    action,
  );

  if (!error) {
    yield put(actionCreators.fetchRecurlyBillingInfo({ teamId }));
    if (meta?.onSuccess) {
      meta.onSuccess();
    }
  } else if (meta?.onFailure) {
    meta.onFailure();
  }
}

export function* setRecurlyBillingTokenWorker(action: ReturnType<typeof setRecurlyBillingToken>) {
  const { payload, meta } = action;
  const { billingTokenId } = payload;
  const teamId = yield select(getSelectedTeamId);

  if (billingTokenId && teamId) {
    yield put(
      actionCreators.updateRecurlyBillingInfo({
        teamId,
        billingTokenId,
        meta: {
          requestStatusId: setRecurlyBillingToken.type,
          onSuccess: meta?.onSuccess,
          onFailure: meta?.onFailure,
        },
      }),
    );
  }
}
