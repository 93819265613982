import { createActionWithMeta } from '../shared/redux/utils/createActionWithMeta';
import * as constants from '../constants';

export const updateRecurlyBillingInfo = createActionWithMeta<
  UpdateRecurlyBillingInfoInitialParams,
  IMeta<() => void, () => void>
>(constants.SET_RECURLY_BILLING_INFO.TRIGGER);

export const fetchRecurlyBillingInfo = createActionWithMeta<FetchRecurlyBillingInfoInitialParams>(
  constants.FETCH_RECURLY_BILLING_INFO.TRIGGER,
);

export const setRecurlyBillingToken = createActionWithMeta<
  SetRecurlyBillingTokenInitialParams,
  IMeta<() => void, () => void>
>(constants.SET_RECURLY_BILLING_TOKEN.TRIGGER);

interface UpdateRecurlyBillingInfoInitialParams {
  teamId: number;
  billingTokenId: string;
}

interface FetchRecurlyBillingInfoInitialParams {
  teamId: number;
}

interface SetRecurlyBillingTokenInitialParams {
  billingTokenId: string;
}
