import { RealmAPIClient } from './RealmAPIClient';

export class AggregatedRealmAPIClients {
  private readonly apiClients: {
    mosaicAPI: RealmAPIClient;
  };

  constructor(apiClients: { mosaicAPI: RealmAPIClient }) {
    this.apiClients = apiClients;
  }

  useRealm(realmId: string) {
    this.apiClients.mosaicAPI.useRealm(realmId);
  }

  resetToDefaultBaseURL() {
    this.apiClients.mosaicAPI.resetToDefaultBaseURL();
  }

  get mosaicAPI() {
    return this.apiClients.mosaicAPI;
  }
}
