import { CountryCode } from 'libphonenumber-js/core';
import { useState, useRef, forwardRef } from 'react';
import PhoneInput, {
  DefaultInputComponentProps,
  getCountryCallingCode,
} from 'react-phone-number-input';
import { Popover, Dropdown, DropdownItem } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ArrowIcon } from '../../assets/icons/Arrow';
import { appTheme } from '../../shared/styles';

interface PhoneNumberInputProps extends DefaultInputComponentProps {
  inputWidth?: number;
  onChange: (value: string) => void;
}

export const PhoneNumberInput = ({
  inputWidth,
  onChange,
  ...inputProps
}: PhoneNumberInputProps) => {
  return (
    <PhoneInputContainer $inputWidth={inputWidth}>
      <PhoneInput
        international={false}
        defaultCountry="US"
        countrySelectComponent={CountrySelectComponent}
        inputComponent={InputComponent}
        onChange={(phoneNumber) => {
          onChange(phoneNumber || '');
        }}
        {...inputProps}
      />
    </PhoneInputContainer>
  );
};

const CountrySelectComponent = ({ value, options, onChange }: CountrySelectComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverTarget = useRef(null);

  if (!value) return <></>;
  const countryCallingCode = getCountryCallingCode(value);
  return (
    <RootContainer className="mario" ref={popoverTarget} onClick={() => setIsOpen(!isOpen)}>
      <CountrySelectRootContainer>
        <CountryCodeHeading>Country Code</CountryCodeHeading>
        <CountryCallingCode>
          +{countryCallingCode}
          <ArrowContainer>
            <ArrowIcon fillColor={appTheme.colors.colorSemiDarkGray1} />
          </ArrowContainer>
        </CountryCallingCode>
      </CountrySelectRootContainer>
      {isOpen && (
        <Popover isOpen target={popoverTarget} closePopover={() => setIsOpen(false)}>
          <Dropdown toggle={() => setIsOpen(!isOpen)} style={{ height: 240, overflowY: 'scroll' }}>
            {options.flatMap((option) => {
              if (!option.value) return [];
              return [
                <StyledDropdownItem key={option.value} onClick={() => onChange(option.value)}>
                  <span>{option.label}</span>
                  <span>+{getCountryCallingCode(option.value)}</span>
                </StyledDropdownItem>,
              ];
            })}
          </Dropdown>
        </Popover>
      )}
    </RootContainer>
  );
};

const InputComponent = forwardRef<HTMLInputElement>((inputProps, ref) => {
  return (
    <InputContainer>
      <StyledInput ref={ref} {...inputProps} />
      <PhoneNumberInputDescriptionText>Phone Number*</PhoneNumberInputDescriptionText>
    </InputContainer>
  );
});

InputComponent.displayName = 'InputComponent';

const RootContainer = styled.div``;

const CountrySelectRootContainer = styled.div`
  cursor: pointer;
  border: 1px solid ${appTheme.colors.colorLightGray12};
  border-radius: 8px;
`;

const CountryCodeHeading = styled.div`
  padding: 10px 10px 0 10px;
  color: ${appTheme.colors.colorBudgetGrey};
  font-size: 11px;
`;

const InputText = css`
  font-size: 15px;
  color: ${appTheme.colors.colorSemiDarkGray1};
`;

const CountryCallingCode = styled.div`
  position: relative;
  ${InputText};
  padding-bottom: 10px;
  text-align: center;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 77%;
`;

const StyledDropdownItem = styled(DropdownItem)`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  position: relative;
  margin-left: 10px;
`;

const PhoneInputContainer = styled.div<{ $inputWidth?: number }>`
  .PhoneInput {
    display: flex;
  }

  .PhoneInput--focus {
    .PhoneInputInput {
      outline-color: ${(props) => props.theme.colors.colorRoyalBlue};
    }
  }

  .PhoneInputInput {
    width: ${(props) => props.$inputWidth}px;
  }
`;

const StyledInput = styled.input`
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${appTheme.colors.colorLightGray12};
  ${InputText};
  padding-top: 15px;
  padding-left: 10px;
`;

const PhoneNumberInputDescriptionText = styled.span`
  position: absolute;
  left: 6%;
  top: 18%;
  font-size: 11px;
  color: ${appTheme.colors.colorBudgetGrey};
  font-size: 11px;
  user-select: none;
`;

interface CountrySelectComponentProps {
  name: string | undefined;
  value: CountryCode;
  onChange: (value: CountryCode) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  options: { label: string; value: CountryCode }[];
  iconComponent: (props: IconComponentProps) => JSX.Element;
  tabIndex: number | string | undefined;
  disabled: boolean | undefined;
  readOnly: boolean | undefined;
  className: string;
}

interface IconComponentProps {
  label: string;
  country: string | undefined;
}
