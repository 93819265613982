import qs from 'qs';
import cn from 'classnames';
import { useState, useEffect, useCallback, KeyboardEventHandler, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/redux/hooks';
import { OutlinedButton } from '../../shared/OutlinedButton';
import {
  checkAuthMethod,
  checkAuthMethodPerEnv,
  initiateSSOLogin,
  loginUser,
  resetPendingLoginSessionState,
  setCachedSourceUrl,
} from '../../../auth/redux/actionCreators';
import { useRequestStatus } from '../../../shared/hooks/useRequestStatus';
import { usePasswordShowHideIcon } from '../../shared/usePasswordShowHideIcon';
import styled from 'styled-components';
import { LoginDomainFailure } from '../../../auth/types';
import { authConstants } from '../../../auth/constants';
import { urlUtils } from '../../../appUtils/urlUtils';
import { useFeatureFlags } from '../../../shared/hooks/useFeatureFlags';
import { useNavigation } from '../../../auth/hooks/useNavigation';
import { envTypes } from '../../../core/env/utils';
import {
  getAuthMethodPerEnv,
  getAuthMethodType,
  getIsAcceptedTerms,
} from '../../../auth/redux/selectors';
import { batch } from 'react-redux';
import { TermsOfServiceCheckbox } from '../../shared/TermsOfServiceCheckbox';

const { authMethods } = authConstants;
const { removeSubdirectoryFromUrlString } = urlUtils;

interface InitialLoginFormProps {
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
}

export const InitialLoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
}: InitialLoginFormProps) => {
  const [isAcceptedTermsOfService, setIsAcceptedTermsOfService] = useState(true);
  const dispatch = useAppDispatch();
  const [isActedOnPrefilledEmail, setIsActedOnPrefilledEmail] = useState(false);
  const { rotatingLoginApplicationFlag } = useFeatureFlags();

  const { status: loginUserRequestStatus, removeStatus: removeLoginUserRequestStatus } =
    useRequestStatus<LoginDomainFailure>({ requestStatusId: loginUser.type });

  const { status: checkAuthMethodPerEnvRequestStatus } = useRequestStatus<undefined>({
    requestStatusId: checkAuthMethodPerEnv.type,
  });

  const { status: checkAuthMethodRequestStatus, removeStatus: removeCheckAuthMethodRequestStatus } =
    useRequestStatus<string>({ requestStatusId: checkAuthMethod.type });

  const authMethodType = useAppSelector(getAuthMethodType);
  const isAcceptedTermsInitialValue = useAppSelector(getIsAcceptedTerms);
  const authMethodPerEnv = useAppSelector(getAuthMethodPerEnv);
  const { searchParams, sourceUrl: fallbackSourceUrl } = useNavigation();
  const env = searchParams.get('env');
  const prefilledEmail = searchParams.get('prefilledEmail');
  const sourceUrl = searchParams.get('sourceUrl');

  const possibleEnvsToSelect = useMemo(
    () => Object.keys(authMethodPerEnv) as Array<keyof typeof authMethodPerEnv>,
    [authMethodPerEnv],
  );

  const singleValidEnvType = useMemo(
    () =>
      rotatingLoginApplicationFlag &&
      possibleEnvsToSelect.length === 1 &&
      possibleEnvsToSelect[0] === process.env.REACT_APP_GATEWAY_HOST
        ? possibleEnvsToSelect[0]
        : undefined,
    [possibleEnvsToSelect, rotatingLoginApplicationFlag],
  );

  const _checkAuthMethod = useCallback(() => {
    if (
      window.location.href.toLowerCase().includes('projects.mosaicapp.com') &&
      email &&
      email.toLowerCase().includes('@edgnyc.com')
    ) {
      window.location.replace('https://pilot.mosaicapp.com');
      return;
    }

    if (!email) return;

    dispatch(
      checkAuthMethod({
        email,
        meta: { requestStatusId: checkAuthMethod.type },
      }),
    );
  }, [dispatch, email]);

  const _checkAuthMethodPerEnv = useCallback(() => {
    if (!email) return;

    dispatch(
      checkAuthMethodPerEnv({
        email,
        meta: { requestStatusId: checkAuthMethodPerEnv.type },
      }),
    );
  }, [dispatch, email]);

  const _initiateSSOLogin = useCallback(() => {
    if (!email) return;

    const callbackUrl = `${removeSubdirectoryFromUrlString({
      url: window.location.href,
    })}/sso-callback`;

    dispatch(
      initiateSSOLogin({
        email,
        callbackUrl,
        meta: {
          onSuccess: ({ redirect_token }) => {
            const stringifiedQueryParams = qs.stringify({ email, redirect_token });
            const authDomain = process.env.REACT_APP_MOSAIC_AUTH_DOMAIN;
            if (authDomain) {
              window.location.replace(`${authDomain}/auth/sso?${stringifiedQueryParams}`);
            }
          },
        },
      }),
    );
  }, [dispatch, email]);

  useEffect(() => {
    if (authMethodType === authMethods.saml && isAcceptedTermsInitialValue === true) {
      _initiateSSOLogin();
    }
  }, [_initiateSSOLogin, authMethodType, dispatch, email, isAcceptedTermsInitialValue]);

  const handleLoginRequest = useCallback(() => {
    if (!authMethodType) return;

    // make sure form fields are present before proceeding
    if (authMethodType === authMethods.default) {
      if (!email || !password) return;
      dispatch(
        loginUser({
          email,
          password,
          meta: {
            requestStatusId: loginUser.type,
            onSuccess: (response) => {
              // @ts-expect-error application does not know about window.ReactNativeWebView
              const reactNativeWebView = window.ReactNativeWebView;

              if (reactNativeWebView) {
                reactNativeWebView.postMessage(JSON.stringify({ value: response }));
              }
            },
          },
        }),
      );
    } else if (
      authMethodType === authMethods.saml &&
      isAcceptedTermsInitialValue === false &&
      isAcceptedTermsOfService
    ) {
      _initiateSSOLogin();
    }
  }, [
    _initiateSSOLogin,
    authMethodType,
    dispatch,
    email,
    isAcceptedTermsInitialValue,
    isAcceptedTermsOfService,
    password,
  ]);

  const actionButtonHandler = useCallback(() => {
    if (authMethodType) {
      handleLoginRequest();
      return;
    }

    // if we already have the valid env set, we can skip having to check auth method per type and instead just check auth method (for specific env) right away
    if (rotatingLoginApplicationFlag && (!env || (env && !(env in envTypes)))) {
      _checkAuthMethodPerEnv();
      return;
    }

    _checkAuthMethod();
  }, [
    _checkAuthMethod,
    _checkAuthMethodPerEnv,
    authMethodType,
    env,
    handleLoginRequest,
    rotatingLoginApplicationFlag,
  ]);

  useEffect(() => {
    if (prefilledEmail) {
      if (!email) {
        setEmail(prefilledEmail);
        return;
      }

      // we need this extra boolean variable `isActedOnPrefilledEmail` to ensure this `useEffect` doesn't keep running
      // each time `actionButtonHandler` changes (e.g. if email and password changes, thus changing the login request
      // function, and causing a re-running of this useEffect)
      // also note that we ensure that `email` is defined
      if (!isActedOnPrefilledEmail) {
        actionButtonHandler();
        setIsActedOnPrefilledEmail(true);
      }
    }
  }, [actionButtonHandler, email, isActedOnPrefilledEmail, prefilledEmail, setEmail]);

  useEffect(() => {
    // if we already have the current env set, we can skip having to check auth method per type and instead just set the auth method for that env right away
    if (singleValidEnvType && email) {
      batch(() => {
        const authMethodForEnv = authMethodPerEnv[singleValidEnvType];

        if (authMethodForEnv) {
          batch(() => {
            // important to reset state before checking auth method
            // we need to explicitly check auth method via api because api returns some information that
            // we are not able to determine ourselves (e.g. whether the user is TOS compliant)
            dispatch(resetPendingLoginSessionState());
            dispatch(checkAuthMethod({ email, meta: { requestStatusId: checkAuthMethod.type } }));
          });
        }
      });
    }
  }, [authMethodPerEnv, dispatch, email, singleValidEnvType]);

  const { PasswordVisibilityIcon, togglePasswordVisibility, passwordInputType } =
    usePasswordShowHideIcon();

  const clearValidationsIfError = useCallback(() => {
    if (loginUserRequestStatus?.error) {
      removeLoginUserRequestStatus();
    }

    if (checkAuthMethodRequestStatus?.error) {
      removeCheckAuthMethodRequestStatus();
    }
  }, [
    checkAuthMethodRequestStatus?.error,
    loginUserRequestStatus?.error,
    removeCheckAuthMethodRequestStatus,
    removeLoginUserRequestStatus,
  ]);

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      actionButtonHandler();
    }
  };

  useEffect(() => {
    if (loginUserRequestStatus?.error) {
      setPassword('');
    }
  }, [loginUserRequestStatus?.error, setPassword]);

  useEffect(() => {
    if (sourceUrl && sourceUrl !== fallbackSourceUrl) {
      dispatch(setCachedSourceUrl({ value: sourceUrl }));
    }
  }, [dispatch, fallbackSourceUrl, sourceUrl]);

  useEffect(() => {
    if (isAcceptedTermsInitialValue === false) {
      setIsAcceptedTermsOfService(false);
    }
  }, [isAcceptedTermsInitialValue]);

  const getLoginFailureMessage = () => {
    const domainFailureResult = loginUserRequestStatus?.error;
    if (!domainFailureResult) {
      return undefined;
    }

    if (domainFailureResult.type === 'invalidCredentials') {
      return 'That email and password combination is incorrect. Please try again.';
    } else if (domainFailureResult.type === 'serverFriendlyError') {
      return domainFailureResult.message;
    } else if (domainFailureResult.type === 'unknownFailure') {
      return 'An unknown error has occurred!';
    }
  };

  const errorMessage = getLoginFailureMessage() || checkAuthMethodRequestStatus?.error || undefined;

  const isLoading =
    loginUserRequestStatus?.isExecuting ||
    checkAuthMethodRequestStatus?.isExecuting ||
    checkAuthMethodPerEnvRequestStatus?.isExecuting;

  const isSubmitButtonDisabled =
    isLoading || (isAcceptedTermsInitialValue === false && !isAcceptedTermsOfService);

  const isPasswordFieldVisible = authMethodType === authMethods.default;

  const isNotShowAnyUI =
    authMethodType === authMethods.saml && isAcceptedTermsInitialValue !== false;

  if (isNotShowAnyUI) return null;

  return (
    <>
      <div className="input-form">
        <>
          <h3 style={{ fontWeight: 600 }}>Log In</h3>
          <p className="action-link">
            New to Mosaic?
            <a
              style={{ marginLeft: 10 }}
              className="signup-link"
              href="https://get.mosaicapp.com/get-demo"
              target="_blank"
              rel="noreferrer"
            >
              {'Speak to sales'}
            </a>
          </p>
          <div
            className={cn('form-group form-input', {
              'has-danger': errorMessage,
            })}
          >
            <InputContainer>
              <StyledInput
                type="email"
                value={email}
                name="email"
                disabled={isLoading}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=" Email"
                required
                onKeyUp={handleInputKeyDown}
                onFocus={clearValidationsIfError}
                className={cn('user-input', {
                  'form-control-danger': errorMessage,
                })}
              />
            </InputContainer>
          </div>
        </>
        {isPasswordFieldVisible && (
          <>
            <div
              className={cn('form-group form-input', {
                'has-danger': errorMessage,
              })}
            >
              <InputContainer>
                <StyledInput
                  type={passwordInputType}
                  value={password}
                  name="password"
                  disabled={isLoading}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=" Password"
                  required
                  onKeyUp={handleInputKeyDown}
                  onFocus={clearValidationsIfError}
                  className={cn('user-input', {
                    'form-control-danger': errorMessage,
                  })}
                />
                <PasswordVisibilityIconContainer
                  src={PasswordVisibilityIcon}
                  onClick={togglePasswordVisibility}
                />
              </InputContainer>
            </div>
            <div className="login-options">
              <div className="forgot-password">
                <a id="forgot-password-link" href="/forgot">
                  Forgot Password?
                </a>
              </div>
            </div>
          </>
        )}
        {isAcceptedTermsInitialValue === false && (
          <TermsOfServiceCheckbox
            isChecked={isAcceptedTermsOfService}
            onToggle={() => setIsAcceptedTermsOfService(!isAcceptedTermsOfService)}
          />
        )}
        <div>
          <OutlinedButton
            label={isPasswordFieldVisible ? 'Login' : 'Next'}
            isDisabled={isSubmitButtonDisabled}
            isLoading={isLoading}
            onClick={actionButtonHandler}
          />
        </div>
        {errorMessage && <p className="incorrect-input">{errorMessage}</p>}
      </div>
    </>
  );
};

const PasswordVisibilityIconContainer = styled.img`
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  right: 6px;
`;

const InputContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
`;
