import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const initTracing = (): void => {
  if (window.location.href.includes('localhost')) {
    return
  }
  Sentry.init({
    environment: process.env.REACT_APP_UI_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    attachStacktrace: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_LEVEL ? +process.env.REACT_APP_SENTRY_TRACE_LEVEL : 0.1,
  });
}

export default initTracing

