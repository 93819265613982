import { createAction } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';
import { createRequestTypes } from '../../appUtils';
import { createActionCreatorsMap } from '../../shared/redux/utils/createActionCreatorsMap';
import { createActionWithMeta } from '../../shared/redux/utils/createActionWithMeta';
import { Me } from '../../users/models/me';
import {
  ActivateGalaxyAccountInitialParams,
  UpdateAccountInfoInitialParams,
  ForgotPasswordParams,
  LoginUserParams,
  UpdateCognitoPasswordParams,
  UpdatePasswordParams,
  RespondToMFAParams,
  UpdateAccountSMSParams,
  VerifyTOTPParams,
  AssociateTOTPParams,
  AssociateTOTPSuccessResponse,
  AuthenticateSuccessResponse,
  CheckAuthMethodParams,
  CheckAuthMethodSuccessResponse,
  InitiateSSOLoginParams,
  InitiateSSOLoginSuccessResponse,
  ValidateSSOLoginParams,
  SetCachedSourceUrlParams,
  CheckAuthMethodPerEnvSuccessResponse,
} from '../api/types';
import { LoginDomainFailure } from '../types';

/* ----------------------------------- logout user ---------------------------------- */

export const logoutUser = createAction('LOGOUT_USER');

/* ------------------------- activate galaxy account ------------------------ */

const ACTIVATE_GALAXY_ACCOUNT = createRequestTypes('ACTIVATE_GALAXY_ACCOUNT');

export const activateGalaxyAccount = createActionWithMeta<
  ActivateGalaxyAccountInitialParams,
  IMeta<() => void, () => void>
>(ACTIVATE_GALAXY_ACCOUNT.TRIGGER);

export const activateGalaxyAccountActionCreatorsMap =
  createActionCreatorsMap<ActivateGalaxyAccountInitialParams>(ACTIVATE_GALAXY_ACCOUNT);

/* ----------------------------- account update ----------------------------- */

const ACCOUNT_UPDATE = createRequestTypes('ACCOUNT_UPDATE');

export const updateAccountInfo = createActionWithMeta<
  UpdateAccountInfoInitialParams,
  IMeta<() => void, () => void>
>(ACCOUNT_UPDATE.TRIGGER);

export const updateAccountInfoActionCreatorsMap = createActionCreatorsMap<
  UpdateAccountInfoInitialParams,
  Me
>(ACCOUNT_UPDATE);

/* ----------------------------- password forgot ---------------------------- */

const PASSWORD_FORGOT = createRequestTypes('PASSWORD_FORGOT');

export const forgotPassword = createAction<ForgotPasswordParams>(PASSWORD_FORGOT.TRIGGER);

export const forgotPasswordActionCreatorsMap =
  createActionCreatorsMap<ForgotPasswordParams>(PASSWORD_FORGOT);

/* ---------------------------------- login --------------------------------- */

const LOGIN = createRequestTypes('LOGIN');

export const loginUser = createActionWithMeta<LoginUserParams, AuthenticateBaseIMeta>(
  LOGIN.TRIGGER,
);

export const loginUserActionCreatorsMap = createActionCreatorsMap<
  LoginUserParams,
  AuthenticateSuccessResponse,
  ValueOf<Pick<ReturnType<typeof loginUser>, 'meta'>>,
  LoginDomainFailure
>(LOGIN);

/* ------------------------- cognito password update ------------------------ */

const COGNITO_PASSWORD_UPDATE = createRequestTypes('COGNITO_PASSWORD_UPDATE');

export const updateCognitoPassword = createAction<UpdateCognitoPasswordParams>(
  COGNITO_PASSWORD_UPDATE.TRIGGER,
);

export const updateCognitoPasswordActionCreatorsMap =
  createActionCreatorsMap<UpdateCognitoPasswordParams>(COGNITO_PASSWORD_UPDATE);

/* ----------------------------- password update ---------------------------- */

const PASSWORD_UPDATE = createRequestTypes('PASSWORD_UPDATE');

export const updatePassword = createAction<UpdatePasswordParams>(PASSWORD_UPDATE.TRIGGER);

export const updatePasswordActionCreatorsMap =
  createActionCreatorsMap<UpdatePasswordParams>(PASSWORD_UPDATE);

/* ----------------------------- respond to MFA ----------------------------- */

const RESPOND_TO_MFA = createRequestTypes('RESPOND_TO_MFA');

export const respondToMFA = createActionWithMeta<RespondToMFAParams, AuthenticateBaseIMeta>(
  RESPOND_TO_MFA.TRIGGER,
);

export const respondToMFAActionCreatorsMap = createActionCreatorsMap<
  RespondToMFAParams,
  AuthenticateSuccessResponse
>(RESPOND_TO_MFA);

/* --------------------------- update account SMS --------------------------- */

const UPDATE_ACCOUNT_SMS = createRequestTypes('UPDATE_ACCOUNT_SMS');

export const updateAccountSMS = createActionWithMeta<
  UpdateAccountSMSParams,
  IMeta<() => void, () => void>
>(UPDATE_ACCOUNT_SMS.TRIGGER);

export const updateAccountSMSActionCreatorsMap =
  createActionCreatorsMap<UpdateAccountSMSParams>(UPDATE_ACCOUNT_SMS);

/* ------------------------------- verify TOTP ------------------------------ */

const VERIFY_TOTP = createRequestTypes('VERIFY_TOTP');

export const verifyTOTP = createActionWithMeta<VerifyTOTPParams, AuthenticateBaseIMeta>(
  VERIFY_TOTP.TRIGGER,
);

export const verifyTOTPActionCreatorsMap = createActionCreatorsMap<
  VerifyTOTPParams,
  AuthenticateSuccessResponse
>(VERIFY_TOTP);

/* ----------------------------- associate TOTP ----------------------------- */

const ASSOCIATE_TOTP = createRequestTypes('ASSOCIATE_TOTP');

export const associateTOTP = createAction<AssociateTOTPParams>(ASSOCIATE_TOTP.TRIGGER);

export const associateTOTPActionCreatorsMap = createActionCreatorsMap<
  AssociateTOTPParams,
  AssociateTOTPSuccessResponse
>(ASSOCIATE_TOTP);

/* ---------------------------- check auth method --------------------------- */

const CHECK_AUTH_METHOD = createRequestTypes('CHECK_AUTH_METHOD');

export const checkAuthMethod = createActionWithMeta<CheckAuthMethodParams>(
  CHECK_AUTH_METHOD.TRIGGER,
);

export const checkAuthMethodActionCreatorsMap = createActionCreatorsMap<
  CheckAuthMethodParams,
  CheckAuthMethodSuccessResponse
>(CHECK_AUTH_METHOD);

/* -------------------------- check auth method per env ------------------------- */

const CHECK_AUTH_METHOD_PER_ENV = createRequestTypes('CHECK_AUTH_METHOD_PER_ENV');

export const checkAuthMethodPerEnv = createActionWithMeta<CheckAuthMethodParams>(
  CHECK_AUTH_METHOD_PER_ENV.TRIGGER,
);

export const checkAuthMethodPerEnvActionCreatorsMap = createActionCreatorsMap<
  CheckAuthMethodParams,
  CheckAuthMethodPerEnvSuccessResponse
>(CHECK_AUTH_METHOD_PER_ENV);

/* ------------------------- reset pending login session state ------------------------ */

export const resetPendingLoginSessionState = createAction('RESET_PENDING_LOGIN_SESSION_STATE');

/* --------------------------- initiate sso login --------------------------- */

const INITIATE_SSO_LOGIN = createRequestTypes('INITIATE_SSO_LOGIN');

export const initiateSSOLogin = createActionWithMeta<
  InitiateSSOLoginParams,
  IMeta<(response: InitiateSSOLoginSuccessResponse) => void>
>(INITIATE_SSO_LOGIN.TRIGGER);

export const initiateSSOLoginActionCreatorsMap = createActionCreatorsMap<
  InitiateSSOLoginParams,
  InitiateSSOLoginSuccessResponse,
  ValueOf<Pick<ReturnType<typeof initiateSSOLogin>, 'meta'>>
>(INITIATE_SSO_LOGIN);

/* --------------------------- validate sso login --------------------------- */

const VALIDATE_SSO_LOGIN = createRequestTypes('VALIDATE_SSO_LOGIN');

export const validateSSOLogin = createActionWithMeta<ValidateSSOLoginParams, AuthenticateBaseIMeta>(
  VALIDATE_SSO_LOGIN.TRIGGER,
);

export const validateSSOLoginActionCreatorsMap = createActionCreatorsMap<
  ValidateSSOLoginParams,
  AuthenticateSuccessResponse
>(VALIDATE_SSO_LOGIN);

export const setCachedSourceUrl = createAction<SetCachedSourceUrlParams>('SET_CACHED_SOURCE_URL');

export const setMfaSetupVerifyStateToUserPreferredSetup = createAction(
  'SET_MFA_SETUP_VERIFY_STATE_TO_USER_PREFERRED_SETUP',
);

type AuthenticateBaseIMeta = IMeta<(response: AuthenticateSuccessResponse) => void, () => void>;
