import { createBrowserHistory } from 'history';
import { reducerMap } from './reducers';
import rootSaga from './sagas';
export const history = createBrowserHistory();
export function getRootModule() {
  return {
    id: 'root',
    reducerMap: reducerMap,
    sagas: [rootSaga],
    // Actions to fire when this module is added/removed
    initialActions: []
    // finalActions: []
  };
}
