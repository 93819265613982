import keyBy from 'lodash/keyBy';
export const makeErrorString = (actionType:string) => `Error - actionType ${actionType}`;
const makeErrorStrings = (errorStrings:string[]) => errorStrings.map(makeErrorString);
export const IGNORED_ERROR_RESPONSES = {
  "Title can't be blank": true
};

// set up different ignore constraints per env

// pick correct env
export const IGNORED_ERROR_ACTIONS_ARRAY = [];
// build messages to ignore based on env
export const IGNORED_ERROR_ACTIONS_MESSAGES = [
  'Existing connection must be closed before opening', // action-cable-react-jwt uses an older version of action cable, which still throws on this message even when a successful reconnection occurs.
  ...makeErrorStrings(IGNORED_ERROR_ACTIONS_ARRAY)
];
// build actions to ignore in 'captureError' based on env
export const IGNORED_ERROR_ACTIONS = keyBy(IGNORED_ERROR_ACTIONS_ARRAY);
