import * as constants from '../constants';
import { action } from '../appUtils';

export const companyUpdate = {
  request: () => action(constants.TEAM_UPDATE.REQUEST),
  success: (response, _, requestPayload) =>
    action(constants.TEAM_UPDATE.SUCCESS, { payload: { response, requestPayload } }),
  failure: (error, response, requestPayload) =>
    action(constants.TEAM_UPDATE.FAILURE, { payload: { error, requestPayload } }),
};

export const inviteValidation = {
  request: () => action(constants.INVITE_VALIDATION.REQUEST),
  success: (response) => action(constants.INVITE_VALIDATION.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.INVITE_VALIDATION.FAILURE, { payload: { error } }),
};

export const recurlyBillingUpdate = {
  request: () => action(constants.SET_RECURLY_BILLING_INFO.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.SET_RECURLY_BILLING_INFO.SUCCESS, {
      payload: { response, requestPayload },
    }),
  failure: (error, _, requestPayload) =>
    action(constants.SET_RECURLY_BILLING_INFO.FAILURE, { payload: { error, requestPayload } }),
};

export const recurlyBillingFetch = {
  request: () => action(constants.FETCH_RECURLY_BILLING_INFO.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_RECURLY_BILLING_INFO.SUCCESS, {
      payload: { response, requestPayload },
    }),
  failure: (error) => action(constants.FETCH_RECURLY_BILLING_INFO.FAILURE, { payload: { error } }),
};

export const fetchTeams = {
  request: () => action(constants.FETCH_TEAMS.REQUEST),
  success: (response) => action(constants.FETCH_TEAMS.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.FETCH_TEAMS.FAILURE, { payload: { error } }),
};

export const userFetch = {
  request: () => action(constants.FETCH_USER.REQUEST),
  success: (response) => action(constants.FETCH_USER.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.FETCH_USER.FAILURE, { payload: { error } }),
};

export const userActionLog = {
  request: () => action(constants.LOG_USER_ACTION.REQUEST),
  success: (response) => action(constants.LOG_USER_ACTION.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.LOG_USER_ACTION.FAILURE, { payload: { error } }),
};
