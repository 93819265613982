import { useEffect, useState, useCallback } from 'react';
import QRCode from 'qrcode';
import capitalize from 'lodash/capitalize';
import { getMFASetupVerifyState } from '../../../auth/redux/selectors';
import { useAppDispatch, useAppSelector } from '../../../shared/redux/hooks';
import styled from 'styled-components';
import { authConstants } from '../../../auth/constants';
import { Space } from '../../shared/Space';
import { OutlinedButton } from '../../shared/OutlinedButton';
import { useRequestStatus } from '../../../shared/hooks/useRequestStatus';
import { SupportedMfaMethods } from '../../../auth/types';
import {
  updateAccountSMS,
  loginUser,
  verifyTOTP,
  respondToMFA,
  associateTOTP,
  resetPendingLoginSessionState,
  setMfaSetupVerifyStateToUserPreferredSetup,
} from '../../../auth/redux/actionCreators';
import { AuthenticateSuccessResponse } from '../../../auth/api/types';
import { TextButton } from '../../shared/TextButton';
import { appTheme } from '../../../shared/styles';
import { AppVerificationInput } from './AppVerificationInput';
import { ValueOf } from 'type-fest';
import { PhoneNumberInput } from '../../shared/PhoneNumberInput';

const { mfaMethods } = authConstants;

const mfaComponentTypes = {
  userPreferredMfaPicker: 'userPreferredMfaPicker',
  smsSetup: 'smsSetup',
  totpSetup: 'totpSetup',
  verify: 'verify',
} as const;

type MfaComponentTypes = ValueOf<typeof mfaComponentTypes>;

interface MFAFormProps {
  email: string;
  password: string;
}

export const MFAForm = ({ email, password }: MFAFormProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [totpToken, setTotpToken] = useState('');
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string | undefined>();
  const [isPhoneNumberBeingSetupAgain, setIsPhoneNumberBeingSetupAgain] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [userPreferredMfaMethod, setUserPreferredMfaMethod] = useState<
    SupportedMfaMethods | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const mfaSetupVerifyState = useAppSelector(getMFASetupVerifyState);

  const notifyParentWindowOfMfaVerifySuccess = () => {
    parent.postMessage(JSON.stringify({ type: 'mfaVerifySuccess' }), '*');
  };

  const handleAuthenticationSuccess = (response: AuthenticateSuccessResponse) => {
    // @ts-expect-error application does not know about window.ReactNativeWebView
    const reactNativeWebView = window.ReactNativeWebView;

    if (reactNativeWebView) {
      reactNativeWebView.postMessage(JSON.stringify({ value: response }));
    }
  };

  const handleMfaSetupRequest = () => {
    if (
      mfaSetupVerifyState?.type === 'smsSetup' ||
      isPhoneNumberBeingSetupAgain ||
      userPreferredMfaMethod === mfaMethods.sms
    ) {
      dispatch(
        updateAccountSMS({
          email,
          password,
          phoneNumber,
          requestStatusId: updateAccountSMS.type,
          meta: {
            onSuccess: () => {
              if (isPhoneNumberBeingSetupAgain) {
                setIsPhoneNumberBeingSetupAgain(false);
              }

              dispatch(
                loginUser({
                  email,
                  password,
                  meta: {
                    requestStatusId: loginUser.type,
                    onSuccess: handleAuthenticationSuccess,
                  },
                }),
              );
            },
          },
        }),
      );
    } else if (mfaSetupVerifyState?.type === 'totpSetup') {
      dispatch(
        verifyTOTP({
          email,
          password,
          totpToken,
          meta: {
            requestStatusId: verifyTOTP.type,
            onSuccess: (response) => {
              handleAuthenticationSuccess(response);
              notifyParentWindowOfMfaVerifySuccess();
            },
          },
        }),
      );
    }
  };
  const handleMfaVerifyRequest = () => {
    if (mfaSetupVerifyState?.type === 'verify') {
      dispatch(
        respondToMFA({
          email,
          mfaCode,
          challengeName: mfaSetupVerifyState.challengeName,
          sessionToken: mfaSetupVerifyState.sessionToken,
          meta: {
            requestStatusId: respondToMFA.type,
            onSuccess: (response) => {
              handleAuthenticationSuccess(response);
              notifyParentWindowOfMfaVerifySuccess();
            },
          },
        }),
      );
    }
  };

  const {
    status: updateAccountSMSRequestStatus,
    removeStatus: removeUpdateAccountSMSRequestStatus,
  } = useRequestStatus<string>({ requestStatusId: updateAccountSMS.type });

  const { status: verifyTOTPRequestStatus, removeStatus: removeVerifyTOTPRequestStatus } =
    useRequestStatus({ requestStatusId: verifyTOTP.type });

  const { status: respondToMFARequestStatus, removeStatus: removeRespondToMFARequestStatus } =
    useRequestStatus({ requestStatusId: respondToMFA.type });

  const isSetupMFAExecuting =
    updateAccountSMSRequestStatus?.isExecuting || verifyTOTPRequestStatus?.isExecuting || false;

  const isVerifyMFAExecuting = respondToMFARequestStatus?.isExecuting;

  const clearRequestStatuses = useCallback(() => {
    removeUpdateAccountSMSRequestStatus();
    removeVerifyTOTPRequestStatus();
    removeRespondToMFARequestStatus();
  }, [
    removeRespondToMFARequestStatus,
    removeUpdateAccountSMSRequestStatus,
    removeVerifyTOTPRequestStatus,
  ]);

  const isInteractionDisabled = isSetupMFAExecuting || isVerifyMFAExecuting || false;

  useEffect(() => {
    const effect = async () => {
      if (mfaSetupVerifyState?.type === 'totpSetup' && !qrCodeDataUrl) {
        const mfaEntryName =
          process.env.REACT_APP_UI_ENV === 'prod'
            ? 'Mosaic'
            : `Mosaic-${capitalize(process.env.REACT_APP_UI_ENV)}`;

        const qrCodeDataUrl = await QRCode.toDataURL(
          `otpauth://totp/${mfaEntryName}?secret=${mfaSetupVerifyState.secretCode}`,
        );
        setQrCodeDataUrl(qrCodeDataUrl);
      }
    };

    effect();
  }, [mfaSetupVerifyState, qrCodeDataUrl]);

  useEffect(() => {
    if (
      mfaSetupVerifyState?.type === 'userPreferredSetup' &&
      userPreferredMfaMethod === mfaMethods.totp
    ) {
      // we need to get the secret code from BE if the user prefers TOTP so that we can show it to the user
      dispatch(associateTOTP({ email, password }));
    }
  }, [dispatch, email, mfaSetupVerifyState?.type, password, userPreferredMfaMethod]);

  // should never be true
  if (!mfaSetupVerifyState) return null;

  const isSettingUpMFA =
    mfaSetupVerifyState.type === 'smsSetup' ||
    isPhoneNumberBeingSetupAgain ||
    mfaSetupVerifyState.type === 'totpSetup' ||
    mfaSetupVerifyState.type === 'userPreferredSetup';

  const handleGoBack = () => {
    // go back from TOTP/SMS setup screen to user preferred mfa picker screen
    if (userPreferredMfaMethod) {
      setUserPreferredMfaMethod(undefined);
      dispatch(setMfaSetupVerifyStateToUserPreferredSetup());
      return;
    }

    if (isSettingUpMFA) {
      // will take back to login screen
      dispatch(resetPendingLoginSessionState());
    } else if (mfaSetupVerifyState.type === 'verify') {
      // if we are trying to verify SMS but we want to go back, then go back to trying to setup SMS MFA
      if (mfaSetupVerifyState.challengeName === mfaMethods.sms && !isPhoneNumberBeingSetupAgain) {
        setIsPhoneNumberBeingSetupAgain(true);
      } else {
        dispatch(resetPendingLoginSessionState());
      }
    }
  };

  const renderUserPreferredMfaPickerView = () => {
    return (
      <>
        <InstructionsText>Select your preferred MFA method for your account.</InstructionsText>
        <OutlinedButton label="SMS" onClick={() => setUserPreferredMfaMethod(mfaMethods.sms)} />
        <Space value={10} />
        <OutlinedButton
          label="Authentication App"
          onClick={() => setUserPreferredMfaMethod(mfaMethods.totp)}
        />
      </>
    );
  };

  const renderSmsSetupView = () => {
    return (
      <>
        <InstructionsText>
          Input the phone number to setup multi-factor authentication.
        </InstructionsText>
        <PhoneInputContainer>
          <PhoneNumberInput onChange={setPhoneNumber} />
        </PhoneInputContainer>
        <OutlinedButton
          label="Setup"
          isDisabled={isInteractionDisabled}
          isLoading={isInteractionDisabled}
          onClick={handleMfaSetupRequest}
        />
        {updateAccountSMSRequestStatus?.error ? (
          <div style={{ color: 'red' }}>{updateAccountSMSRequestStatus.error}</div>
        ) : null}
      </>
    );
  };

  const renderTotpSetupView = () => {
    if (!qrCodeDataUrl || mfaSetupVerifyState?.type !== 'totpSetup') return <></>;

    return (
      <>
        <InstructionsText>
          Scan the QR code or type the secret key into your authenticator app and then input the 6
          digit verification code generated from the app.
        </InstructionsText>
        <AppLinksSection>
          <AppLinksText>
            Examples of apps you can use are Google Authenticator (
            <ThirdPartyAppLink
              className="help-link-text"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              Android
            </ThirdPartyAppLink>{' '}
            /{' '}
            <ThirdPartyAppLink
              className="help-link-text"
              target="_blank"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            >
              iOS
            </ThirdPartyAppLink>
            ) or Microsoft Authenticator (
            <ThirdPartyAppLink
              className="help-link-text"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
            >
              Android
            </ThirdPartyAppLink>{' '}
            /{' '}
            <ThirdPartyAppLink
              className="help-link-text"
              target="_blank"
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
            >
              iOS
            </ThirdPartyAppLink>
            )
          </AppLinksText>
        </AppLinksSection>
        <ScanningSection>
          <FlexContainer>
            <Label>QR Code:</Label>
            <SetupContentValue>
              <QRCodeImage src={qrCodeDataUrl} />
            </SetupContentValue>
          </FlexContainer>
          <FlexContainer>
            <Label>Secret Key:</Label>
            <SetupContentValue style={{ textAlign: 'start' }}>
              {mfaSetupVerifyState.secretCode}
            </SetupContentValue>
          </FlexContainer>

          <div style={{ display: 'flex', marginTop: 20 }}>
            <Label>Enter Code From App:</Label>
            <SetupContentValue>
              <AppVerificationInput
                value={totpToken}
                onFocus={clearRequestStatuses}
                onChange={setTotpToken}
              />
            </SetupContentValue>
          </div>
        </ScanningSection>
        <OutlinedButton
          label="Setup"
          isDisabled={isInteractionDisabled}
          isLoading={isInteractionDisabled}
          onClick={handleMfaSetupRequest}
        />
        {verifyTOTPRequestStatus?.error ? (
          <p className="incorrect-input">Provided token is invalid, please enter a valid token.</p>
        ) : null}
      </>
    );
  };
  const renderVerifyView = () => {
    if (mfaSetupVerifyState?.type !== 'verify') return <></>;

    return (
      <>
        <InstructionsText>
          Input the verification code{' '}
          {mfaSetupVerifyState.challengeName === 'SMS_MFA'
            ? 'sent to your phone.'
            : 'generated from the authenticator app.'}
        </InstructionsText>

        <AppVerificationInput
          value={mfaCode}
          onChange={setMfaCode}
          onFocus={clearRequestStatuses}
        />

        <OutlinedButton
          label="Verify"
          isDisabled={isInteractionDisabled}
          isLoading={isInteractionDisabled}
          onClick={handleMfaVerifyRequest}
        />

        {respondToMFARequestStatus?.error && (
          <p className="incorrect-input">
            Provided 6 digit verification code is invalid, please try again.
          </p>
        )}
      </>
    );
  };

  const componentsMap: Record<MfaComponentTypes, () => JSX.Element> = {
    [mfaComponentTypes.userPreferredMfaPicker]: renderUserPreferredMfaPickerView,
    [mfaComponentTypes.smsSetup]: renderSmsSetupView,
    [mfaComponentTypes.totpSetup]: renderTotpSetupView,
    [mfaComponentTypes.verify]: renderVerifyView,
  };

  const getMfaComponentKey = (): Nullable<MfaComponentTypes> => {
    if (mfaSetupVerifyState?.type === 'userPreferredSetup' && !userPreferredMfaMethod) {
      return mfaComponentTypes.userPreferredMfaPicker;
    }

    if (
      mfaSetupVerifyState?.type === 'smsSetup' ||
      isPhoneNumberBeingSetupAgain ||
      userPreferredMfaMethod === mfaMethods.sms
    ) {
      return mfaComponentTypes.smsSetup;
    }

    if (mfaSetupVerifyState?.type === 'totpSetup' || userPreferredMfaMethod === mfaMethods.totp) {
      return mfaComponentTypes.totpSetup;
    }

    if (mfaSetupVerifyState?.type === 'verify') {
      return mfaComponentTypes.verify;
    }

    return null;
  };

  const mfaComponentKey = getMfaComponentKey();
  const getMfaComponent = mfaComponentKey ? componentsMap[mfaComponentKey] : () => null;

  return (
    <form
      className="input-form"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <HeaderText>{isSettingUpMFA ? 'MFA Setup is Required' : 'Verify MFA'}</HeaderText>
      {getMfaComponent()}

      <div>
        <TextButton onClick={handleGoBack}>Go Back</TextButton>
      </div>
    </form>
  );
};

const HeaderText = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${appTheme.colors.colorSemiDarkGray1};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  width: 50%;
  font-size: 15px;
  color: ${appTheme.colors.colorSemiDarkGray1};
`;

const AppLinksSection = styled.div``;

const SetupContentValue = styled.div`
  width: 50%;
  display: flex;
  overflow-wrap: anywhere;
  font-size: 15px;
`;

const ThirdPartyAppLink = styled.a`
  font-size: 15px;
`;

const AppLinksText = styled.div`
  font-size: 15px;
  padding: 0 60px;
  color: ${appTheme.colors.colorBudgetGrey};
`;

const ScanningSection = styled.div``;

const QRCodeImage = styled.img``;

const InstructionsText = styled.p`
  font-size: 15px;
  color: ${appTheme.colors.colorSemiDarkGray1};
`;

const PhoneInputContainer = styled.div`
  .PhoneInput {
    justify-content: center;
  }
`;
