import { useEffect } from 'react';
import { validateSSOLogin } from '../../auth/redux/actionCreators';
import { useAppDispatch } from '../../shared/redux/hooks';
import logo from '../../images/mosaic-logo.png';
import { LandingContainerStyledWrapper } from './common/generic';
import { LoadingWheel } from '../../images/LoadingWheel';
import styled from 'styled-components';
import { useRedirectIfLoggedIn } from '../../auth/hooks/useRedirectIfLoggedIn';
import { useHistory } from 'react-router-dom';
import { Space } from '../shared/Space';

export const SsoCallbackContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const emailFromQuery = searchParams.get('email');
  const verificationTokenFromQuery = searchParams.get('verification_token');
  const hasError = searchParams.has('error');

  useRedirectIfLoggedIn();

  useEffect(() => {
    if (emailFromQuery && verificationTokenFromQuery) {
      dispatch(
        validateSSOLogin({
          email: emailFromQuery,
          verificationToken: verificationTokenFromQuery,
        }),
      );
    }
  }, [dispatch, emailFromQuery, verificationTokenFromQuery]);

  return (
    <LandingContainerStyledWrapper>
      <div className="auth-page">
        <div className="header-container">
          <img src={logo} className="login-logo" alt="Mosaic Logo" />
        </div>
        <RootContainer>
          {!hasError ? (
            <LoadingWheel />
          ) : (
            <ErrorContentContainer>
              <ErrorMessage>An unknown error occurred.</ErrorMessage>
              <Space vertical value={8} />
              <GoToLoginScreenText onClick={() => history.replace('/login')}>
                Go back to login screen
              </GoToLoginScreenText>
            </ErrorContentContainer>
          )}
        </RootContainer>
      </div>
    </LandingContainerStyledWrapper>
  );
};

const RootContainer = styled.div`
  margin-top: -30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorCalendarRed};
`;

const GoToLoginScreenText = styled.span`
  cursor: pointer;
  margin-left: 4px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorHelpBlue};
`;
