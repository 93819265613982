import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

import ChevronIcon from '../../../assets/imgs/Chevron.svg';

export const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  width: 962px;
  height: 592px;
`;

export const FormWrapper = styled.div`
  position: relative;
  display: block;
  padding: 36px;
  width: 459px;

  &.fullWidth {
    width: 100%;
  }
`;

export const WidgetWrapper = styled.div`
  position: relative;
  padding: 36px;
  background: #e9f0fa;
  width: 592px;

  overflow: hidden;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 36px;
  top: 44px;
  img {
    height: 27.3px;
  }
`;

export const HeaderWrapper = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  padding-top: 15px;
  padding-bottom: 14px;
`;
export const BlueButton = styled(Button)`
  cursor: pointer;
  width: 315px;
  height: 64px;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  border-width: 0px;
  background: linear-gradient(180deg, #296bec 0%, #2952ec 100%);
  color: white;

  :disabled {
    cursor: not-allowed;
    background: #b3b3b3;
  }
`;

export const GreenButton = styled(Button)`
  width: 340px;
  height: 64px;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;

  border-radius: 4px;
  border-width: 0px;

  color: white;
  background: linear-gradient(180deg, #7ed321 0%, #5caf00 100%);

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
`;

export const BackIcon = () => (
  <img style={{ marginRight: '15px' }} src={ChevronIcon} alt="Return to Previous" />
);

export const AccountInput = styled(Input)`
  width: 315px;
  height: 60px;

  padding-left: 20px;

  border-radius: 8px;
  // override base component styling
  border: none;
  font-size: 16px;
  outline: 1px solid ${(props) => props.theme.colors.colorLightGray12};

  &:focus {
    // override base component styling
    border-color: unset;
    // override base component styling
    box-shadow: unset;
    outline: 2px solid ${(props) => props.theme.colors.colorRoyalBlue};
  }
`;

export const InvalidText = styled.span`
  color: #ee263f;

  font-size: 14px;
  line-height: 14px;

  margin-bottom: 8px;
`;
