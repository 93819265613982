import * as constants from '../../constants';
import { AnyAction } from 'redux';
import { AuthMethods, SupportedMfaMethods } from '../types';
import {
  associateTOTPActionCreatorsMap,
  checkAuthMethodActionCreatorsMap,
  loginUserActionCreatorsMap,
  logoutUser,
  respondToMFAActionCreatorsMap,
  updateAccountSMSActionCreatorsMap,
  validateSSOLoginActionCreatorsMap,
  verifyTOTPActionCreatorsMap,
  setCachedSourceUrl,
  resetPendingLoginSessionState,
  setMfaSetupVerifyStateToUserPreferredSetup,
  checkAuthMethodPerEnvActionCreatorsMap,
} from './actionCreators';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { CheckAuthMethodPerEnvSuccessResponse } from '../api/types';

const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000;

// WARNING: If modifying the shape of this state, make sure to create a migration path inside `localStorage.ts`
export interface AuthState {
  token: string | null;
  realmId: string | null;
  account: {
    id: number;
    slug: string;
    email: string;
  } | null;
  isAuthenticated: boolean;
  tempToken: string | null;
  tempTokenExpiration: number | null;
  isLoadingToken: boolean;
  mfaSetupVerifyState:
    | { type: 'smsSetup' }
    | { type: 'totpSetup'; secretCode: string }
    | { type: 'userPreferredSetup' }
    | { type: 'verify'; challengeName: SupportedMfaMethods; sessionToken: string }
    | { type: 'verified' }
    | null;
  authMethodType: AuthMethods | null;
  authMethodPerEnv: CheckAuthMethodPerEnvSuccessResponse;
  cachedSourceUrl: Nullable<string>;
  isAcceptedTerms: Nullable<boolean>;
}

export const initialState: AuthState = {
  token: null,
  realmId: null,
  account: null,
  isAuthenticated: false,
  tempToken: null,
  tempTokenExpiration: null,
  isLoadingToken: false,
  mfaSetupVerifyState: null,
  authMethodType: null,
  authMethodPerEnv: {},
  cachedSourceUrl: null,
  isAcceptedTerms: null,
};

const handleInviteValidationSuccess = (state: Draft<AuthState>, action: AnyAction) => {
  const { auth_token, account } = action.payload.response;
  state.token = auth_token;
  state.isAuthenticated = false;
  state.account = account;
};

const handleAuthenticateSuccess = (
  state: Draft<AuthState>,
  action:
    | ReturnType<typeof loginUserActionCreatorsMap.success>
    | ReturnType<typeof verifyTOTPActionCreatorsMap.success>
    | ReturnType<typeof respondToMFAActionCreatorsMap.success>
    | ReturnType<typeof validateSSOLoginActionCreatorsMap.success>,
) => {
  const { payload } = action;
  const { auth_token, temp_token, account, realm_id } = payload.response;
  state.isAuthenticated = true;
  state.token = auth_token;
  state.tempToken = temp_token;
  state.tempTokenExpiration = new Date().valueOf() + TWO_MINUTES_IN_MILLISECONDS;
  state.account = account;
  state.mfaSetupVerifyState = null;

  if (realm_id) {
    state.realmId = realm_id;
  }
};

const handleLoginUserFailure = (
  state: Draft<AuthState>,
  action: ReturnType<typeof loginUserActionCreatorsMap.failure>,
) => {
  let mfaSetupVerifyState = state.mfaSetupVerifyState;
  const { error: domainFailureResult } = action.payload;

  if (domainFailureResult.type === 'phoneNumberAssociationRequired') {
    mfaSetupVerifyState = { type: 'smsSetup' };
  } else if (domainFailureResult.type === 'totpMFAAssociationRequired') {
    mfaSetupVerifyState = { type: 'totpSetup', secretCode: domainFailureResult.secretCode };
  } else if (domainFailureResult.type === 'userPreferredSetup') {
    mfaSetupVerifyState = { type: 'userPreferredSetup' };
  } else if (domainFailureResult.type === 'mfaVerifyRequired') {
    mfaSetupVerifyState = {
      type: 'verify',
      challengeName: domainFailureResult.challengeName,
      sessionToken: domainFailureResult.sessionToken,
    };
  }

  state.isAuthenticated = false;
  state.token = null;
  state.account = null;
  state.mfaSetupVerifyState = mfaSetupVerifyState;
};

const handleAssociateTOTPSuccess = (
  state: Draft<AuthState>,
  action: ReturnType<typeof associateTOTPActionCreatorsMap.success>,
) => {
  const { response } = action.payload;
  state.mfaSetupVerifyState = { type: 'totpSetup', secretCode: response.secret_code };
};

const handleVerifyMFASuccess = (
  state: Draft<AuthState>,
  action:
    | ReturnType<typeof verifyTOTPActionCreatorsMap.success>
    | ReturnType<typeof respondToMFAActionCreatorsMap.success>,
) => {
  state.mfaSetupVerifyState = { type: 'verified' };
  handleAuthenticateSuccess(state, action);
};

const handleResetPendingLoginSessionState = (state: Draft<AuthState>) => {
  state.mfaSetupVerifyState = null;
  state.authMethodType = null;
  state.authMethodPerEnv = {};
};

const handleSetMfaSetupVerifyStateToUserPreferredSetup = (state: Draft<AuthState>) => {
  state.mfaSetupVerifyState = {
    type: 'userPreferredSetup',
  };
};

const handleUpdateAccountSMSSuccess = (state: Draft<AuthState>) => {
  state.mfaSetupVerifyState = null;
};

const handleFetchUserSuccess = (state: Draft<AuthState>, action: AnyAction) => {
  if (state.isLoadingToken) {
    state.account = action.payload.response.account;
    state.isLoadingToken = false;
  }
};

const handleSetToken = (state: Draft<AuthState>, action: AnyAction) => {
  state.account = null;
  state.isAuthenticated = true;
  state.isLoadingToken = true;
  state.token = action.payload.token;
};

const handleCheckAuthMethodSuccess = (
  state: Draft<AuthState>,
  action: ReturnType<typeof checkAuthMethodActionCreatorsMap.success>,
) => {
  const { auth_method, is_tos_compliant } = action.payload.response;
  state.authMethodType = auth_method;
  state.isAcceptedTerms = is_tos_compliant;
};

const handleCheckAuthMethodPerEnvSuccess = (
  state: Draft<AuthState>,
  action: ReturnType<typeof checkAuthMethodPerEnvActionCreatorsMap.success>,
) => {
  state.authMethodPerEnv = action.payload.response;
};

const handleSetCachedSourceUrl = (
  state: Draft<AuthState>,
  action: ReturnType<typeof setCachedSourceUrl>,
) => {
  state.cachedSourceUrl = action.payload.value;
};

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutUser, () => initialState);
  builder.addCase(constants.INVITE_VALIDATION.FAILURE, () => initialState);
  builder.addCase(constants.INVITE_VALIDATION.SUCCESS, handleInviteValidationSuccess);
  builder.addCase(loginUserActionCreatorsMap.success, handleAuthenticateSuccess);
  builder.addCase(verifyTOTPActionCreatorsMap.success, handleVerifyMFASuccess);
  builder.addCase(respondToMFAActionCreatorsMap.success, handleVerifyMFASuccess);
  builder.addCase(validateSSOLoginActionCreatorsMap.success, handleAuthenticateSuccess);
  builder.addCase(loginUserActionCreatorsMap.failure, handleLoginUserFailure);
  builder.addCase(associateTOTPActionCreatorsMap.success, handleAssociateTOTPSuccess);
  builder.addCase(resetPendingLoginSessionState, handleResetPendingLoginSessionState);
  builder.addCase(
    setMfaSetupVerifyStateToUserPreferredSetup,
    handleSetMfaSetupVerifyStateToUserPreferredSetup,
  );
  builder.addCase(updateAccountSMSActionCreatorsMap.success, handleUpdateAccountSMSSuccess);
  builder.addCase(constants.SET_TOKEN, handleSetToken);
  builder.addCase(constants.FETCH_USER.SUCCESS, handleFetchUserSuccess);
  builder.addCase(checkAuthMethodActionCreatorsMap.success, handleCheckAuthMethodSuccess);
  builder.addCase(
    checkAuthMethodPerEnvActionCreatorsMap.success,
    handleCheckAuthMethodPerEnvSuccess,
  );
  builder.addCase(setCachedSourceUrl, handleSetCachedSourceUrl);
});
