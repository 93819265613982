import { useEffect, useRef, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  selector: string;
}

// taken from https://github.com/vercel/next.js/blob/2874a3e0f1130853da989dd18a90f6726ff33256/examples/with-portals/components/ClientOnlyPortal.js
export const Portal = ({ selector, children }: PortalProps) => {
  const ref = useRef<Element | null>();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setIsMounted(true);
  }, [selector]);

  return isMounted && !!ref.current ? createPortal(children, ref.current) : null;
};
