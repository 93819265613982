import raw from 'raw.macro';
import { ValueOf } from 'type-fest';

export const envTypes = {
  demo: 'demo',
  gov: 'gov',
  party: 'party',
  pilot: 'pilot',
  prod: 'prod',
  staging: 'staging',
} as const;

export type EnvTypes = ValueOf<typeof envTypes>;

const envTypesBaseConfig: Record<EnvTypes, { getEnvFileString: () => string }> = {
  demo: {
    getEnvFileString: () => raw('../../../.env.demo'),
  },
  gov: {
    getEnvFileString: () => raw('../../../.env.gov'),
  },
  party: {
    getEnvFileString: () => raw('../../../.env.party'),
  },
  pilot: {
    getEnvFileString: () => raw('../../../.env.pilot'),
  },
  prod: {
    getEnvFileString: () => raw('../../../.env.prod'),
  },
  staging: {
    getEnvFileString: () => raw('../../../.env.staging'),
  },
};

type EnvConfig = Record<
  EnvTypes,
  {
    selfUrl: string;
    apiUrl: string;
    webUrl: string;
  }
>;

export const envTypesFullConfig = Object.entries(envTypesBaseConfig).reduce<EnvConfig>(
  (envFullConfig, [currentEnvType, { getEnvFileString }]) => {
    const extractValueFromEnvFile = (key: string) =>
      getEnvFileString()
        .split('\n')
        .find((line) => line.startsWith(key))
        ?.split('=')[1] || '';

    const selfUrl = extractValueFromEnvFile('REACT_APP_SELF_DOMAIN');
    const apiUrl = extractValueFromEnvFile('REACT_APP_MOSAIC_API_URL');
    const webUrl = extractValueFromEnvFile('REACT_APP_MOSAIC_APP_DOMAIN');

    envFullConfig[currentEnvType] = { selfUrl, apiUrl, webUrl };
    return envFullConfig;
  },
  {} as EnvConfig,
);
