import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { validateInvite } from '../../actionCreators';
import { ResetPasswordForm } from './ResetPasswordForm';
import logo from '../../images/mosaic-logo.png';
import { getAuth } from '../../auth/redux/selectors';
import { LandingContainerStyledWrapper } from './common/generic';

class ResetPasswordContainer extends React.Component {
  componentDidMount() {
    const inviteToken = this.props.inviteToken;
    if (inviteToken) {
      this.props.validateInvite(inviteToken);
    }
  }

  redirectIfLoggedIn = (props) => {
    const { auth, redirectRoute } = props;
    if (auth.isAuthenticated) {
      window.location.href = redirectRoute;
    }
  };

  setPassword = (password, firstName, lastName) => {
    if (this.props.isCognito) {
      this.props.updateCognitoPassword({
        password,
        email: this.props.email,
        code: this.props.confirmationCode,
      });
    } else {
      this.props.updatePassword(this.props.auth.token, password, firstName, lastName);
    }
  };

  render() {
    return (
      <LandingContainerStyledWrapper>
        <div className="auth-page">
          <div className="header-container">
            <img src={logo} className="login-logo" onClick={this.loginClick} alt="Mosaic App" />
          </div>

          <ResetPasswordForm
            email={this.props.email}
            confirmationCode={this.props.confirmationCode}
            isCognito={this.props.isCognito}
          />
        </div>
      </LandingContainerStyledWrapper>
    );
  }
}

const redirectRoute = '/';
const mapStateToProps = (state) => ({
  redirectRoute,
  auth: getAuth(state),
  invite: state.invite,
});

const mapDispatchToProps = { validateInvite };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer));
