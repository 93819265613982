import { AnyAction } from '@reduxjs/toolkit';
import {
  logoutUser,
  updateCognitoPasswordActionCreatorsMap,
  updatePasswordActionCreatorsMap,
} from '../auth/redux/actionCreators';
import * as constants from '../constants';

export interface InviteState {
  error: boolean | null;
  passwordIsSet: true | null;
}

export const initialState: InviteState = {
  error: null,
  passwordIsSet: null,
};

const invite = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case logoutUser.type: {
      return initialState;
    }
    case constants.INVITE_VALIDATION.FAILURE:
      return {
        ...state,
        error: true,
      };
    case updatePasswordActionCreatorsMap.success.type:
    case updateCognitoPasswordActionCreatorsMap.success.type:
      return {
        ...state,
        passwordIsSet: true,
      };
    default:
      return state;
  }
};
export default invite;
