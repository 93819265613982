import { appTheme } from '../shared/styles';
import { SvgIcon, SvgIconProps } from './SvgIcon';

interface CompletedFilledIconProps extends Pick<SvgIconProps, 'width' | 'height' | 'title'> {
  fillColor?: string;
}

export const CompletedFilledIcon = ({
  fillColor = appTheme.colors.colorConnectedGreen,
  width = 14,
  height = 14,
  title,
}: CompletedFilledIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={title}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM9.73361 4.53726L10.1835 4.08333L11.0833 4.98331L10.6334 5.43724C9.96434 6.10782 9.26045 6.84248 8.5531 7.58076C7.83765 8.32749 7.11867 9.07791 6.42861 9.76944L6.02247 10.1796L5.57253 9.80927L3.40643 8.02542L2.91667 7.61928L3.72498 6.63576L4.21872 7.04191L5.93096 8.4514C6.46916 7.9039 7.02332 7.32795 7.5797 6.74969C8.30403 5.99688 9.03211 5.24016 9.73361 4.53726Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
