import { useState, useMemo } from 'react';
import newClose from '../../images/close-icon.svg';
import styled from 'styled-components';

function ChromeDownloadBanner() {
  const [isHidden, setIsHidden] = useState(false);

  const agent = window.navigator.userAgent;

  const isSafari = useMemo(
    () => agent.indexOf('Safari/') > -1 && agent.indexOf('Chrome/') < 0,
    [agent],
  );

  const isChromeOutOfDate = useMemo(() => {
    const rawChromeVersion = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    const chromeVersion = rawChromeVersion ? parseInt(rawChromeVersion[2], 10) : false;

    // css grid support
    return chromeVersion && chromeVersion < 57;
  }, []);

  const shouldDisplayBanner = useMemo(
    () =>
      agent.indexOf('MSIE ') > -1 ||
      agent.indexOf('Trident/') > -1 ||
      agent.indexOf('Edge/') > -1 ||
      agent.indexOf('Firefox/') > -1 ||
      isSafari ||
      isChromeOutOfDate,
    [agent, isChromeOutOfDate, isSafari],
  );

  if (!shouldDisplayBanner || isHidden) {
    return null;
  }

  const handleClose = () => {
    setIsHidden(true);
  };

  const bannerMessage = isChromeOutOfDate ? (
    <p>
      Mosaic runs best on a newer version of Google Chrome. For help updating Chrome, please
      click&nbsp;
      <a href="https://support.google.com/chrome/answer/95414"> here </a>
      <img src={newClose} className="banner-close" onClick={handleClose} />
    </p>
  ) : (
    <p>
      Mosaic runs best on Google Chrome or Microsoft Edge Browsers. Don&#39;t Have Chrome?
      Install&nbsp;
      <a
        target={'_blank'}
        rel={'noopener noreferrer'}
        href="https://www.google.com/chrome/browser/desktop/index.html?brand=CHBD&gclid=CjwKCAjwmIrJBRAREiwAuUPKMroEstfo0MYpVQlzMjt22tmr8c0UE-0A4w8mErlSovplYRtB2vJr2hoC6BUQAvD_BwE"
      >
        here
      </a>
      <img src={newClose} className="banner-close" onClick={handleClose} />
    </p>
  );

  return <ChromeBannerContainer>{bannerMessage}</ChromeBannerContainer>;
}

export default ChromeDownloadBanner;

const ChromeBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  background-color: #ef7b31;
  padding: 9px;
  p {
    color: white;
    font-size: clamp(12px, 1.5vw, 15px);
    letter-spacing: -0.1px;
    text-align: center;
    margin: 0;
  }
  a {
    text-decoration: underline;
  }
  .banner-close {
    float: right;
    margin-top: 6px;
    margin-right: 15px;
    z-index: 100;
  }
`;
