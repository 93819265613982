import styled from 'styled-components';
import { appTheme } from '../../../shared/styles';

export const BaseButton = styled.button`
  height: 54px;
  color: ${appTheme.colors.colorRoyalBlue};
  font-size: 18px;
  border-radius: 100px;
  background-color: white;
  border: solid 1px transparent;

  outline: 0 !important;
  margin-top: 1rem;

  &:hover {
    color: white;
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
