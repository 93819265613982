import { css } from 'styled-components';

export const IconStyles = css`
  .loading-wheel {
    animation: load3 1.4s infinite linear;
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* see: https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal#remove-the-password-reveal-control */
  ::-ms-reveal {
    display: none !important;
  }
`;
