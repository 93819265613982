import { AggregatedRealmAPIClients } from './AggregatedRealmAPIClients';
import { RealmAPIClient } from './RealmAPIClient';

if (!process.env.REACT_APP_MOSAIC_API_URL) {
  throw Error('REACT_APP_MOSAIC_API_URL is not defined');
}

// initialize instance for Mosiac Backend API
export const mosaicAPIClients = new AggregatedRealmAPIClients({
  mosaicAPI: new RealmAPIClient({
    baseURL: process.env.REACT_APP_MOSAIC_API_URL,
  }),
});
