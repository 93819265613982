import Storage from 'versioned-storage';
import { AuthState, initialState as authReducerInitialState } from '../auth/redux/reducer';
import { RootState } from '../shared/redux/shared';

// TODO: Remove this function in the near future once all users are actively reading local storage from `versioned-storage` storage entity
const _loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const loadState = () => {
  return storage.read();
};

export const saveState = (state: Partial<RootState>) => {
  storage.write(state);
};

let storage: Storage<Partial<RootState>>;

// before incrementing this version number, make sure to add a migration below to that version number
const VERSION_NUMBER = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const migrations: Record<string, (state: Record<string, any>) => Record<string, any>> = {
  3: (state) => {
    return {
      ...state,
      auth: { ...state.auth, isAcceptedTerms: null },
    };
  },
  4: (state) => {
    return {
      ...state,
      auth: {
        ...state.auth,
        account: null,
        token: null,
        tempToken: null,
        tempTokenExpiration: null,
        realmId: null,
      },
    };
  },
  5: (state) => {
    return {
      ...state,
      auth: { ...state.auth, isFetchingTempToken: undefined },
    };
  },
};
try {
  // Note: this must be different than the key used for legacy `localStorage`
  storage = new Storage('state-new');
  // @ts-expect-error incomplete types
  const persistedVersion = storage.version as number;
  // this should only happen if the user has never used versioned storage before
  if (persistedVersion === VERSION_NUMBER) {
    const existingState = _loadState();

    if (existingState) {
      saveState(existingState);
    }

    throw Error('finished migrating user to versioned storage');
  }

  if (VERSION_NUMBER - persistedVersion > 3) throw Error('Too many versions behind');

  // apply migration to each new version iteratively until state is reached to latest version
  let newState = loadState();

  for (let stateVersion = persistedVersion + 1; stateVersion <= VERSION_NUMBER; stateVersion++) {
    if (!migrations[stateVersion]) {
      Storage.reset();
      throw Error(`No migration for version ${stateVersion}`);
    }

    if (newState) {
      newState = migrations[stateVersion](newState);
    }
  }

  if (newState) {
    saveState(newState);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
} finally {
  storage = new Storage('state-new', VERSION_NUMBER);

  if (_loadState()) {
    localStorage.removeItem('state');
  }
}

export const overridePersistantAuthStorage = ({ cachedSourceUrl }: AuthState): AuthState => ({
  ...authReducerInitialState,
  cachedSourceUrl,
});
