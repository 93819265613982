import styled from 'styled-components';
import { BlueButton, FormWrapper, ModalWrapper } from './common/generic';

const BadInviteWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const BadInviteBlurb = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

interface BadInviteProps {
  error?: string;
}

export const BadInvite = ({
  error = 'The link you have entered is either incorrect or expired!',
}: BadInviteProps) => {
  const handleGoToLoginPage = () => {
    window.parent.postMessage(JSON.stringify({ type: 'badInvite' }), '*');
  };
  return (
    <ModalWrapper>
      <FormWrapper className="fullWidth">
        <BadInviteWrapper>
          <BadInviteBlurb>
            {error}
            <BlueButton onClick={handleGoToLoginPage}>Go to login page</BlueButton>
          </BadInviteBlurb>
        </BadInviteWrapper>
      </FormWrapper>
    </ModalWrapper>
  );
};
