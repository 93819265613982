import { useState, useEffect, useRef, FormEvent, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { PasswordPolicyTooltip } from '../shared/PasswordPolicyTooltip';

import { ResetFormContainer } from './common/generic';
import { useAppDispatch, useAppSelector } from '../../shared/redux/hooks';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Space } from '../shared/Space';
import { passwordUtils } from '../../appUtils/passwordUtils';
import { OutlinedButton } from '../shared/OutlinedButton';
import { updateCognitoPassword, updatePassword } from '../../auth/redux/actionCreators';
import { useRequestStatus } from '../../shared/hooks/useRequestStatus';
import { usePasswordShowHideIcon } from '../shared/usePasswordShowHideIcon';
import { getAuthToken } from '../../auth/redux/selectors';
import { TextButton } from '../shared/TextButton';

type ResetPasswordFormProps =
  | {
      email: string;
      isCognito: true;
      confirmationCode: string;
    }
  | {
      email?: never;
      isCognito: false;
      confirmationCode?: never;
    };

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const tooltipRef = useRef<HTMLInputElement>(null);
  const [isInputtingPassword, setIsInputtingPassword] = useState(false);
  const [isShowNeutralRequirementsState, setIsShowNeutralRequirementsState] = useState(true);
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstName, setFirstName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastName, setLastName] = useState('');

  const isPasswordResetSuccessful = useAppSelector((state) => state.invite.passwordIsSet);
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(getAuthToken);
  const inviteError = useAppSelector((state) => state.invite.error);
  const { PasswordVisibilityIcon, passwordInputType, togglePasswordVisibility } =
    usePasswordShowHideIcon();

  useEffect(() => {
    if (isInputtingPassword) {
      // @ts-expect-error incorrect types in react-tooltip package
      ReactTooltip.show(tooltipRef);
    }
  }, [isInputtingPassword]);

  const {
    status: updateCognitoPasswordRequestStatus,
    removeStatus: removeUpdateCognitoPasswordRequestStatus,
  } = useRequestStatus<string>({ requestStatusId: updateCognitoPassword.type });
  const { status: updatePasswordRequestStatus, removeStatus: removeUpdatePasswordRequestStatus } =
    useRequestStatus<string>({ requestStatusId: updatePassword.type });

  const clearValidations = useCallback(() => {
    removeUpdateCognitoPasswordRequestStatus();
    removeUpdatePasswordRequestStatus();
  }, [removeUpdateCognitoPasswordRequestStatus, removeUpdatePasswordRequestStatus]);

  const formSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password.length && passwordUtils.getPasswordValidationResult(password)) {
      if (props.isCognito) {
        dispatch(
          updateCognitoPassword({
            password,
            email: props.email,
            code: props.confirmationCode,
            requestStatusId: updateCognitoPassword.type,
          }),
        );
      } else if (authToken) {
        dispatch(
          updatePassword({ password, firstName, lastName, requestStatusId: updatePassword.type }),
        );
      }
    }
  };

  const errorMessage = useMemo(() => {
    if (updateCognitoPasswordRequestStatus?.error) {
      return updateCognitoPasswordRequestStatus?.error;
    }

    if (updatePasswordRequestStatus?.error) {
      return updatePasswordRequestStatus?.error;
    }

    return inviteError
      ? 'Sorry! The activation link you clicked is either invalid or has expired.'
      : undefined;
  }, [inviteError, updateCognitoPasswordRequestStatus?.error, updatePasswordRequestStatus?.error]);

  const inputProps = {
    'data-for': 'password-policy-tooltip',
    'data-tip': '',
    'data-class': 'white-tooltip full-opacity mw-370',
    'data-place': 'bottom',
    'data-arrow-color': '#fff',
    onFocus: () => {
      setIsInputtingPassword(true);
      clearValidations();
    },
    onBlur: () => {
      if (isShowNeutralRequirementsState) {
        setIsShowNeutralRequirementsState(false);
      }

      setIsInputtingPassword(false);
    },
    ref: tooltipRef,
  };

  const isAnyPasswordFieldFilled = password.length || confirmPassword.length;
  const isPasswordValid = password.length
    ? passwordUtils.getPasswordValidationResult(password).isValid
    : true;

  const isPasswordMatching = isAnyPasswordFieldFilled
    ? passwordUtils.getArePasswordsMatching({ password, confirmPassword })
    : false;

  const isExecuting =
    updateCognitoPasswordRequestStatus?.isExecuting || updatePasswordRequestStatus?.isExecuting;
  const isInteractionDisabled = isExecuting || !isPasswordValid || !isPasswordMatching;

  return (
    <>
      <ResetFormContainer className="input-reset">
        <h3>Reset Password</h3>
        {isPasswordResetSuccessful ? (
          <div>
            <h6 className="password-reset-text">Password reset was successful.</h6>
            <div>
              <TextButton
                onClick={() =>
                  history.push({ pathname: '/login', search: `?prefilledEmail=${props.email}` })
                }
              >
                Go back to login page
              </TextButton>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h6 className="password-reset-text">Enter your new password.</h6>
            </div>
            {errorMessage ? (
              <div className="auth-modal-error">
                <span className="auth-modal-error-message">{errorMessage}</span>
              </div>
            ) : null}
            <Space vertical value={24} />
            <FormContainer>
              <StyledForm onSubmit={formSubmit}>
                <div className={cn('form-group', { 'has-danger': errorMessage })}>
                  <InputContainer>
                    <StyledPasswordInput
                      type={passwordInputType}
                      defaultValue={password}
                      name="password"
                      disabled={isExecuting}
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder="Set Password"
                      required
                      className={cn('user-input', {
                        'form-control-danger':
                          isAnyPasswordFieldFilled && (!isPasswordValid || !isPasswordMatching),
                        'form-control-success':
                          isAnyPasswordFieldFilled && isPasswordValid && isPasswordMatching,
                      })}
                      {...inputProps}
                    />
                    <PasswordVisibilityIconContainer
                      src={PasswordVisibilityIcon}
                      onClick={togglePasswordVisibility}
                    />
                  </InputContainer>
                </div>

                <Space vertical value={16} />
                <div className={cn('form-group', { 'has-danger': errorMessage })}>
                  <InputContainer>
                    <StyledPasswordInput
                      type={passwordInputType}
                      defaultValue={confirmPassword}
                      name="confirm_password"
                      disabled={isExecuting}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      placeholder="Confirm Password"
                      required
                      className={cn('user-input', {
                        'form-control-danger':
                          isAnyPasswordFieldFilled && (!isPasswordValid || !isPasswordMatching),
                        'form-control-success':
                          isAnyPasswordFieldFilled && isPasswordValid && isPasswordMatching,
                      })}
                      {...inputProps}
                    />
                    <PasswordVisibilityIconContainer
                      src={PasswordVisibilityIcon}
                      onClick={togglePasswordVisibility}
                    />
                  </InputContainer>
                </div>

                <Space vertical value={16} />
                <div className="auth-modal-submit set-password-modal-actions">
                  <OutlinedButton
                    label="Update"
                    isDisabled={isInteractionDisabled}
                    isLoading={isExecuting}
                    onClick={formSubmit}
                  />
                </div>
              </StyledForm>
            </FormContainer>
          </>
        )}
      </ResetFormContainer>
      <ReactTooltip
        id="password-policy-tooltip"
        getContent={() => (
          <PasswordPolicyTooltip
            password={password}
            confirmPassword={confirmPassword}
            isShowNeutralRequirementsState={isShowNeutralRequirementsState}
          />
        )}
      />
    </>
  );
};

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  width: 100%;

  .form-group {
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  width: 69%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const PasswordVisibilityIconContainer = styled.img`
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 10px;
`;

const StyledPasswordInput = styled.input`
  width: 100%;
`;
