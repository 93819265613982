import ReactTooltip from 'react-tooltip';
import React, { useCallback, useMemo, useState, useEffect, ChangeEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../shared/redux/hooks';
import { Form } from 'reactstrap';
import styled from 'styled-components';
import { logUserAction } from '../../actionCreators';
import { getMyId } from '../../auth/redux/selectors';

import * as constants from '../../constants';

import {
  AccountInput,
  BlueButton,
  HeaderWrapper,
  FormWrapper,
  ModalWrapper,
} from './common/generic';

import WidgetContainer from './common/WidgetContainer';
import { PasswordPolicyTooltip } from '../shared/PasswordPolicyTooltip';
import { getRequestStatus } from '../../selectors/statuses';
import { removeRequestStatusId } from '../../actionCreators/statuses';
import { Space } from '../shared/Space';
import { LoadingWheel } from '../../images/LoadingWheel';
import { ActivateGalaxyAccountFailure } from '../../auth/types';
import { passwordUtils } from '../../appUtils/passwordUtils';
import { usePasswordShowHideIcon } from '../shared/usePasswordShowHideIcon';
import { activateGalaxyAccount, updateAccountInfo } from '../../auth/redux/actionCreators';
import { PhoneNumberInput } from '../shared/PhoneNumberInput';
import { getMe } from '../../users/redux/selectors';
import { TermsOfServiceCheckbox } from '../shared/TermsOfServiceCheckbox';

const AccountForm = styled(Form)`
  margin-top: 25px;
`;

const AccountInputPasswordContainer = styled.div`
  display: flex;
`;

const updateAccountInfoRequestStatusId = 'AccountSignup__updateAccountInfo';

interface AccountSignupProps {
  name: string;
  password: string;
  phoneNumber: string;
  email: string;
  queryEmail?: string;
  queryPassword?: string;
  queryHasTeam?: boolean;
  setName: (value: string) => void;
  setPassword: (value: string) => void;
  setPhoneNumber: (value: string) => void;
  setEmail: (value: string) => void;
  onDone: () => void;
}

const AccountSignup: React.FC<AccountSignupProps> = ({
  children,
  name,
  password,
  phoneNumber,
  email,
  queryEmail,
  queryPassword,
  queryHasTeam,
  setName,
  setPassword,
  setPhoneNumber,
  setEmail,
  onDone,
}) => {
  const tooltipRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);
  const myId = useAppSelector(getMyId);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [isInputtingPassword, setIsInputtingPassword] = useState(false);
  const [isShowNeutralRequirementsState, setIsShowNeutralRequirementsState] = useState(true);

  const { PasswordVisibilityIcon, togglePasswordVisibility, passwordInputType } =
    usePasswordShowHideIcon();

  const { activateGalaxyAccountRequestStatus, updateAccountInfoRequestStatus } = useAppSelector(
    (state) => ({
      activateGalaxyAccountRequestStatus: getRequestStatus<ActivateGalaxyAccountFailure>(
        state,
        activateGalaxyAccount.type,
      ),
      updateAccountInfoRequestStatus: getRequestStatus<string>(
        state,
        updateAccountInfoRequestStatusId,
      ),
    }),
  );

  const sendAccountInfo = useCallback(() => {
    const first_name = name.split(' ')[0];
    const last_name = name.split(' ')[1];

    if (queryEmail && queryPassword) {
      dispatch(
        activateGalaxyAccount({
          email: queryEmail,
          password,
          tempPassword: queryPassword,
          accountInfo: {
            first_name,
            last_name,
            email,
            phone_number: phoneNumber,
            isTOSCompliant: true,
          },
          meta: {
            requestStatusId: activateGalaxyAccount.type,
          },
        }),
      );
      return;
    }

    // if auth server is not being used, then we will reach here
    dispatch(
      updateAccountInfo({
        password: {
          old_password: 'INIT PASS',
          new_password: password,
        },
        account: {
          first_name,
          last_name,
          email,
          phone_number: phoneNumber,
        },
        meta: {
          requestStatusId: updateAccountInfoRequestStatusId,
        },
      }),
    );
  }, [dispatch, email, name, password, phoneNumber, queryEmail, queryPassword]);

  useEffect(() => {
    if (activateGalaxyAccountRequestStatus?.isSuccess) {
      dispatch(removeRequestStatusId(activateGalaxyAccount.type));
      onDone();
    }

    if (updateAccountInfoRequestStatus?.isSuccess) {
      dispatch(removeRequestStatusId(updateAccountInfoRequestStatusId));
      onDone();
    }
  }, [
    activateGalaxyAccountRequestStatus?.isSuccess,
    dispatch,
    onDone,
    updateAccountInfoRequestStatus?.isSuccess,
  ]);

  const doneAction = useCallback(() => {
    if (myId) {
      dispatch(
        logUserAction({
          actionable_id: myId,
          actionable_type: constants.LOG_USER_ACTIONABLE_TYPE,
          action_type_id: constants.LOG_USER_ACTION_TYPE_ID,
          entity_information: {
            user_action_type: 'signup_account',
            user_action_id: 1,
            success: true,
            error: null,
          },
        }),
      );
    }
    sendAccountInfo();
  }, [dispatch, myId, sendAccountInfo]);

  useEffect(() => {
    if (me?.account) {
      setName(me?.account.name);
      setPhoneNumber(me?.account.phone_number);
    }
  }, [me?.account, setName, setPhoneNumber]);

  useEffect(() => {
    if (isInputtingPassword) {
      // @ts-expect-error incorrect types in react-tooltip package
      ReactTooltip.show(tooltipRef);
    }
  }, [isInputtingPassword]);

  const clearRequestStatuses = useCallback(() => {
    if (activateGalaxyAccountRequestStatus) {
      dispatch(removeRequestStatusId(activateGalaxyAccount.type));
    }
  }, [activateGalaxyAccountRequestStatus, dispatch]);

  const isPageDone = useMemo(() => {
    const first_name = name.split(' ')[0];
    const last_name = name.split(' ')[1];
    return (
      name &&
      first_name &&
      last_name &&
      password &&
      passwordUtils.getPasswordValidationResult(password).isValid &&
      phoneNumber &&
      email &&
      acceptTermsAndConditions
    );
  }, [acceptTermsAndConditions, email, name, password, phoneNumber]);

  const inputProps = {
    'data-for': 'password-policy-tooltip',
    'data-tip': '',
    'data-class': 'white-tooltip full-opacity mw-370',
    'data-place': 'bottom',
    'data-arrow-color': '#fff',
    onFocus: () => setIsInputtingPassword(true),
    onBlur: () => {
      if (isShowNeutralRequirementsState) {
        setIsShowNeutralRequirementsState(false);
      }

      setIsInputtingPassword(false);
    },
    ref: tooltipRef,
  };

  const handleClick = useCallback(() => {
    if (isPageDone) {
      doneAction();
    }
  }, [doneAction, isPageDone]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, origin: 'name' | 'password' | 'email') => {
      switch (origin) {
        case 'name': {
          setName(event.target.value);
          break;
        }
        case 'password': {
          setPassword(event.target.value);
          break;
        }
        case 'email': {
          setEmail(event.target.value);
          break;
        }
      }

      clearRequestStatuses();
    },
    [clearRequestStatuses, setEmail, setName, setPassword],
  );

  const handlePhoneNumberChange = useCallback(
    (value: string) => {
      setPhoneNumber(value);
      clearRequestStatuses();
    },
    [clearRequestStatuses, setPhoneNumber],
  );

  const isExecuting =
    activateGalaxyAccountRequestStatus?.isExecuting || updateAccountInfoRequestStatus?.isExecuting;

  const failureObj =
    activateGalaxyAccountRequestStatus?.error || updateAccountInfoRequestStatus?.error
      ? {
          errorMessage:
            activateGalaxyAccountRequestStatus?.error?.errorMessage ||
            updateAccountInfoRequestStatus?.error,
          isUserAlreadyActivatedFailure: activateGalaxyAccountRequestStatus?.error
            ? activateGalaxyAccountRequestStatus.error.type === 'userAlreadyActivatedFailure'
            : false,
        }
      : undefined;

  return (
    <>
      <ModalWrapper>
        <FormWrapper>
          <HeaderWrapper>
            {queryHasTeam ? 'Activate your account' : 'Try Mosaic for free'}
          </HeaderWrapper>
          <AccountForm>
            <AccountInput
              autoComplete="name"
              value={name}
              onChange={(e) => handleInputChange(e, 'name')}
              placeholder="Full Name *"
            />
            <Space vertical value={7} />
            <AccountInputPasswordContainer style={{ position: 'relative' }}>
              <AccountInput
                autoComplete="password"
                type={passwordInputType}
                value={password}
                onChange={(e) => handleInputChange(e, 'password')}
                placeholder="Password *"
                required
                {...inputProps}
              />
              <PasswordVisibilityIconContainer
                src={PasswordVisibilityIcon}
                onClick={togglePasswordVisibility}
              />
            </AccountInputPasswordContainer>
            <Space vertical value={12} />
            <PhoneNumberInput inputWidth={211} onChange={handlePhoneNumberChange} />
            <Space vertical value={12} />
            <AccountInput
              value={email || queryEmail}
              onChange={(e) => handleInputChange(e, 'email')}
              type="email"
              placeholder="Business Email *"
              disabled
            />
            <Space vertical value={12} />
            <TermsOfServiceCheckbox
              isChecked={acceptTermsAndConditions}
              onToggle={() => setAcceptTermsAndConditions(!acceptTermsAndConditions)}
            />
            <BlueButton disabled={!isPageDone || isExecuting} onClick={handleClick}>
              {isExecuting ? <LoadingWheel /> : 'Next'}
            </BlueButton>
            {failureObj && (
              <>
                <Space value={10} vertical />
                <ErrorMessage>{failureObj.errorMessage}</ErrorMessage>
                {failureObj.isUserAlreadyActivatedFailure && (
                  <>
                    <GoToLoginScreenText
                      onClick={() =>
                        window.parent.postMessage(JSON.stringify({ type: 'badInvite' }), '*')
                      }
                    >
                      Go to login screen?
                    </GoToLoginScreenText>
                  </>
                )}
              </>
            )}
          </AccountForm>
        </FormWrapper>
        <WidgetContainer>{children}</WidgetContainer>
      </ModalWrapper>
      <ReactTooltip
        id="password-policy-tooltip"
        getContent={() => (
          <PasswordPolicyTooltip
            password={password}
            isShowNeutralRequirementsState={isShowNeutralRequirementsState}
          />
        )}
      />
    </>
  );
};

export default AccountSignup;

const ErrorMessage = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorCalendarRed};
`;

const GoToLoginScreenText = styled.span`
  cursor: pointer;
  margin-left: 4px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorHelpBlue};
`;

const PasswordVisibilityIconContainer = styled.img`
  position: absolute;
  cursor: pointer;
  bottom: 26px;
  right: 44px;
`;
