import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { Entries } from 'type-fest';
import { callApi } from '../../appUtils/api';
import { convertKeysToSnakeCase } from '../../appUtils/formatUtils';
import { AggregatedRealmAPIClients } from '../../core/API/AggregatedRealmAPIClients';
import { RealmAPIClient } from '../../core/API/RealmAPIClient';
import { envTypesFullConfig } from '../../core/env/utils';
import { ActivateGalaxyAccountFailure, LoginDomainFailure } from '../types';
import {
  ActivateGalaxyAccountInitialParams,
  CheckAuthMethodParams,
  CheckAuthMethodPerEnvSuccessResponse,
  InitiateSSOLoginParams,
  ValidateSSOLoginParams,
} from './types';

const login = async (email: string, password: string) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  };

  const result = await callApi('auth/login', null, fetchInit);

  if (!('error' in result)) {
    try {
      jwtDecode(result.response.auth_token);
      return result;
    } catch (error) {
      return {
        error: {
          status: 403,
          statusText: 'Invalid token',
        },
      };
    }
  }

  return {
    error: mapLoginFailureResponseToDomain(result.error),
  };
};

const forgotPassword = (email: string) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  };
  return callApi('reset_password', null, fetchInit);
};

const activateGalaxyAccount = async ({
  email,
  password,
  tempPassword,
  accountInfo,
}: ActivateGalaxyAccountInitialParams) => {
  const { first_name, last_name, phone_number, isTOSCompliant } = accountInfo;

  const body = convertKeysToSnakeCase({
    email,
    password,
    tempPassword,
    first_name,
    last_name,
    phone_number,
    isTOSCompliant,
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const result = await callApi('account/activate', null, fetchInit);
  if (!('error' in result) || isEmpty(result.error)) return result;

  const failure: { error: ActivateGalaxyAccountFailure } = {
    error: {
      type:
        result.error === "User's already been activated."
          ? 'userAlreadyActivatedFailure'
          : 'serverFriendlyFailure',
      errorMessage: result.error,
    },
  };

  return failure;
};

const updateAccountInfo = (token: string, payload: any) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return callApi('settings', token, fetchInit);
};

const updateAccountSMS = ({
  email,
  password,
  phoneNumber,
}: {
  email: string;
  password: string;
  phoneNumber: string;
}) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, phone_number: phoneNumber }),
  };

  return callApi('account/associate_sms', null, fetchInit);
};

const associateTOTP = ({ email, password }: { email: string; password: string }) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  };

  return callApi('account/associate_totp', null, fetchInit);
};

const verifyTOTP = ({
  email,
  password,
  totpToken,
}: {
  email: string;
  password: string;
  totpToken: string;
}) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      totp_token: totpToken,
    }),
  };

  return callApi('account/verify_totp', null, fetchInit);
};

const respondToMFA = ({
  email,
  mfaCode,
  challengeName,
  sessionToken,
}: {
  email: string;
  mfaCode: string;
  challengeName: string;
  sessionToken: string;
}) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      mfa_code: mfaCode,
      challenge_name: challengeName,
      session_token: sessionToken,
    }),
  };

  return callApi('account/respond_to_mfa', null, fetchInit);
};

const updateCognitoPassword = ({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      email,
      code,
    }),
  };
  return callApi('/account/confirm_password_reset', null, fetchInit);
};

const updatePassword = (token: string, password: string, firstName: string, lastName: string) => {
  let fetchInit;

  if (firstName || lastName) {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password,
        first_name: firstName,
        last_name: lastName,
      }),
    };
  } else {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password,
      }),
    };
  }

  return callApi('me', token, fetchInit);
};

const checkAuthMethod = ({ email }: CheckAuthMethodParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const stringifiedQueryParams = qs.stringify({ email });
  return callApi(`auth/check_auth_method?${stringifiedQueryParams}`, null, fetchInit);
};

const checkAuthMethodPerEnv = async ({ email }: CheckAuthMethodParams) => {
  const successResponse: CheckAuthMethodPerEnvSuccessResponse = {};

  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const stringifiedQueryParams = qs.stringify({ email });

  for (const [envType, envConfig] of Object.entries(envTypesFullConfig) as Entries<
    typeof envTypesFullConfig
  >) {
    const mosaicApiClients = new AggregatedRealmAPIClients({
      mosaicAPI: new RealmAPIClient({
        baseURL: envConfig.apiUrl,
      }),
    });

    const result = await callApi(
      `auth/check_auth_method?${stringifiedQueryParams}`,
      null,
      fetchInit,
      { client: mosaicApiClients.mosaicAPI },
    );

    if (!('error' in result)) {
      successResponse[envType] = result.response;
    }
  }

  return { response: successResponse };
};

const initiateSSOLogin = ({ email, callbackUrl }: InitiateSSOLoginParams) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const stringifiedQueryParams = qs.stringify({ email, callback_url: callbackUrl });

  return callApi(`auth/sso_login?${stringifiedQueryParams}`, null, fetchInit);
};

const validateSSOLogin = ({ email, verificationToken }: ValidateSSOLoginParams) => {
  const body = {
    email,
    one_time_secret: verificationToken,
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  return callApi('auth/validate_sso_login', null, fetchInit);
};

export const authApi = {
  login,
  forgotPassword,
  activateGalaxyAccount,
  updateAccountInfo,
  updateAccountSMS,
  associateTOTP,
  verifyTOTP,
  respondToMFA,
  updateCognitoPassword,
  updatePassword,
  checkAuthMethod,
  checkAuthMethodPerEnv,
  initiateSSOLogin,
  validateSSOLogin,
};

const mapLoginFailureResponseToDomain = (error: LoginFailureResponse): LoginDomainFailure => {
  if (error === true) {
    return {
      type: 'invalidCredentials',
    };
  } else if (typeof error === 'string') {
    return {
      type: 'serverFriendlyError',
      message: error,
    };
  } else if (error.result === "Require MFA association of user's choice") {
    return {
      type: 'userPreferredSetup',
    };
  } else if (error.result === 'Require TOTP MFA association') {
    return error.team_mfa_enforced
      ? {
          type: 'totpMFAAssociationRequired',
          secretCode: error.secret_code,
        }
      : {
          type: 'userPreferredSetup',
        };
  } else if (error.result === 'Require phone number association') {
    return error.team_mfa_enforced
      ? {
          type: 'phoneNumberAssociationRequired',
        }
      : {
          type: 'userPreferredSetup',
        };
  } else if (error.result === 'Require MFA response') {
    return {
      type: 'mfaVerifyRequired',
      challengeName: error.challenge_name,
      sessionToken: error.session,
    };
  } else {
    return {
      type: 'unknownFailure',
    };
  }
};

type LoginFailureResponse =
  | true
  | string
  | {
      result: 'Require TOTP MFA association';
      team_mfa_enforced: boolean;
      secret_code: string;
    }
  | { result: 'Require phone number association'; team_mfa_enforced: boolean }
  | { result: "Require MFA association of user's choice" }
  | {
      result: 'Require MFA response';
      challenge_name: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
      session: string;
    };
